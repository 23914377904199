import { ElementRef, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { Root, SeparatorProps } from '@radix-ui/react-separator';
import { colors } from '@sidetalk/tokens';

const { gray700, gray050 } = colors;

const separatorVariants = tv({
    base: [
        'my-3 flex items-center',
        'text-xs font-semibold text-[--separator-color]',
        'data-[orientation=vertical]:h-full data-[orientation=vertical]:w-fit data-[orientation=vertical]:flex-col',
    ],

    variants: {
        variant: {
            line: [
                '!bg-transparent before:content-[""] after:content-[""]',
                'before:bg-[--separator-color] after:bg-[--separator-color]',
                'data-[orientation=horizontal]:before:mr-3 data-[orientation=horizontal]:after:ml-3',
                'data-[orientation=horizontal]:before:h-px data-[orientation=horizontal]:after:h-px',
                'data-[orientation=horizontal]:before:flex-1 data-[orientation=horizontal]:after:flex-1',
                'data-[orientation=vertical]:before:mb-3 data-[orientation=vertical]:after:mt-3',
                'data-[orientation=vertical]:before:w-px data-[orientation=vertical]:after:w-px',
                'data-[orientation=vertical]:before:h-full data-[orientation=vertical]:after:h-full',
                'empty:before:!m-0 empty:after:!m-0',
            ],

            background: 'p-4',
        },

        align: {
            left: 'justify-start',

            center: 'justify-center',

            right: 'justify-end',
        },
    },

    compoundVariants: [
        {
            variant: 'line',
            align: 'left',
            className: 'data-[orientation=horizontal]:before:w-6',
        },
        {
            variant: 'line',
            align: 'right',
            className: 'data-[orientation=horizontal]:after:w-6',
        },
    ],

    defaultVariants: {
        variant: 'line',
        align: 'center',
    },
});

type CustomRootProps = SeparatorProps &
    VariantProps<typeof separatorVariants> & {
        color?: string;
        background?: string;
    };

export const CustomRoot = forwardRef<ElementRef<typeof Root>, CustomRootProps>(
    ({ variant, align, color = gray700, background = gray050, style, className, ...rest }, forwardedRef) => (
        <Root
            ref={forwardedRef}
            className={separatorVariants({ variant, align, className })}
            style={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                '--separator-color': color,
                backgroundColor: background,
                ...style,
            }}
            {...rest}
        />
    ),
);

CustomRoot.displayName = 'Separator.Root';
