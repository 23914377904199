import { ElementRef, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { Root, Indicator, type ProgressProps, type ProgressIndicatorProps } from '@radix-ui/react-progress';

const rootVariations = tv({
    base: 'relative h-[5px] w-full overflow-hidden rounded-full will-change-transform',

    variants: {
        styleGuide: {
            coremedia: 'bg-gray-200',

            new: 'bg-gray-50',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomRootProps = ProgressProps & VariantProps<typeof rootVariations>;

export const CustomRoot = forwardRef<ElementRef<typeof Root>, CustomRootProps>(
    ({ styleGuide, className, ...rest }, forwardedRef) => (
        <Root ref={forwardedRef} className={rootVariations({ styleGuide, className })} {...rest} />
    ),
);

CustomRoot.displayName = 'ProgressBar.Root';

const indicatorVariations = tv({
    base: 'ease-[cubic-bezier(0.65, 0, 0.35, 1)] size-full transition-transform duration-[660ms]',

    variants: {
        styleGuide: {
            coremedia: 'bg-green-600',

            new: 'bg-gray-850',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomIndicatorProps = ProgressIndicatorProps & VariantProps<typeof indicatorVariations>;

export const CustomIndicator = forwardRef<ElementRef<typeof Indicator>, CustomIndicatorProps>(
    ({ styleGuide, className, ...rest }, forwardedRef) => (
        <Indicator ref={forwardedRef} className={indicatorVariations({ styleGuide, className })} {...rest} />
    ),
);

CustomIndicator.displayName = 'ProgressBar.Indicator';
