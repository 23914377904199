import { ComponentPropsWithRef, ComponentPropsWithoutRef, ElementRef, forwardRef, useMemo } from 'react';
import { isNumber } from '@sidetalk/helpers';
import { CustomRoot, CustomButton, CustomVisual } from './styles';

type VisualBadgeProps = ComponentPropsWithoutRef<typeof CustomVisual> & {
    number?: number;
    maxNumber?: number;
};

const VisualBadge = forwardRef<ElementRef<typeof CustomVisual>, VisualBadgeProps>(
    ({ number = 0, maxNumber = 9, children, ...rest }, forwardedRef) => {
        const numberParsed = isNumber(number) ? number : 0;
        const maxNumberParsed = isNumber(number) ? maxNumber : 9;

        const stringNumber = useMemo(() => {
            if (numberParsed > maxNumberParsed) {
                return `${maxNumberParsed}+`;
            }

            return `${numberParsed <= 0 ? 0 : numberParsed}`;
        }, [numberParsed, maxNumberParsed]);

        return (
            <CustomVisual ref={forwardedRef} {...rest}>
                {children ?? stringNumber}
            </CustomVisual>
        );
    },
);

VisualBadge.displayName = 'Badge.Visual';

export type BadgeVisualProps = ComponentPropsWithRef<typeof VisualBadge>;

type ButtonBadgeProps = ComponentPropsWithoutRef<typeof CustomButton> & {
    number?: number;
    maxNumber?: number;
};

const ButtonBadge = forwardRef<ElementRef<typeof CustomButton>, ButtonBadgeProps>(
    ({ number = 0, maxNumber = 9, children, ...rest }, forwardedRef) => {
        const numberParsed = isNumber(number) ? number : 0;
        const maxNumberParsed = isNumber(number) ? maxNumber : 9;

        const stringNumber = useMemo(() => {
            if (numberParsed > maxNumberParsed) {
                return `${maxNumberParsed}+`;
            }

            return `${numberParsed <= 0 ? 0 : numberParsed}`;
        }, [numberParsed, maxNumberParsed]);

        return (
            <CustomButton ref={forwardedRef} {...rest}>
                {children ?? stringNumber}
            </CustomButton>
        );
    },
);

ButtonBadge.displayName = 'Badge.Button';

export type BadgeButtonProps = ComponentPropsWithRef<typeof ButtonBadge>;

export const Badge = {
    Root: CustomRoot,
    Visual: VisualBadge,
    Button: ButtonBadge,
};
