import { ButtonHTMLAttributes, HTMLAttributes, InputHTMLAttributes, forwardRef } from 'react';
import { tv } from '@lib/tailwind-variants';
import { cn } from '@helpers/index';

const rootVariants = tv({
    base: [
        'flex h-9 items-center p-0',
        'bg-gray-30 box-border transition-colors',
        'rounded-xs border border-solid border-transparent',
        '[&>_i:last-child]:pr-4',
        '[&_button:last-child]:rounded-r-xs [&>_i:first-child]:pl-4 [&>_input:first-child]:pl-4',
        'has-[input:disabled]:cursor-not-allowed has-[input:disabled]:border-transparent',
        '[&>button]:has-[input:disabled]:pointer-events-none',
        'focus-within:has-[input]:border-red-500',
        'hover:border-red-500',
    ],
});

export const CustomRoot = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...rest }, forwardedRef) => (
        <div
            ref={forwardedRef}
            className={rootVariants({
                className,
            })}
            {...rest}
        />
    ),
);

CustomRoot.displayName = 'TextInput.Root';

export const CustomInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
    ({ className, ...rest }, forwardedRef) => (
        <input
            ref={forwardedRef}
            className={cn(
                [
                    'h-9 flex-1 px-2 py-0 outline-none',
                    'text-gray-850 box-border border-none bg-transparent',
                    'font-default font-regular leading-base text-sm',
                    'placeholder:text-gray-250',
                    'disabled:text-gray-250 disabled:cursor-not-allowed',
                ],
                className,
            )}
            {...rest}
        />
    ),
);

CustomInput.displayName = 'TextInput.Input';

export const CustomButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
    ({ className, ...rest }, forwardedRef) => (
        <button
            ref={forwardedRef}
            type="button"
            className={cn(
                [
                    'flex h-full w-9 cursor-pointer items-center justify-center p-2',
                    'text-gray-850 bg-inherit outline-none',
                    'border-0 border-l border-solid border-white',
                ],
                className,
            )}
            {...rest}
        />
    ),
);

CustomButton.displayName = 'TextInput.Button';
