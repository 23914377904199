import { ElementRef, forwardRef } from 'react';
import { Content, type TooltipContentProps, Arrow, type TooltipArrowProps } from '@radix-ui/react-tooltip';
import { cn } from '@helpers/index';

export const TooltipContent = forwardRef<ElementRef<typeof Content>, TooltipContentProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Content
            ref={forwardedRef}
            className={cn(
                [
                    'rounded-xs z-tooltip select-none px-2 py-1',
                    'bg-gray-850 shadow-tooltip/content will-change-[transform,opacity]',
                    'leading-short text-xs font-semibold text-white',
                    'max-h-[--radix-tooltip-content-available-height] max-w-[--radix-tooltip-content-available-width]',
                    'data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade',
                    'data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade',
                    'data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade',
                    'data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade',
                ],
                className,
            )}
            {...rest}
        />
    ),
);

TooltipContent.displayName = 'TooltipContent';

export const TooltipArrow = forwardRef<ElementRef<typeof Arrow>, TooltipArrowProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Arrow ref={forwardedRef} className={cn('fill-gray-850', className)} {...rest} />
    ),
);

TooltipArrow.displayName = 'TooltipArrow';
