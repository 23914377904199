import { ComponentPropsWithRef, ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { Root } from '@radix-ui/react-accordion';
import { Icon } from '@components/Icon';
import { coremediaColors } from '@sidetalk/tokens';
import { CustomContent, ContentStyled, ExtraInfoContainer, CustomHeader, Item, CustomTrigger, Arrow } from './styles';
import { AccordionContextProvider, AccordionContextProviderProps } from './context';

type CustomRootProps = ComponentPropsWithoutRef<typeof Root> & AccordionContextProviderProps;

export const CustomRoot = forwardRef<ElementRef<typeof Root>, CustomRootProps>(
    ({ styleGuide, border, ...rest }, forwardedRef) => (
        <AccordionContextProvider styleGuide={styleGuide} border={border}>
            <Root ref={forwardedRef} {...rest} />
        </AccordionContextProvider>
    ),
);

CustomRoot.displayName = 'Accordion.Root';

// Removing `orientation` since we don't use it.
export type AccordionRootProps = Omit<ComponentPropsWithRef<typeof Root>, 'orientation'>;

type CustomAccordionHeaderProps = ComponentPropsWithoutRef<typeof CustomHeader> & {
    alertColor?: string;
};

const AccordionHeader = forwardRef<ElementRef<typeof CustomHeader>, CustomAccordionHeaderProps>(
    ({ alertColor, ...rest }, forwardedRef) => (
        <CustomHeader
            ref={forwardedRef}
            style={{
                '--accordionHighlightColor': alertColor ?? coremediaColors.yellow200,
            }}
            {...rest}
        />
    ),
);

AccordionHeader.displayName = 'Accordion.Header';

const AccordionTrigger = forwardRef<ElementRef<typeof CustomTrigger>, ComponentPropsWithoutRef<typeof CustomTrigger>>(
    ({ children, ...rest }, forwardedRef) => (
        <CustomTrigger {...rest} ref={forwardedRef}>
            <Arrow />
            {children}
        </CustomTrigger>
    ),
);

AccordionTrigger.displayName = 'Accordion.Trigger';

type CustomExtraInfoContainerWithErrorProps = ComponentPropsWithoutRef<typeof ExtraInfoContainer> & {
    error?: boolean;
};

const ExtraInfoContainerWithError = forwardRef<
    ElementRef<typeof ExtraInfoContainer>,
    CustomExtraInfoContainerWithErrorProps
>(({ children, error = false, ...rest }, forwardedRef) => (
    <ExtraInfoContainer ref={forwardedRef} {...rest}>
        {error && <Icon type="byside" iconName="alert" aria-hidden className="text-coremedia-red-500 text-xl" />}
        {children}
    </ExtraInfoContainer>
));

ExtraInfoContainerWithError.displayName = 'Accordion.ExtraInfoContainer';

const Content = forwardRef<ElementRef<typeof CustomContent>, ComponentPropsWithoutRef<typeof CustomContent>>(
    ({ className, children, ...rest }, forwardedRef) => (
        <CustomContent ref={forwardedRef} {...rest}>
            <ContentStyled className={className}>{children}</ContentStyled>
        </CustomContent>
    ),
);

Content.displayName = 'Accordion.Content';

export const Accordion = {
    Root: CustomRoot,
    Item,
    Header: AccordionHeader,
    Trigger: AccordionTrigger,
    ExtraInfoContainer: ExtraInfoContainerWithError,
    Content,
};
