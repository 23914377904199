import {
    ButtonHTMLAttributes,
    ComponentPropsWithoutRef,
    ElementRef,
    InputHTMLAttributes,
    LabelHTMLAttributes,
    forwardRef,
} from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import {
    Overlay,
    Title,
    Description,
    Content,
    type AlertDialogOverlayProps,
    type AlertDialogTitleProps,
    type AlertDialogDescriptionProps,
} from '@radix-ui/react-alert-dialog';
import { CustomScrollArea } from '@components/CustomScrollArea';
import { Loader } from '@components/Loader';
import { Button } from '@components/Button';
import { cn } from '@helpers/index';
import { useModal } from './context';

const overlayVariants = tv({
    base: 'z-modal/overlay absolute inset-0',

    variants: {
        styleGuide: {
            coremedia: 'bg-coremedia-grey-500/60',

            new: 'bg-gray-150/80',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomOverlayProps = AlertDialogOverlayProps & VariantProps<typeof overlayVariants>;

export const CustomOverlay = forwardRef<ElementRef<typeof Overlay>, CustomOverlayProps>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useModal('CustomOverlay');

        return <Overlay ref={forwardedRef} className={overlayVariants({ styleGuide, className })} {...rest} />;
    },
);

const contentVariants = tv({
    base: ['z-modal/content group absolute left-1/2 -translate-x-1/2 focus:outline-none', 'flex flex-col'],

    variants: {
        alignment: {
            top: 'top-0',

            center: 'top-1/2 -translate-y-1/2',
        },

        styleGuide: {
            coremedia: ['min-w-[300px] max-w-[400px]', 'shadow-modal/content rounded-[6px]'],

            new: 'max-w-[236px] rounded-md bg-white',
        },
    },

    compoundVariants: [
        {
            styleGuide: ['coremedia', 'new'],
            alignment: 'top',
            className: 'rounded-t-none',
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
        alignment: 'center',
    },
});

type CustomContentProps = AlertDialogTitleProps & VariantProps<typeof contentVariants>;

export const CustomContent = forwardRef<ElementRef<typeof Content>, CustomContentProps>(
    ({ alignment = 'center', className, ...rest }, forwardedRef) => {
        const { styleGuide } = useModal('Content');

        return (
            <Content
                ref={forwardedRef}
                data-is-on-top={alignment === 'top'}
                className={contentVariants({ styleGuide, alignment, className })}
                {...rest}
            />
        );
    },
);

CustomContent.displayName = 'Modal.Content';

const bodyVariants = tv({
    base: 'min-h-[80px] bg-white',

    variants: {
        styleGuide: {
            coremedia: 'p-[15px]',

            new: 'px-12',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomBodyProps = ComponentPropsWithoutRef<typeof CustomScrollArea.Basic> & {
    isLoading?: boolean;
};

export const CustomBody = forwardRef<ElementRef<typeof CustomScrollArea.Basic>, CustomBodyProps>(
    ({ className, children, isLoading = false, ...rest }, forwardedRef) => {
        const { styleGuide } = useModal('Body');

        return (
            <CustomScrollArea.Basic ref={forwardedRef} className={bodyVariants({ styleGuide, className })} {...rest}>
                {isLoading ? <Loader.Byside size="sm" verticalCenter /> : children}
            </CustomScrollArea.Basic>
        );
    },
);

CustomBody.displayName = 'Modal.Body';

const titleVariants = tv({
    slots: {
        header: '',
        title: '',
    },

    variants: {
        styleGuide: {
            coremedia: {
                header: [
                    'bg-white p-[15px] group-[[data-is-on-top=true]]:rounded-t-none',
                    'border-coremedia-grey-200 rounded-t-[6px] border-0 border-b border-solid',
                ],

                title: 'm-0 text-base font-bold',
            },

            new: {
                header: ['bg-white px-8 pt-12 group-[[data-is-on-top=true]]:rounded-t-none', 'rounded-t-[6px]'],

                title: 'm-0 text-center text-sm font-semibold leading-6 text-black',
            },
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

export const CustomTitle = forwardRef<ElementRef<typeof Title>, AlertDialogTitleProps>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useModal('Title');

        const { header, title } = titleVariants({ styleGuide });

        return (
            <header className={header()}>
                <Title ref={forwardedRef} className={title({ className })} {...rest} />
            </header>
        );
    },
);

CustomTitle.displayName = 'Modal.Title';

const descriptionVariants = tv({
    base: 'm-0',

    variants: {
        styleGuide: {
            coremedia: '',

            new: 'text-center text-xs font-semibold leading-6 text-gray-300',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

export const CustomDescription = forwardRef<ElementRef<typeof Description>, AlertDialogDescriptionProps>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useModal('Description');

        return <Description ref={forwardedRef} className={descriptionVariants({ styleGuide, className })} {...rest} />;
    },
);

CustomDescription.displayName = 'Modal.Description';

export const CustomLabel = forwardRef<HTMLLabelElement, LabelHTMLAttributes<HTMLLabelElement>>(
    ({ className, ...rest }, forwardedRef) => (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
            ref={forwardedRef}
            className={cn(
                ['mb-0 inline-block pt-[7px]', 'text-coremedia-grey-400 text-left text-[11px] font-light uppercase'],
                className,
            )}
            {...rest}
        />
    ),
);

CustomLabel.displayName = 'Modal.Label';

export const CustomInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
    ({ className, ...rest }, forwardedRef) => (
        <input
            ref={forwardedRef}
            className={cn(
                [
                    'block h-[28px] w-full bg-transparent px-[8px] py-[4px] leading-[28px]',
                    'border-coremedia-grey-300 rounded-none border-0 border-b border-solid shadow-none outline-none',
                ],
                className,
            )}
            {...rest}
        />
    ),
);

CustomInput.displayName = 'Modal.Input';

const footerVariants = tv({
    base: 'flex bg-white',

    variants: {
        styleGuide: {
            coremedia: [
                'h-[41px] justify-end',
                'divide-coremedia-grey-200 divide-x',
                'border-coremedia-grey-200 rounded-b-[6px] border-0 border-t border-solid',
            ],

            new: 'justify-center gap-2 rounded-b-md px-8 pb-8 pt-4',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

export const CustomFooter = forwardRef<HTMLElement, AlertDialogTitleProps>(({ className, ...rest }, forwardedRef) => {
    const { styleGuide } = useModal('Footer');

    return <footer ref={forwardedRef} className={footerVariants({ styleGuide, className })} {...rest} />;
});

CustomFooter.displayName = 'Modal.Footer';

const buttonsStyle = cn([
    'text-coremedia-blue-500 h-[40px] w-full cursor-pointer border-0 bg-transparent text-[12px] font-semibold uppercase tracking-[0.8px] outline-none',
    'enabled:hover:text-coremedia-blue-600',
    'disabled:text-coremedia-grey-200',
]);

export const CustomButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useModal('CustomButton');

        if (styleGuide === 'coremedia') {
            return <button type="button" ref={forwardedRef} className={cn(buttonsStyle, className)} {...rest} />;
        }

        return <Button ref={forwardedRef} className={className} {...rest} />;
    },
);

CustomButton.displayName = 'Modal.CustomButton';
