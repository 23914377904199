import * as AvatarPrimitive from '@radix-ui/react-avatar';
import { styled } from '@styles';

export const AvatarRoot = styled(AvatarPrimitive.Root, {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden',
    userSelect: 'none',
    width: '2.375rem',
    aspectRatio: 1 / 1,
    borderRadius: '100%',
    boxSizing: 'border-box',
    borderStyle: 'solid',

    variants: {
        border: {
            thin: {
                borderWidth: '1px',
            },

            medium: {
                borderWidth: '3px',
            },

            bold: {
                borderWidth: '5px',
            },
        },

        borderColor: {
            red: {
                borderColor: '$red500',
            },

            yellow: {
                borderColor: '$yellow500',
            },

            green: {
                borderColor: '$green500',
            },

            turquoise: {
                borderColor: '$turquoise500',
            },

            purple: {
                borderColor: '$purple500',
            },

            blue: {
                borderColor: '$blue500',
            },
        },
    },

    defaultVariants: {
        borderColor: 'red',
    },
});

export const AvatarImage = styled(AvatarPrimitive.Image, {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: 'inherit',
});

export const AvatarFallback = styled(AvatarPrimitive.Fallback, {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '$md',
    lineHeight: '$base',
    fontWeight: 'regular',
});
