import { useCallback, useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { isDate } from '@sidetalk/helpers';
import { DatePickerWithDefaultRanges, DatePickerRanges, Locales } from '@components/DatePicker';
import { RangesKeys, useQueryBuilderState } from '../../context/QueryBuilder';
import { formatDate, getDatePickerDefaultRanges, setHoursToBeginningOfDay, setHoursToEndOfDay } from '../helpers';

const dateRangePickerFormat = 'YYYY/MM/DD';

type PeriodType =
    | 'lasthour'
    | 'last24hours'
    | 'today'
    | 'yesterday'
    | 'currentweek'
    | 'lastweek'
    | 'last7days'
    | 'last30days'
    | 'currentmonth'
    | 'lastmonth'
    | 'lastthreemonth'
    | 'custom';

type DateRangeValue = {
    period: PeriodType;
    startDate: string | Date;
    endDate: string | Date;
};

type DateRangeProps = {
    value: DateRangeValue;
    handleOnChange: (value: DateRangeValue) => void;
    locale?: Locales;
};

type DateRangeType =
    | {
          from?: Date;
          to?: Date;
      }
    | undefined;

export function DateRange({ value, handleOnChange, ...props }: DateRangeProps) {
    const defaultLocale = {
        format: dateRangePickerFormat,
        separator: '-',
        customRangeLabel: 'Custom',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        firstDay: 1,
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
    };

    const defaultLabels = {
        lasthour: 'Last Hour',
        last24hours: 'Last 24 Hours',
        today: 'Today',
        yesterday: 'Yesterday',
        currentweek: 'Current Week',
        lastweek: 'Last Week',
        last7days: 'Last 7 Days',
        last30days: 'Last 30 Days',
        currentmonth: 'This Month',
        lastmonth: 'Last Month',
        lastthreemonth: 'Last Three Months',
        custom: 'Custom',
    };

    const { state } = useQueryBuilderState();
    const { resources, disabled, locale: localeProp, localeResources } = state;
    const ranges = (resources?.DatePicker?.ranges ?? getDatePickerDefaultRanges()) as DatePickerRanges;
    const rangeMapper = resources?.DatePicker?.rangeMapper ?? null;
    const label = resources?.DatePicker?.label ?? defaultLabels;
    const locale = resources?.DatePicker?.locale ?? defaultLocale;

    const onDateChange = useCallback(
        (date: DateRangeType, selectedRange: Date | PeriodType = 'today') => {
            if (date === undefined) {
                return;
            }

            const { from, to } = date;
            const period: PeriodType = isDate(selectedRange) ? 'custom' : (rangeMapper?.[selectedRange] as PeriodType);

            handleOnChange({
                startDate: format(from ? new Date(from) : new Date(), 'yyyy-MM-dd HH:mm:ss'),
                endDate: format(to ? new Date(to) : new Date(), 'yyyy-MM-dd HH:mm:ss'),
                period,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [rangeMapper],
    );

    const defaultValue = useMemo(() => {
        const startOfToday = new Date();
        setHoursToBeginningOfDay(startOfToday);
        const endOfToday = new Date();
        setHoursToEndOfDay(endOfToday);

        return {
            period: (rangeMapper?.[Object.keys(ranges)?.[0]] as PeriodType) || 'today',
            startDate: startOfToday,
            endDate: endOfToday,
        };
    }, [rangeMapper, ranges]);

    const period = value?.period || defaultValue.period;
    const { customRangeLabel } = localeResources ?? locale;
    const periodValue = label[period] || label.today;

    const dateRange = {
        from:
            ranges[periodValue as RangesKeys]?.[0] ??
            (value?.startDate ? new Date(formatDate(value?.startDate)) : new Date()),
        to:
            ranges[periodValue as RangesKeys]?.[1] ??
            (value?.endDate ? new Date(formatDate(value?.endDate, 'end')) : new Date()),
    };

    useEffect(() => {
        if (!value && defaultValue) {
            onDateChange(
                {
                    from: defaultValue.startDate,
                    to: defaultValue.endDate,
                },
                period,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div className="[&_button]:h-7">
            <DatePickerWithDefaultRanges
                onSelect={onDateChange}
                ranges={ranges}
                locale={localeProp}
                selected={dateRange}
                initialDefaultOption={periodValue}
                minDate={new Date(resources?.DatePicker?.minDate as string)}
                disabledPopover={disabled}
                timePicker
                timePickerSeconds
                customRangeLabel={customRangeLabel}
                styleGuide="coremedia"
                variant="boxed"
                cancelLabel={locale.cancelLabel}
                acceptLabel={locale.applyLabel}
                {...props}
            />
        </div>
    );
}
