import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import {
    Root,
    Trigger,
    Portal,
    Overlay,
    Content,
    Action,
    Cancel,
    type AlertDialogPortalProps,
    type AlertDialogProps,
} from '@radix-ui/react-alert-dialog';
import { ModalContextProvider, ModalContextProviderProps } from './context';
import {
    CustomBody,
    CustomButton,
    CustomContent,
    CustomDescription,
    CustomFooter,
    CustomInput,
    CustomLabel,
    CustomOverlay,
    CustomTitle,
} from './styles';

type CustomRootProps = AlertDialogProps & ModalContextProviderProps;

function CustomRoot({ styleGuide, ...rest }: CustomRootProps) {
    return (
        <ModalContextProvider styleGuide={styleGuide}>
            <Root {...rest} />
        </ModalContextProvider>
    );
}

CustomRoot.displayName = 'Modal.Root';

export type ModalRootProps = typeof CustomRoot;

Trigger.displayName = 'Modal.Trigger';

Action.displayName = 'Modal.Action';

Cancel.displayName = 'Modal.Cancel';

type CustomContentProps = AlertDialogPortalProps & ComponentPropsWithoutRef<typeof CustomContent>;

const ModalContent = forwardRef<ElementRef<typeof CustomContent>, CustomContentProps>(
    ({ container, forceMount, children, ...rest }, forwardedRef) => (
        <Portal container={container} forceMount={forceMount}>
            <CustomOverlay />
            <CustomContent ref={forwardedRef} {...rest}>
                {children}
            </CustomContent>
        </Portal>
    ),
);

ModalContent.displayName = 'Modal.Content';

export const Modal = {
    Root: CustomRoot,
    Trigger,
    Portal,
    Overlay,
    OverlayStyled: CustomOverlay,
    ContentWithoutPortal: Content,
    ContentWithoutPortalStyled: CustomContent,
    Content: ModalContent,
    Body: CustomBody,
    Title: CustomTitle,
    Description: CustomDescription,
    Label: CustomLabel,
    Input: CustomInput,
    ActionButton: Action,
    CancelButton: Cancel,
    ButtonStyled: CustomButton,
    Footer: CustomFooter,
};
