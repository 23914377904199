import { Options } from 'react-select';
import { isUndefined, uniq } from '@sidetalk/helpers';
import { OPTION_NOT_GROUPED } from '../constants';
import { GroupedOptions, OptionWithInnerGroup } from '../types';

export const getInnerGroupedOptions = (options: Options<OptionWithInnerGroup>) => {
    const initialMapWithOptionNotGrouped = new Map<string, GroupedOptions>([
        [
            OPTION_NOT_GROUPED,
            {
                options: [],
            },
        ],
    ]);

    const innerGroupNames = uniq(
        options.filter((option) => !isUndefined(option.data.innerGroup)).map((option) => option.data.innerGroup!),
    );

    innerGroupNames.forEach((groupName) => {
        const header = options.find((option) => option.label === groupName || option.value === groupName);

        initialMapWithOptionNotGrouped.set(groupName, {
            header,
            options: [],
        });
    });

    return options.reduce<Map<string, GroupedOptions>>((acc, option) => {
        const isHeaderOption = acc.has(option.label);

        if (!isHeaderOption) {
            const {
                data: { innerGroup },
            } = option;

            const isInnerGroupOption = !isUndefined(innerGroup);

            if (isInnerGroupOption) {
                const groupedOptionsObject = acc.get(innerGroup);

                acc.set(innerGroup, {
                    ...groupedOptionsObject,
                    options: [...(groupedOptionsObject?.options ?? []), option],
                });

                return acc;
            }

            const optionsNotGroupedObject = acc.get(OPTION_NOT_GROUPED);

            acc.set(OPTION_NOT_GROUPED, {
                options: [...(optionsNotGroupedObject?.options ?? []), option],
            });

            return acc;
        }

        return acc;
    }, initialMapWithOptionNotGrouped);
};
