import {
    createContext,
    MutableRefObject,
    MouseEvent as ReactMouseEvent,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import type AudioPlayer from 'react-h5-audio-player';
import { Entry } from './types';

type SimpleAudioPlayerProps = {
    audioRef: MutableRefObject<HTMLAudioElement | null>;
    entries?: Entry[] | null;
    hasPlayerContent: boolean;
    downloadOnly: boolean;
    isPlaying: boolean;
    disabled: boolean;
    preload: AudioPlayer['props']['preload'];
    popoverContentInitialized: () => void;
    handleIsPlaying: (isPlaying: boolean) => void;
    handleDownload: (e: ReactMouseEvent<HTMLElement, MouseEvent>, index: number) => void;
    handlePlay: (e: ReactMouseEvent<HTMLElement, MouseEvent>) => void;
    handlePause: (e: ReactMouseEvent<HTMLElement, MouseEvent>) => void;
};

const SimpleAudioPlayer = createContext<SimpleAudioPlayerProps | null>(null);

export type SimpleAudioPlayerProviderProps = {
    entries?: Entry[];
    downloadOnly?: boolean;
    disabled?: boolean;
    preload?: AudioPlayer['props']['preload'];
    children: ReactNode;
};

export function SimpleAudioPlayerProvider({
    entries: initialEntries,
    disabled: initialDisabled = false,
    downloadOnly = false,
    preload = 'none',
    children,
}: SimpleAudioPlayerProviderProps) {
    const [entries, setEntries] = useState<Entry[] | null | undefined>(initialEntries);
    const [disabled, setDisabled] = useState(initialDisabled);
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasPlayerContent, setHasPlayerContent] = useState(false);

    const audioRef = useRef<HTMLAudioElement | null>(null);

    const popoverContentInitialized = useCallback(() => {
        setHasPlayerContent(true);
    }, []);

    const handleDownload = useCallback(
        (e: ReactMouseEvent<HTMLElement, MouseEvent>, index: number) => {
            e.preventDefault();

            entries?.[index]?.onDownloadClick?.();
        },
        [entries],
    );

    const handleIsPlaying = useCallback((isPlaying: boolean) => {
        setIsPlaying(isPlaying);
    }, []);

    const handlePlay = useCallback(
        (e: ReactMouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();

            handleIsPlaying(true);

            audioRef.current?.play();

            audioRef.current?.addEventListener('ended', () => {
                handleIsPlaying(false);
            });
        },
        [handleIsPlaying],
    );

    const handlePause = useCallback(
        (e: ReactMouseEvent<HTMLElement, MouseEvent>) => {
            e.preventDefault();

            handleIsPlaying(false);

            audioRef.current?.pause();
        },
        [handleIsPlaying],
    );

    useEffect(() => {
        setDisabled(initialDisabled);
    }, [initialDisabled]);

    useEffect(() => {
        setEntries(initialEntries);
    }, [initialEntries]);

    const simpleAudioPlauerValue = useMemo(
        () => ({
            audioRef,
            preload,
            entries,
            isPlaying,
            hasPlayerContent,
            disabled,
            downloadOnly,
            popoverContentInitialized,
            handleDownload,
            handleIsPlaying,
            handlePause,
            handlePlay,
        }),
        [
            preload,
            entries,
            isPlaying,
            hasPlayerContent,
            disabled,
            downloadOnly,
            popoverContentInitialized,
            handleDownload,
            handleIsPlaying,
            handlePause,
            handlePlay,
        ],
    );

    return <SimpleAudioPlayer.Provider value={simpleAudioPlauerValue}>{children}</SimpleAudioPlayer.Provider>;
}

export function useSimpleAudioPlayer(componentName: string) {
    const simpleAudioPlayer = useContext(SimpleAudioPlayer);

    if (!simpleAudioPlayer) {
        throw new Error(`SimpleAudioPlayer.${componentName} has to be inside the SimpleAudioPlayer.Root component`);
    }

    return simpleAudioPlayer;
}
