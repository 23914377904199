import { ComponentProps, ComponentPropsWithoutRef, ElementRef, forwardRef, ReactNode, useState } from 'react';
import { Button, ButtonProps } from '@components/Button';
import { BysideClassNamesIcons, Icon } from '@components/Icon';
import {
    Root as DropdownMenuRoot,
    type DropdownMenuProps,
    Trigger,
    Portal,
    type DropdownMenuPortalProps,
    Group,
} from '@radix-ui/react-dropdown-menu';
import { useHasOverflowed } from '@sidetalk/hooks';
import { Tooltip } from '@components/Tooltip';
import { isUndefined } from '@sidetalk/helpers';
import { Modal } from '@components/Modal';
import { cn } from '@helpers/index';
import { CustomArrow, CustomContent, CustomItem, CustomLabel, CustomSeparator } from './styles';
import { DropdownMenuItems } from './types';
import { Menu } from './components/Menu';
import { DroplistContextProvider, DroplistContextProviderProps } from './context';

export type { DropdownMenuItems };

type RootProps = DropdownMenuProps & DroplistContextProviderProps;

export function Root({ styleGuide, ...rest }: RootProps) {
    return (
        <DroplistContextProvider styleGuide={styleGuide}>
            <Tooltip.Provider>
                <DropdownMenuRoot {...rest} />
            </Tooltip.Provider>
        </DroplistContextProvider>
    );
}

Root.displayName = 'Droplist.Root';

type ContentProps = DropdownMenuPortalProps & ComponentPropsWithoutRef<typeof CustomContent>;

const Content = forwardRef<ElementRef<typeof CustomContent>, ContentProps>(
    ({ children, container, forceMount, ...rest }, forwardedRef) => (
        <Portal container={container ?? document.body} forceMount={forceMount}>
            <CustomContent ref={forwardedRef} sideOffset={10} alignOffset={-10} align="start" {...rest}>
                {children}
            </CustomContent>
        </Portal>
    ),
);

Content.displayName = 'Droplist.Content';

type BasicProps = DropdownMenuProps &
    Pick<ContentProps, 'styleGuide' | 'side' | 'align' | 'hideWhenDetached' | 'collisionBoundary' | 'container'> & {
        showEllipsed?: boolean;
        withoutIcon?: boolean;
        disabled?: boolean;
        variant?: ButtonProps['variant'];
        className?: ButtonProps['className'];
        chosen?: DropdownMenuItems;
        menus: DropdownMenuItems[];
        ellipsisCustom?: BysideClassNamesIcons;
        children?: ReactNode;
        onSelect?: (item: DropdownMenuItems) => void;
    };

function Basic({
    showEllipsed = false,
    withoutIcon = false,
    disabled = false,
    variant = 'primary',
    chosen,
    ellipsisCustom,
    styleGuide = 'coremedia',
    children,
    menus,
    onSelect,
    side,
    align,
    hideWhenDetached,
    collisionBoundary,
    className,
    container,
    ...rest
}: BasicProps) {
    const [itemSelected, setItemSelected] = useState<DropdownMenuItems | undefined>(undefined);
    const [inputValue, setInputValue] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [buttonRef, hasOverflowed] = useHasOverflowed<HTMLButtonElement>();

    const hasInputOnModal = !!itemSelected?.inputLabel;

    const handleCloseModal = () => {
        setItemSelected(undefined);
        setShowModal(false);
    };

    const handleConfirmModal = () => {
        if (itemSelected) {
            if (hasInputOnModal) {
                onSelect?.({
                    ...itemSelected,
                    inputValue,
                });
            } else {
                onSelect?.(itemSelected);
            }
        }

        handleCloseModal();
    };

    const handleItemSelect = (item: DropdownMenuItems) => {
        if (item.disabled) {
            return;
        }

        setItemSelected(item);
        if (item?.confirmationWindow) {
            setShowModal(true);
            setInputValue(item?.inputValue ?? '');
        } else if (item) {
            onSelect?.(item);
        }
    };

    const isModalConfirmButtonDisabled = hasInputOnModal && inputValue.trim().length === 0;
    const hasChildren = !isUndefined(children);
    const tooltipText = hasChildren ? children : chosen?.label;

    return (
        <Root styleGuide={styleGuide} {...rest}>
            <Tooltip.Basic content={hasOverflowed ? tooltipText : undefined}>
                <Trigger
                    aria-label={showEllipsed ? 'Open menu' : undefined}
                    onClick={(e) => e.stopPropagation()}
                    disabled={disabled}
                    asChild
                >
                    <Button
                        ref={buttonRef}
                        styleGuide={styleGuide}
                        variant={showEllipsed ? 'icon' : variant}
                        className={cn('max-w-40 first:[&_span]:truncate', showEllipsed && '[&_i]:text-xs', className)}
                    >
                        {showEllipsed && <Icon type="byside" iconName={ellipsisCustom ?? 'settings-small'} />}
                        {!showEllipsed && hasChildren && <span>{children}</span>}
                        {!hasChildren && !showEllipsed && (chosen?.label ?? '')}
                        {!withoutIcon && !showEllipsed && (
                            <Icon type="byside" iconName="arrow-small" className="!text-[7px] font-extrabold" />
                        )}
                    </Button>
                </Trigger>
            </Tooltip.Basic>
            <Content
                styleGuide={styleGuide}
                side={side}
                align={align}
                container={container}
                hideWhenDetached={hideWhenDetached}
                collisionBoundary={collisionBoundary}
            >
                {menus.map((item) => (
                    <Menu key={item.id ?? item.value} chosen={chosen} onSelect={handleItemSelect} item={item} />
                ))}
                {styleGuide === 'coremedia' && <CustomArrow />}
            </Content>
            <Modal.Root open={showModal} onOpenChange={setShowModal} styleGuide={styleGuide}>
                <Modal.Content onClick={(e) => e.stopPropagation()}>
                    <Modal.Title>{itemSelected?.header ?? 'Notice'}</Modal.Title>
                    <Modal.Body>
                        {itemSelected?.bodyPrevContent}
                        <Modal.Description>{itemSelected?.confirmationText}</Modal.Description>
                        {hasInputOnModal && (
                            <>
                                <Modal.Label htmlFor={String(itemSelected?.id) || itemSelected?.value}>
                                    {itemSelected?.inputLabel}
                                </Modal.Label>
                                <Modal.Input
                                    id={String(itemSelected?.id) || itemSelected?.value}
                                    type={itemSelected?.inputType ?? 'text'}
                                    maxLength={itemSelected?.inputMaxLength}
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                />
                            </>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Modal.CancelButton asChild onClick={handleCloseModal}>
                            <Modal.ButtonStyled>{itemSelected?.cancelLabel ?? 'Cancel'}</Modal.ButtonStyled>
                        </Modal.CancelButton>
                        <Modal.ActionButton
                            asChild
                            onClick={handleConfirmModal}
                            disabled={isModalConfirmButtonDisabled}
                        >
                            <Modal.ButtonStyled>{itemSelected?.confirmLabel ?? 'Confirm'}</Modal.ButtonStyled>
                        </Modal.ActionButton>
                    </Modal.Footer>
                </Modal.Content>
            </Modal.Root>
        </Root>
    );
}

Basic.displayName = 'Droplist.Basic';

export type DroplistProps = ComponentProps<typeof Root>;

export const Droplist = {
    Root,
    Trigger,
    Content,
    Group,
    Label: CustomLabel,
    Item: CustomItem,
    Menu,
    Separator: CustomSeparator,
    Arrow: CustomArrow,
    Basic,
};
