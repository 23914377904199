import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

type StyleGuideType = 'coremedia' | 'new';

type BubbleContextProps = {
    styleGuide: StyleGuideType;
    setStyleGuide: (value: StyleGuideType) => void;
};

const BubbleContext = createContext<BubbleContextProps | null>(null);

export type BubbleContextProviderProps = {
    children: ReactNode;
    styleGuide?: StyleGuideType;
};

export function BubbleContextProvider({
    styleGuide: initialStyleGuide = 'coremedia',
    children,
}: BubbleContextProviderProps) {
    const [styleGuide, setStyleGuide] = useState<StyleGuideType>(initialStyleGuide);

    useEffect(() => {
        setStyleGuide(initialStyleGuide);
    }, [initialStyleGuide]);

    const bubbleContextValue = useMemo(
        () => ({
            styleGuide,
            setStyleGuide,
        }),
        [styleGuide, setStyleGuide],
    );

    return <BubbleContext.Provider value={bubbleContextValue}>{children}</BubbleContext.Provider>;
}

export function useBubble(componentName: string) {
    const bubbleContext = useContext(BubbleContext);

    if (!bubbleContext) {
        throw new Error(`Bubble.${componentName} has to be inside the Bubble.Root component`);
    }

    return bubbleContext;
}
