import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type StyleGuideType = 'coremedia' | 'new';

type ModalContextProps = {
    styleGuide: StyleGuideType;
    setStyleGuide: (value: StyleGuideType) => void;
};

const ModalContext = createContext<ModalContextProps | null>(null);

export type ModalContextProviderProps = {
    children: ReactNode;
    styleGuide?: StyleGuideType;
};

export function ModalContextProvider({
    styleGuide: initialStyleGuide = 'coremedia',
    children,
}: ModalContextProviderProps) {
    const [styleGuide, setStyleGuide] = useState<StyleGuideType>(initialStyleGuide);

    useEffect(() => {
        setStyleGuide(initialStyleGuide);
    }, [initialStyleGuide]);

    return (
        <ModalContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                styleGuide,
                setStyleGuide,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
}

export function useModal(componentName: string) {
    const modalContext = useContext(ModalContext);

    if (!modalContext) {
        throw new Error(`Modal.${componentName} has to be inside the Modal.Root component`);
    }

    return modalContext;
}
