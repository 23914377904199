import { ChangeEvent } from 'react';
import { TextInput } from '@components/TextInput';
import { FilterOptionInput } from '@components/Filter/types';

export type InputOptionsProps = {
    filterOption: FilterOptionInput;
    onChange: (filterChanges: FilterOptionInput) => void;
};

const getInputType = (dataType: string) =>
    ({
        string: 'text',
        textarea: 'text',
        email: 'email',
        phone: 'tel',
    })[dataType];

export function InputOptions({ filterOption, onChange }: InputOptionsProps) {
    const handleInputChange = (newText: string) => {
        onChange({
            ...filterOption,
            value: newText,
        });
    };

    if (filterOption.dataType === 'number') {
        const handleInputNumber = (e: ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;

            if (value === '' || /^[0-9]+$/.test(value)) {
                handleInputChange(value);
            }
        };

        return (
            <TextInput.Root>
                <TextInput.Input
                    type="text"
                    max={filterOption.max}
                    min={filterOption.min}
                    disabled={!filterOption.editable}
                    onChange={handleInputNumber}
                    value={filterOption.value}
                    placeholder={filterOption.label}
                    pattern="^[0-9]+$"
                    inputMode="numeric"
                />
            </TextInput.Root>
        );
    }

    return (
        <TextInput.Root>
            <TextInput.Input
                type={getInputType(filterOption.dataType)}
                max={filterOption.max}
                min={filterOption.min}
                disabled={!filterOption.editable}
                onChange={(e) => handleInputChange(e.target.value)}
                value={filterOption.value}
                placeholder={filterOption.label}
            />
        </TextInput.Root>
    );
}
