export const formatDate = (date: string | Date, period = 'start') => {
    if (typeof date !== 'string') {
        return date;
    }

    const [day, hour] = date.split(' ');

    if (!hour) {
        const hoursToReturn = period === 'start' ? '00:00:00' : '23:59:59';
        return `${day} ${hoursToReturn}`;
    }

    return date;
};

export const setHoursToBeginningOfDay = (date: Date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
};

export const setHoursToEndOfDay = (date: Date) => {
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
};

export const getDatePickerDefaultRanges = () => {
    const today = new Date();
    const todayLastHour = new Date();
    todayLastHour.setHours(today.getHours() - 1);

    const todayLast24Hours = new Date();
    todayLast24Hours.setHours(today.getHours() - 24);

    const initDay = new Date();
    setHoursToBeginningOfDay(initDay);
    const endDay = new Date();
    setHoursToEndOfDay(endDay);

    const initYesterday = new Date();
    initYesterday.setDate(initDay.getDate() - 1);
    setHoursToBeginningOfDay(initYesterday);
    const endYesterday = new Date();
    endYesterday.setDate(endDay.getDate() - 1);
    setHoursToEndOfDay(endYesterday);

    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const currentWeekStart = new Date();
    currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());
    setHoursToBeginningOfDay(currentWeekStart);
    const currentWeekEnd = new Date();
    currentWeekEnd.setDate(currentWeekStart.getDate() + 6);
    setHoursToEndOfDay(currentWeekEnd);

    const lastWeekStart = new Date();
    lastWeekStart.setDate(lastWeekStart.getDate() - lastWeekStart.getDay() - 6);
    setHoursToBeginningOfDay(lastWeekStart);
    const lastWeekEnd = new Date();
    lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
    setHoursToEndOfDay(lastWeekEnd);

    const last7DaysStart = new Date();
    last7DaysStart.setDate(last7DaysStart.getDate() - 6);
    setHoursToBeginningOfDay(last7DaysStart);

    const last30DaysStart = new Date();
    last30DaysStart.setDate(last30DaysStart.getDate() - 30);
    setHoursToBeginningOfDay(last30DaysStart);
    const last30DaysEnd = new Date();
    last30DaysEnd.setDate(today.getDate() - 30);
    setHoursToBeginningOfDay(last30DaysEnd);

    const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    setHoursToBeginningOfDay(currentMonthStart);
    const currentMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    setHoursToEndOfDay(currentMonthEnd);

    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    setHoursToBeginningOfDay(lastMonthStart);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    setHoursToEndOfDay(lastMonthEnd);

    const lastThreeMonthsStart = new Date(today.getFullYear(), today.getMonth() - 3, 1);
    setHoursToBeginningOfDay(lastThreeMonthsStart);
    const lastThreeMonthsEnd = new Date(today.getFullYear(), today.getMonth(), 0);
    setHoursToEndOfDay(lastThreeMonthsEnd);

    return {
        'Last hour': [todayLastHour, today],
        'Last 24 hours': [todayLast24Hours, today],
        Today: [initDay, endDay],
        Yesterday: [initYesterday, endYesterday],
        'Current week': [currentWeekStart, currentWeekEnd],
        'Last week': [lastWeekStart, lastWeekEnd],
        'Last 7 days': [last7DaysStart, endDay],
        'Last 30 days': [last30DaysStart, last30DaysEnd],
        'Current month': [currentMonthStart, currentMonthEnd],
        'Last month': [lastMonthStart, lastMonthEnd],
        'Last 3 months': [lastThreeMonthsStart, lastThreeMonthsEnd],
    };
};
