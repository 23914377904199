import { ComponentPropsWithRef, ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { Icon } from '@components/Icon';
import { Root, List, Trigger, Content, IconCircle, Separator } from './styles';

type RootProps = ComponentPropsWithRef<typeof Root>;

type CustomRootProps = Omit<RootProps, 'defaultValue' | 'value' | 'onValueChange'> & {
    defaultStep?: RootProps['defaultValue'];
    step?: RootProps['value'];
    onStepChange?: RootProps['onValueChange'];
};

const CustomRoot = forwardRef<ElementRef<typeof Root>, CustomRootProps>(
    ({ defaultStep, step, onStepChange, ...rest }, forwardedRef) => (
        <Root ref={forwardedRef} {...rest} defaultValue={defaultStep} value={step} onValueChange={onStepChange} />
    ),
);

export type StepsRootProps = typeof CustomRoot;

CustomRoot.displayName = 'Steps.Root';

const CustomList = forwardRef<ElementRef<typeof List>, ComponentPropsWithoutRef<typeof List>>(
    (listProps, forwardedRef) => (
        <>
            <List ref={forwardedRef} {...listProps} />
            <Separator />
        </>
    ),
);

CustomList.displayName = 'Steps.List';

type CustomTriggerProps = ComponentPropsWithoutRef<typeof Trigger> & {
    stepOrder: number;
    isStepCompleted?: boolean;
};

const CustomTrigger = forwardRef<ElementRef<typeof Trigger>, CustomTriggerProps>(
    ({ isStepCompleted = false, stepOrder, children, ...rest }, forwardedRef) => (
        <Trigger ref={forwardedRef} {...rest}>
            <IconCircle isChecked={isStepCompleted}>
                {isStepCompleted ? <Icon type="bytalk" iconName="check-on" /> : stepOrder}
            </IconCircle>
            {children}
        </Trigger>
    ),
);

CustomTrigger.displayName = 'Steps.Trigger';

export const Steps = {
    Root: CustomRoot,
    List: CustomList,
    Trigger: CustomTrigger,
    Content,
};
