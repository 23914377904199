import { useEffect } from 'react';
import type {
    Field,
    FullCombinator,
    FullField,
    FullOperator,
    QueryBuilderProps as ReactQueryBuilderProps,
    RuleGroupType,
    RuleGroupTypeAny,
} from 'react-querybuilder';
import { QueryBuilder as ReactQueryBuilder } from 'react-querybuilder';
import { Locales } from '@components/DatePicker';
import { tv } from '@lib/tailwind-variants';
import {
    LocaleResources,
    QueryBuilderContextProvider,
    ResourcesType,
    QueryBuilderState,
    useQueryBuilderState,
} from './context/QueryBuilder';

import {
    AddGroup,
    AddRule,
    CombinatorSelector,
    FieldSelector,
    OperatorSelector,
    RemoveGroup,
    RemoveRule,
    ValueEditor,
} from './Components';

export type QueryBuilderField = Field;
export type QueryBuilderLocale = Locales;
export type QueryBuilderResources = ResourcesType;
export type QueryBuilderRuleGroup = RuleGroupType;
export type QueryBuilderLocaleResources = LocaleResources;
export type QueryBuilderRuleGroupTypeAny = RuleGroupTypeAny;
export type QueryBuilderFullField = FullField;
export type QueryBuilderFullOperator = FullOperator;
export type QueryBuilderFullCombinator = FullCombinator;

export type CustomFieldOptions = {
    search?: string;
    offset?: number;
    limit?: number;
    delay?: number;
};

export type QueryBuilderProps<
    RG extends QueryBuilderRuleGroupTypeAny,
    F extends QueryBuilderFullField,
    O extends QueryBuilderFullOperator,
    C extends QueryBuilderFullCombinator,
> = ReactQueryBuilderProps<RG, F, O, C> & QueryBuilderState;

const queryBuilderContainer = tv({
    slots: {
        container: 'overflow-x-hidden',
        rule: [
            '!m-0 w-full items-start py-0 pl-[60px] pr-0',
            '[&_.field-selector]:w-1/5 [&_.field-selector]:shrink-0 [&_.field-selector]:grow-0',
            '[&_.operator-selector]:w-[15%] [&_.operator-selector]:shrink-0 [&_.operator-selector]:grow-0',
            '[&_.value_selector]:flex-[auto] [&_.value_selector]:overflow-hidden',
            '[&_.query-value-editor]:flex [&_.query-value-editor]:flex-[auto]',
            '[&_.query-value-editor]:h-7 [&_.query-value-editor]:w-full [&_.query-value-editor]:text-xs',
            '[&_.query-value-editor]:bg-white [&_.query-value-editor]:text-[gray]',
            '[&_.query-value-editor]:shadow-none [&_.query-value-editor]:transition-[border-color]',
            '[&_.query-value-editor]:delay-[0.15s] [&_.query-value-editor]:duration-[ease-in-out]',
            '[&_.query-value-editor]:px-1.5 [&_.query-value-editor]:py-1',
            '[&_.query-value-editor]:rounded-none [&_.query-value-editor]:border-b-[gray]',
            '[&_.query-value-editor]:border-0 [&_.query-value-editor]:border-b',
            '[&_.query-value-editor]:border-solid [&_.query-value-editor]:focus:shadow-none',
            '[&_.query-value-editor]:focus:border-b-[red]',
        ],
        ruleGroup: [
            'relative m-0 flex min-h-[40px] flex-col gap-y-[5px] !rounded',
            '!border !border-solid !border-white !bg-[rgba(150,150,150,0.1)] !p-2.5',
            '[&_.ruleGroup-buttonsContainer]:flex [&_.ruleGroup-buttonsContainer]:gap-[8px]',
            '[&_.ruleGroup]:!my-0 [&_.ruleGroup]:!ml-[60px] [&_.ruleGroup]:mr-[30px]',
            '[&_.rule]:flex [&_.rule]:items-start [&_.rule]:gap-x-[5px]',
        ],
        header: 'flex items-center gap-x-[5px]',
        body: 'flex flex-col gap-y-[5px] empty:hidden',
        value: 'bg-red',
    },
});

const { body, container, header, rule, ruleGroup, value } = queryBuilderContainer();

function QueryBuilderComponent<
    RG extends QueryBuilderRuleGroupTypeAny,
    F extends QueryBuilderFullField,
    O extends QueryBuilderFullOperator,
    C extends QueryBuilderFullCombinator,
>({
    maxDepthLevel = 2,
    customFieldOptions,
    getCustomFieldOptions,
    resources,
    disabled,
    ...rest
}: Omit<QueryBuilderProps<RG, F, O, C>, 'locale' | 'localeResources'>) {
    const { setState: setInfo } = useQueryBuilderState();

    useEffect(() => {
        setInfo?.({
            maxDepthLevel,
            getCustomFieldOptions,
            customFieldOptions,
            resources,
            disabled,
        });
    }, [maxDepthLevel, getCustomFieldOptions, resources, disabled, setInfo, customFieldOptions]);

    const minWidthByLevel = 200 * (maxDepthLevel + 1);

    return (
        <div className={container()} style={{ minWidth: `${minWidthByLevel}px` }}>
            {/* @ts-expect-error generic error */}
            <ReactQueryBuilder
                controlElements={{
                    valueEditor: ValueEditor,
                    addRuleAction: AddRule,
                    removeRuleAction: RemoveRule,
                    removeGroupAction: RemoveGroup,
                    addGroupAction: AddGroup,
                    operatorSelector: OperatorSelector,
                    fieldSelector: FieldSelector,
                    combinatorSelector: CombinatorSelector,
                }}
                controlClassnames={{
                    rule: rule(),
                    ruleGroup: ruleGroup(),
                    header: header(),
                    body: body(),
                    value: value(),
                }}
                {...rest}
            />
        </div>
    );
}

export function QueryBuilder<
    RG extends QueryBuilderRuleGroupTypeAny,
    F extends QueryBuilderFullField,
    O extends QueryBuilderFullOperator,
    C extends QueryBuilderFullCombinator,
>(props: QueryBuilderProps<RG, F, O, C>) {
    const {
        maxDepthLevel = 2,
        customFieldOptions,
        getCustomFieldOptions,
        resources = {},
        disabled = false,
        locale,
        localeResources,
    } = props;

    return (
        <QueryBuilderContextProvider
            initialState={{
                maxDepthLevel,
                getCustomFieldOptions,
                customFieldOptions,
                resources,
                disabled,
                locale,
                localeResources,
            }}
        >
            {/* @ts-expect-error generic error */}
            <QueryBuilderComponent {...props} />
        </QueryBuilderContextProvider>
    );
}

QueryBuilder.displayName = 'QueryBuilder';
