import { StitchesCSS, styled } from '@styles';

export const FilterContainer = styled('div', {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '$2',

    '> [data-radix-popper-content-wrapper]:first-child + *': {
        '&::before': {
            content: 'initial',
        },
    },

    '> * + *': {
        '&:not([data-radix-popper-content-wrapper])': {
            '&::before': {
                content: '',
                position: 'absolute',
                left: 'calc(-$2)',
                top: '50%',
                width: '$2',
                height: 1,
                backgroundColor: '$gray100',
            },
        },
    },
});

FilterContainer.displayName = 'FilterContainer';

export const ButtonCustomStyle = {
    all: 'unset',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '$xs',
    padding: '0 $4',
    fontSize: '$xs',
    fontWeight: '$semibold',
    lineHeight: '$base',
    height: '2.25rem',
    gap: '$1',
    backgroundColor: '$gray050',
    border: '1px solid $gray100',
    color: '$gray850',

    '&:not(:disabled)': {
        cursor: 'pointer',

        "&[data-state='open'], &:focus, &:hover": {
            backgroundColor: '$white',

            span: {
                color: '$red500',
            },
        },

        '&:hover, &:focus': {
            borderColor: '$red500',
        },
    },

    '&[data-placeholder]': {
        color: '$gray850',
    },
} satisfies StitchesCSS;

export const CustomSelect = styled('div', {
    position: 'relative',
    height: '2.25rem',

    '> div': {
        $$plusSize: 'fit-content',
        width: '$$plusSize',

        '.sidetalk-select__': {
            '&control': {
                all: 'unset',
                boxSizing: 'border-box',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '$xs',
                padding: '0 $4',
                fontSize: '$xs',
                fontWeight: '$semibold',
                lineHeight: '$base',
                height: '2.25rem',
                gap: '$1',
                backgroundColor: '$gray050',
                border: '1px solid $gray100',
                color: '$gray850',
                minWidth: '$$plusSize',
            },

            '&placeholder': {
                color: '$gray400',
            },

            '&indicators': {
                display: 'none',
            },

            '&menu-list': {
                width: '$$plusSize',
                minWidth: '$40',
            },
        },
    },
});
