import {
    ButtonHTMLAttributes,
    ComponentPropsWithoutRef,
    ElementRef,
    HTMLAttributes,
    MouseEvent as ReactMouseEvent,
    forwardRef,
    useCallback,
    useImperativeHandle,
} from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { Button } from '@components/Button';
import { Popover } from '@components/Popover';
import { pickerButtonVariants } from '@components/DatePicker/styles';
import { cn } from '@helpers/index';
import { useTimePicker } from './context';

export const Trigger = forwardRef<ElementRef<typeof Popover.Trigger>, ComponentPropsWithoutRef<typeof Popover.Trigger>>(
    (props, forwardedRef) => {
        const { disabled } = useTimePicker('Trigger');

        return <Popover.Trigger ref={forwardedRef} disabled={disabled} {...props} />;
    },
);

Trigger.displayName = 'TimePicker.Trigger';

type CustomButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof pickerButtonVariants>;

export const CustomTriggerButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
    ({ styleGuide: mainStyleGuide, variant, size, isPopoverWithInput, hasError, className, ...rest }, forwardedRef) => {
        const { styleGuide } = useTimePicker('CustomTriggerButton');

        const selectedStyleGuide = mainStyleGuide ?? styleGuide;
        const defaultVariant = selectedStyleGuide === 'coremedia' ? 'boxed' : 'secondary';

        return (
            <button
                type="button"
                ref={forwardedRef}
                className={pickerButtonVariants({
                    styleGuide: selectedStyleGuide,
                    variant: variant ?? defaultVariant,
                    size,
                    isPopoverWithInput,
                    hasError,
                    className,
                }).replace(/,/g, ' ')}
                {...rest}
            />
        );
    },
);

CustomTriggerButton.displayName = 'TimePicker.CustomTriggerButton';

const contentVariants = tv({
    base: 'min-w-32',

    variants: {
        styleGuide: {
            coremedia: 'p-1',

            new: '',
        },
    },
});

type ContentProps = ComponentPropsWithoutRef<typeof Popover.Content> & VariantProps<typeof contentVariants>;

export const Content = forwardRef<ElementRef<typeof Popover.Content>, ContentProps>(
    ({ styleGuide: mainStyleGuide, className, ...rest }, forwardedRef) => {
        const { contentRef, styleGuide } = useTimePicker('Content');

        useImperativeHandle(forwardedRef, () => contentRef.current!, [contentRef]);

        return (
            <Popover.Content
                ref={contentRef}
                className={contentVariants({ styleGuide: mainStyleGuide ?? styleGuide, className })}
                {...rest}
            />
        );
    },
);

Content.displayName = 'TimePicker.Content';

export const Body = forwardRef<HTMLElement, HTMLAttributes<HTMLElement>>(({ className, ...rest }, forwardedRef) => (
    <section ref={forwardedRef} className={cn('flex [&>div]:flex-1', className)} {...rest} />
));

Body.displayName = 'TimePicker.Body';

const timeButtonVariants = tv({
    base: ['block w-full border-0', 'text-gray-850 bg-white text-sm'],

    variants: {
        styleGuide: {
            coremedia: [
                'outline-none',
                'enabled:focus-within:bg-blue-100 enabled:hover:bg-blue-100',
                'data-[checked=true]:bg-gray-50 data-[checked=true]:font-bold',
                'disabled:text-gray-150 disabled:cursor-not-allowed',
            ],

            new: [
                'rounded border-0',
                'enabled:hover:bg-red-100',
                'enabled:focus-within:outline enabled:focus-within:outline-1 enabled:focus-within:outline-offset-[-2px] enabled:focus-within:outline-red-500',
                'data-[checked=true]:bg-red-100',
                'disabled:text-gray-150 disabled:cursor-not-allowed',
            ],
        },
    },
});

type TimeButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof timeButtonVariants>;

export const TimeButton = forwardRef<HTMLButtonElement, TimeButtonProps>(
    ({ styleGuide: mainStyleGuide, className, ...rest }, forwardedRef) => {
        const { styleGuide } = useTimePicker('TimeButton');

        return (
            <button
                ref={forwardedRef}
                type="button"
                className={timeButtonVariants({ styleGuide: mainStyleGuide ?? styleGuide, className })}
                {...rest}
            />
        );
    },
);

TimeButton.displayName = 'TimePicker.TimeButton';

const footerVariants = tv({
    base: 'mt-4 flex justify-between gap-4',

    variants: {
        styleGuide: {
            coremedia: 'px-2 pb-2',

            new: '',
        },
    },
});

type FooterProps = HTMLAttributes<HTMLElement> & VariantProps<typeof footerVariants>;

export const Footer = forwardRef<HTMLElement, FooterProps>(
    ({ styleGuide: mainStyleGuide, className, ...rest }, forwardedRef) => {
        const { styleGuide } = useTimePicker('Footer');

        return (
            <footer
                ref={forwardedRef}
                className={footerVariants({ styleGuide: mainStyleGuide ?? styleGuide, className })}
                {...rest}
            />
        );
    },
);

Footer.displayName = 'TimePicker.Footer';

export const NowButton = forwardRef<ElementRef<typeof Button>, ComponentPropsWithoutRef<typeof Button>>(
    ({ styleGuide: mainStyleGuide, onClick, ...rest }, forwardedRef) => {
        const { styleGuide, handleSelectCurrentTime } = useTimePicker('NowButton');

        const handleButtonClick = useCallback(
            (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
                onClick?.(e);
                handleSelectCurrentTime();
            },
            [handleSelectCurrentTime, onClick],
        );

        return (
            <Button
                ref={forwardedRef}
                type="button"
                variant="secondary"
                size="sm"
                onClick={handleButtonClick}
                styleGuide={mainStyleGuide ?? styleGuide}
                {...rest}
            />
        );
    },
);

NowButton.displayName = 'TimePicker.NowButton';

export const ClearButton = forwardRef<ElementRef<typeof Button>, ComponentPropsWithoutRef<typeof Button>>(
    ({ styleGuide: mainStyleGuide, onClick, ...rest }, forwardedRef) => {
        const { styleGuide, allowEmpty, handleClearTime } = useTimePicker('ClearButton');

        const handleButtonClick = useCallback(
            (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
                onClick?.(e);
                handleClearTime();
            },
            [handleClearTime, onClick],
        );

        if (!allowEmpty) {
            return null;
        }

        return (
            <Button
                ref={forwardedRef}
                type="button"
                variant="secondary"
                size="sm"
                onClick={handleButtonClick}
                styleGuide={mainStyleGuide ?? styleGuide}
                {...rest}
            />
        );
    },
);

ClearButton.displayName = 'TimePicker.ClearButton';

export const CloseButton = forwardRef<
    ElementRef<typeof Popover.CloseButton>,
    ComponentPropsWithoutRef<typeof Popover.CloseButton>
>((props, forwardedRef) => {
    const { closeButtonRef } = useTimePicker('CloseButton');

    useImperativeHandle(forwardedRef, () => closeButtonRef.current!, [closeButtonRef]);

    return <Popover.CloseButton ref={closeButtonRef} {...props} />;
});

CloseButton.displayName = 'TimePicker.CloseButton';
