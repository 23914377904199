import { ElementRef, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import {
    Arrow,
    type DropdownMenuArrowProps,
    Content,
    type DropdownMenuContentProps,
    Item,
    type DropdownMenuItemProps,
    Label,
    type DropdownMenuLabelProps,
    Separator,
    type DropdownMenuSeparatorProps,
} from '@radix-ui/react-dropdown-menu';
import { cn } from '@helpers/index';
import { useDroplist } from './context';

const itemVariants = tv({
    base: ['cursor-pointer text-xs font-semibold outline-none [&_i]:mr-2', 'data-[disabled]:cursor-not-allowed'],

    variants: {
        styleGuide: {
            new: [
                'leading-[1.625rem] text-gray-700',
                'data-[highlighted]:text-red-500',
                'data-[disabled]:text-gray-250',
                'data-[active=true]:text-red-500',
            ],

            coremedia: [
                'text-coremedia-grey-700 truncate px-5 uppercase leading-7',
                'data-[highlighted]:bg-black/5',
                'data-[disabled]:text-coremedia-grey-300',
                'data-[active=true]:bg-coremedia-grey-100',
            ],
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomItemProps = DropdownMenuItemProps & VariantProps<typeof itemVariants>;

export const CustomItem = forwardRef<ElementRef<typeof Item>, CustomItemProps>(
    ({ children, styleGuide: mainStyleGuide, className, ...rest }, forwardedRef) => {
        const { styleGuide } = useDroplist('Item');

        return (
            <Item
                className={itemVariants({ styleGuide: mainStyleGuide ?? styleGuide, className })}
                {...rest}
                ref={forwardedRef}
            >
                {children}
            </Item>
        );
    },
);

CustomItem.displayName = 'Droplist.Item';

const contentVariants = tv({
    base: 'z-popover bg-white',

    variants: {
        styleGuide: {
            new: [
                'rounded-md border border-solid border-gray-200',
                'min-w-[13.75rem] p-4',
                'shadow-popover/content transition-all will-change-[transform,opacity]',
                'data-[state=open]:data-[side=top]:animate-slideDownAndFade',
                'data-[state=open]:data-[side=right]:animate-slideLeftAndFade',
                'data-[state=open]:data-[side=left]:animate-slideRightAndFade',
                'data-[state=open]:data-[side=bottom]:animate-slideUpAndFade',
            ],

            coremedia: [
                'max-h-[300px] min-w-36 max-w-[300px] overflow-auto py-1',
                'text-coremedia-grey-700 rounded outline-none',
                'data-[state=open]:animation-fade-in shadow-[0_0_4px_0_rgba(0,0,0,0.36)]',
            ],
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomContentProps = DropdownMenuContentProps & VariantProps<typeof contentVariants>;

export const CustomContent = forwardRef<ElementRef<typeof Content>, CustomContentProps>(
    ({ styleGuide: mainStyleGuide, className, ...rest }, forwardedRef) => {
        const { styleGuide } = useDroplist('Content');

        return (
            <Content
                ref={forwardedRef}
                className={contentVariants({ styleGuide: mainStyleGuide ?? styleGuide, className })}
                {...rest}
            />
        );
    },
);

const labelVariants = tv({
    base: '',

    variants: {
        styleGuide: {
            new: 'leading-base text-gray-250 text-xs uppercase',

            coremedia: 'text-xxs text-coremedia-grey-300 px-5 pb-0.5 pt-2 font-medium',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomLabelProps = DropdownMenuLabelProps & VariantProps<typeof labelVariants>;

export const CustomLabel = forwardRef<ElementRef<typeof Label>, CustomLabelProps>(
    ({ styleGuide: mainStyleGuide, className, ...rest }, forwardedRef) => {
        const { styleGuide } = useDroplist('Label');

        return (
            <Label
                ref={forwardedRef}
                className={labelVariants({ styleGuide: mainStyleGuide ?? styleGuide, className })}
                {...rest}
            />
        );
    },
);

CustomLabel.displayName = 'Droplist.Label';

const separatorVariants = tv({
    base: 'h-px',

    variants: {
        styleGuide: {
            new: 'my-2 bg-gray-100',

            coremedia: 'bg-coremedia-grey-200',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomSeparatorProps = DropdownMenuSeparatorProps & VariantProps<typeof separatorVariants>;

export const CustomSeparator = forwardRef<ElementRef<typeof Separator>, CustomSeparatorProps>(
    ({ styleGuide: mainStyleGuide, className, ...rest }, forwardedRef) => {
        const { styleGuide } = useDroplist('Separator');

        return (
            <Separator
                ref={forwardedRef}
                className={separatorVariants({ styleGuide: mainStyleGuide ?? styleGuide, className })}
                {...rest}
            />
        );
    },
);

CustomSeparator.displayName = 'Droplist.Separator';

export const CustomArrow = forwardRef<ElementRef<typeof Arrow>, DropdownMenuArrowProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Arrow
            ref={forwardedRef}
            className={cn('fill-white drop-shadow-[0_1px_0_rgb(0,0,0,0.18)]', className)}
            {...rest}
        />
    ),
);

CustomArrow.displayName = 'Droplist.Arrow';
