import { createStitches, defaultThemeMap, CSS } from '@stitches/react';
import { colors, fonts, fontSizes, fontWeights, lineHeights, radii, space } from '@sidetalk/tokens';

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } = createStitches({
    themeMap: {
        ...defaultThemeMap,
        transform: 'space',
    },
    theme: {
        colors,
        fonts,
        fontSizes,
        fontWeights,
        lineHeights,
        radii,
        space,
        sizes: space,
    },
});

export type StitchesCSS = CSS<typeof config>;
