import { ComponentPropsWithoutRef, ElementRef, HTMLAttributes, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import {
    AccordionContent,
    type AccordionContentProps,
    AccordionHeader,
    type AccordionHeaderProps,
    AccordionItem,
    type AccordionItemProps,
    AccordionTrigger,
    type AccordionTriggerProps,
} from '@radix-ui/react-accordion';
import { cn } from '@helpers/index';
import { Icon } from '@components/Icon';
import { useAccordion } from './context';

const headerVariants = tv({
    base: 'm-0 flex will-change-[background]',

    variants: {
        highlight: {
            true: 'animate-fadeAccordion',
        },
    },

    defaultVariants: {
        highlight: false,
    },
});

type CustomHeaderProps = AccordionHeaderProps & VariantProps<typeof headerVariants>;

export const CustomHeader = forwardRef<ElementRef<typeof AccordionHeader>, CustomHeaderProps>(
    ({ highlight, className, ...rest }, forwardedRef) => (
        <AccordionHeader
            ref={forwardedRef}
            className={headerVariants({
                highlight,
                className,
            })}
            {...rest}
        />
    ),
);

const arrowVariants = tv({
    base: [
        '-rotate-90 text-[0.5rem] font-semibold transition-transform will-change-transform',
        'group-data-[state=open]/accordion:rotate-0',
    ],

    variants: {
        styleGuide: {
            coremedia: [
                'text-coremedia-grey-700 mr-4',
                'group-data-[disabled]/accordion:text-coremedia-grey-400',
                'group-hover/accordion:text-coremedia-grey-500',
            ],

            new: ['grid size-6 place-items-center text-red-500', 'group-data-[disabled]/accordion:text-gray-250'],
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type ArrowProps = Omit<ComponentPropsWithoutRef<typeof Icon>, 'type' | 'iconName'> &
    Omit<VariantProps<typeof arrowVariants>, 'styleGuide'>;

export const Arrow = forwardRef<ElementRef<typeof Icon>, ArrowProps>(({ className, ...rest }, forwardedRef) => {
    const { styleGuide } = useAccordion('Arrow');

    return (
        <Icon
            ref={forwardedRef}
            aria-hidden
            type="byside"
            iconName="arrow-small"
            className={arrowVariants({ styleGuide, className })}
            {...rest}
        />
    );
});

const triggerVariants = tv({
    base: [
        'group/accordion box-border border-0 bg-transparent outline-none',
        'flex h-12 flex-1 cursor-pointer items-center',
        'font-semibold transition-colors',
        'data-[disabled]:cursor-not-allowed',
    ],

    variants: {
        styleGuide: {
            coremedia: [
                'border-coremedia-grey-200 text-coremedia-grey-700 px-5 py-4 text-sm',
                'data-[disabled]:text-coremedia-grey-200',
            ],

            new: ['border-gray-200 px-6 text-sm text-black', 'data-[disabled]:text-gray-200'],
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomTriggerProps = AccordionTriggerProps & Omit<VariantProps<typeof triggerVariants>, 'styleGuide'>;

export const CustomTrigger = forwardRef<ElementRef<typeof AccordionTrigger>, CustomTriggerProps>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useAccordion('Trigger');

        return <AccordionTrigger ref={forwardedRef} className={triggerVariants({ styleGuide, className })} {...rest} />;
    },
);

export const ExtraInfoContainer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...rest }, forwardedRef) => (
        <div ref={forwardedRef} className={cn('ml-auto flex items-center', className)} {...rest} />
    ),
);

const itemVariants = tv({
    base: 'overflow-hidden border-0 border-solid',

    variants: {
        styleGuide: {
            coremedia: 'border-coremedia-grey-200',

            new: 'border-gray-200',
        },

        border: {
            'no-border': '',

            top: 'border-t',

            bottom: 'border-b',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
        border: 'top',
    },
});

type ItemProps = AccordionItemProps & Omit<VariantProps<typeof itemVariants>, 'styleGuide'>;

export const Item = forwardRef<ElementRef<typeof AccordionItem>, ItemProps>(
    ({ border: mainBorder, className, ...rest }, forwardedRef) => {
        const { styleGuide, border } = useAccordion('Item');

        return (
            <AccordionItem
                ref={forwardedRef}
                className={itemVariants({
                    styleGuide,
                    border: mainBorder ?? border,
                    className,
                })}
                {...rest}
            />
        );
    },
);

Item.displayName = 'Accordion.Item';

export const CustomContent = forwardRef<ElementRef<typeof AccordionContent>, AccordionContentProps>(
    ({ className, forceMount, ...rest }, forwardedRef) => (
        <AccordionContent
            ref={forwardedRef}
            forceMount={forceMount}
            className={cn(
                [
                    'overflow-hidden will-change-[height]',
                    forceMount ? 'data-[state=open]:block' : 'data-[state=open]:animate-slideDownAccordion',
                    forceMount ? 'data-[state=closed]:hidden' : 'data-[state=closed]:animate-slideUpAccordion',
                ],
                className,
            )}
            {...rest}
        />
    ),
);

const contentStyledVariants = tv({
    base: '',

    variants: {
        styleGuide: {
            coremedia: '',
            new: 'px-12 pb-10 pt-4',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type ContentStyledProps = HTMLAttributes<HTMLDivElement> &
    Omit<VariantProps<typeof contentStyledVariants>, 'styleGuide'>;

export const ContentStyled = forwardRef<HTMLDivElement, ContentStyledProps>(({ className, ...rest }, forwardedRef) => {
    const { styleGuide } = useAccordion('ContentStyled');

    return <div ref={forwardedRef} className={contentStyledVariants({ styleGuide, className })} {...rest} />;
});
