/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { styled } from '@styles';
import { ButtonCustomStyle } from '../styles';

export const PopoverCloseButtonContainer = styled('div', {
    position: 'absolute',
    top: '50%',
    bottom: 0,
    right: 1,
    width: '40%',
    borderRadius: '$xs',
    height: 'calc(100% - 2px)',
    transform: 'translateY(-50%)',
    background: 'linear-gradient(to right, #ffffff00, $white, $white)',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.1s linear',
    cursor: 'pointer',
});

export const PopoverButtonsContainer = styled('div', {
    position: 'relative',

    '&:hover, &:focus-within': {
        'button + div': {
            opacity: 1,

            button: {
                pointerEvents: 'initial',
            },
        },
    },

    variants: {
        disabled: {
            true: {
                [`> ${PopoverCloseButtonContainer}`]: {
                    display: 'none',
                },
            },
        },
    },
});

export const PopoverCloseButton = styled('button', {
    all: 'unset',
    position: 'absolute',
    top: '50%',
    right: '5%',
    padding: '$1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '$4',
    height: '$4',
    transform: 'translate3d(0, -50%, 0)',
    borderRadius: '$xs',
    cursor: 'default',
    pointerEvents: 'initial',

    '&:not(:disabled)': {
        cursor: 'pointer',

        '&:hover, &:focus': {
            outline: '1px solid $red500',
        },
    },
});

export const PopoverContentButtonsContainer = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '$4',
    marginTop: '$4',
});

export const PopoverTriggerButton = styled('button', {
    ...ButtonCustomStyle,

    padding: '0 $4 0 $1',
    minWidth: '$16',
    fontWeight: '$regular',

    i: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '$4',
        height: '$4',
        padding: '$1',
    },

    '> span': {
        fontWeight: '$semibold',

        span: {
            fontWeight: '$regular',
        },
    },

    '&:not(:disabled)': {
        cursor: 'pointer',

        "&[data-state='open'], &:focus, &:hover": {
            span: {
                color: '$gray850',
            },
        },
    },

    '&:disabled': {
        cursor: 'not-allowed',
    },
});
