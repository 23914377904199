import { ButtonHTMLAttributes, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { buttonVariants } from '@components/Button';
import { Slot } from '@radix-ui/react-slot';

const datePickerButtonVariants = tv({
    extend: buttonVariants,
    base: 'justify-start',

    variants: {
        styleGuide: {
            coremedia: [
                'enabled:text-coremedia-grey-700 m-0 h-7 rounded-none !border-0 bg-white px-2.5 text-[0.6875rem]',
                'enabled:hover:text-coremedia-grey-500 shadow-none',
            ].join(' '),

            new: 'px-4 [&_i]:text-lg',
        },
    },

    defaultVariants: {
        variant: 'secondary',
    },
});

type CustomButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
    VariantProps<typeof datePickerButtonVariants> & {
        asChild?: boolean;
    };

export const CustomRangeTriggerButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
    ({ styleGuide, variant, size, className, asChild, ...rest }, forwardedRef) => {
        const Comp = asChild ? Slot : 'button';

        return (
            <Comp
                ref={forwardedRef}
                className={datePickerButtonVariants({ styleGuide, variant, size, className }).replace(/,/g, ' ')}
                {...rest}
            />
        );
    },
);
