import { tv } from '@lib/tailwind-variants';

export const rangePopoverButtonContainerVariants = tv({
    base: 'flex items-center justify-between gap-1',

    variants: {
        styleGuide: {
            new: 'w-[calc(40px_*_7)]',

            coremedia: 'w-auto',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});
