import { ButtonHTMLAttributes, ComponentPropsWithoutRef, ElementRef, HTMLAttributes, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { Bubble } from '@components/Bubble';
import { cn } from '@helpers/index';
import { LineContextProvider, LineContextProviderProps, useLine } from './context';

type RootProps = ButtonHTMLAttributes<HTMLButtonElement> & LineContextProviderProps;

export const Root = forwardRef<HTMLButtonElement, RootProps>(
    ({ hasErrors = false, className, ...rest }, forwardedRef) => (
        <LineContextProvider hasErrors={hasErrors}>
            <button
                type="button"
                ref={forwardedRef}
                className={cn(
                    [
                        'flex h-12 w-full cursor-pointer items-center bg-transparent p-4 transition-colors',
                        'border-coremedia-grey-200 border-0 border-b border-solid outline-none',
                        'enabled:hover:bg-coremedia-grey-100',
                        'disabled:cursor-not-allowed',
                    ],
                    className,
                )}
                {...rest}
            />
        </LineContextProvider>
    ),
);

Root.displayName = 'Line.Root';

export const statusCircleVariant = tv({
    base: 'mr-3 size-2.5 rounded-full',

    variants: {
        hasErrors: {
            true: 'bg-red-700',
            false: 'bg-green-500',
        },
    },

    defaultVariants: {
        hasErrors: false,
    },
});

type StatusCircleProps = Omit<HTMLAttributes<HTMLDivElement> & VariantProps<typeof statusCircleVariant>, 'children'>;

export const StatusCircle = forwardRef<HTMLDivElement, StatusCircleProps>(
    ({ hasErrors: mainHasErrors, className, ...rest }, forwardedRef) => {
        const { hasErrors } = useLine('StatusCircle');

        return (
            <div
                ref={forwardedRef}
                className={statusCircleVariant({
                    hasErrors: mainHasErrors ?? hasErrors,
                    className,
                })}
                {...rest}
            />
        );
    },
);

StatusCircle.displayName = 'Line.StatusCircle';

export const Title = forwardRef<HTMLHeadingElement, HTMLAttributes<HTMLHeadingElement>>(
    ({ className, ...rest }, forwardedRef) => (
        // eslint-disable-next-line jsx-a11y/heading-has-content
        <h3
            ref={forwardedRef}
            className={cn([
                'm-0 mr-3 min-w-[65px]',
                'text-coremedia-grey-700 text-left text-base uppercase first-line:font-semibold',
                className,
            ])}
            {...rest}
        />
    ),
);

Title.displayName = 'Line.Title';

export const CustomBubble = forwardRef<ElementRef<typeof Bubble.Label>, ComponentPropsWithoutRef<typeof Bubble.Label>>(
    (props, forwardedRef) => {
        const { hasErrors } = useLine('Bubble');

        const bubbleStatus = hasErrors ? 'error' : 'info';

        return (
            <Bubble.Root status={bubbleStatus}>
                <Bubble.Label ref={forwardedRef} {...props} />
            </Bubble.Root>
        );
    },
);

CustomBubble.displayName = 'Line.Bubble';
