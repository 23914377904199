import { ComponentPropsWithoutRef, ElementRef, forwardRef, useEffect, useState } from 'react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { tv, VariantProps } from '@lib/tailwind-variants';
import { isFunction } from '@sidetalk/helpers';
import { Icon } from '@components/Icon';
import { cn } from '@helpers/index';
import { Popover } from '@components/Popover';
import { PopoverProps } from '@radix-ui/react-popover';
import { SimpleAudioPlayerProvider, SimpleAudioPlayerProviderProps, useSimpleAudioPlayer } from './context';
import { Entry as SimplePlayerAudioEntry } from './types';

export type { SimplePlayerAudioEntry };

const PopoverTrigger = forwardRef<ElementRef<typeof Popover.Trigger>, ComponentPropsWithoutRef<typeof Popover.Trigger>>(
    (props, forwardedRef) => <Popover.Trigger ref={forwardedRef} {...props} />,
);

PopoverTrigger.displayName = 'SimpleAudioPlayer.PopoverTrigger';

function Audio() {
    const { entries, preload, audioRef, hasPlayerContent, downloadOnly } = useSimpleAudioPlayer('Audio');

    if (hasPlayerContent || downloadOnly) {
        return undefined;
    }

    const entry = entries?.[0];
    const source = isFunction(entry?.src) ? entry?.src() : entry?.src;

    // eslint-disable-next-line jsx-a11y/media-has-caption
    return <audio ref={audioRef} src={source} preload={preload} className="sr-only" />;
}

type CustomRootProps = SimpleAudioPlayerProviderProps & PopoverProps;

function CustomRoot({ entries, preload, disabled, downloadOnly, children, ...rest }: CustomRootProps) {
    return (
        <SimpleAudioPlayerProvider entries={entries} preload={preload} disabled={disabled} downloadOnly={downloadOnly}>
            <Popover.Root {...rest}>
                {children}
                <Audio />
            </Popover.Root>
        </SimpleAudioPlayerProvider>
    );
}

CustomRoot.displayName = 'SimpleAudioPlayer.Root';

export type SimpleAudioPlayerRootProps = ComponentPropsWithoutRef<typeof CustomRoot>;

type PopoverContentProps = ComponentPropsWithoutRef<typeof Popover.Content> & {
    preventCloseWhenPlaying?: boolean;
};

const PopoverContent = forwardRef<ElementRef<typeof Popover.Content>, PopoverContentProps>(
    ({ preventCloseWhenPlaying, container, children, ...rest }, forwardedRef) => {
        const { isPlaying, popoverContentInitialized } = useSimpleAudioPlayer('PopoverContent');

        useEffect(() => {
            popoverContentInitialized();
        }, [popoverContentInitialized]);

        return (
            <Popover.Content
                ref={forwardedRef}
                {...rest}
                container={container}
                collisionBoundary={container}
                hideWhenDetached={!!container}
                onInteractOutside={(e) => {
                    if (preventCloseWhenPlaying && isPlaying) {
                        e.preventDefault();
                    }
                }}
            >
                {children}
                <Popover.Arrow />
            </Popover.Content>
        );
    },
);

PopoverContent.displayName = 'SimpleAudioPlayer.PopoverContent';

const buttonCustomClasses = cn([
    'flex size-5 cursor-pointer items-center justify-center rounded-full transition-colors',
    'text-coremedia-blue-500 text-sm outline-none',
    'border-coremedia-blue-500 border border-solid',
    'enabled:data-[state=closed]:border-coremedia-grey-700 enabled:data-[state=closed]:text-coremedia-grey-700',
    'enabled:data-[state=closed]:hover:border-coremedia-blue-500 enabled:data-[state=closed]:hover:text-coremedia-blue-500',
    'disabled:text-coremedia-grey-400 disabled:border-coremedia-grey-400 disabled:cursor-not-allowed',
    '[&>i]:font-semibold',
]);

type AudioButtonProps = ComponentPropsWithoutRef<'button'> & {
    downloadLabel?: string;
    pauseLabel?: string;
    playLabel?: string;
};

const AudioButton = forwardRef<HTMLButtonElement, AudioButtonProps>(
    ({ className, downloadLabel, pauseLabel, playLabel, ...rest }, forwarededRef) => {
        const {
            entries,
            disabled,
            isPlaying,
            downloadOnly,
            handleDownload,
            handlePause,
            handlePlay,
            hasPlayerContent,
        } = useSimpleAudioPlayer('Buttons');

        const hasDownloadFunction = isFunction(entries?.[0]?.onDownloadClick);
        const isDisabled = !entries?.[0] || disabled;

        if (downloadOnly) {
            return (
                <button
                    ref={forwarededRef}
                    onClick={disabled ? undefined : (e) => handleDownload(e, 0)}
                    disabled={isDisabled || !hasDownloadFunction}
                    className={cn(buttonCustomClasses, 'text-xxs', className)}
                    {...rest}
                >
                    <Icon type="byside" iconName="download" label={downloadLabel ?? 'Download'} />
                </button>
            );
        }

        if (isPlaying) {
            return (
                <button
                    ref={forwarededRef}
                    onClick={disabled || hasPlayerContent ? undefined : (e) => handlePause(e)}
                    disabled={isDisabled}
                    className={cn(buttonCustomClasses, 'text-xs', className)}
                    {...rest}
                >
                    <Icon type="byside" iconName="pause-call" label={pauseLabel ?? 'Pause'} />
                </button>
            );
        }

        return (
            <button
                ref={forwarededRef}
                onClick={disabled || hasPlayerContent ? undefined : (e) => handlePlay(e)}
                disabled={isDisabled}
                className={cn(buttonCustomClasses, 'pl-0.5', className)}
                {...rest}
            >
                <Icon type="byside" iconName="broadcast-play" label={playLabel ?? 'Play'} />
            </button>
        );
    },
);

AudioButton.displayName = 'SimpleAudioPlayer.Button';

// Array created because tailwind wasn't recognizing the class to build the css
const totalTimeClasses = [
    String.raw`[&_.rhap\_total-time]:right-[17%]`,
    String.raw`[&_.rhap\_total-time]:right-[12%]`,
    String.raw`[&_.rhap\_total-time]:right-[9%]`,
    String.raw`[&_.rhap\_total-time]:right-[8%]`,
];

const simpleAudioPlayerSlots = tv({
    slots: {
        container: 'bg-coremedia-white shadow-audio flex flex-col px-4 py-2.5 outline-none *:outline-none',
        main: String.raw`[&_.rhap\_main]:flex [&_.rhap\_main]:flex-1 [&_.rhap\_main]:flex-col`,
        progressSection: String.raw`[&_.rhap\_progress-section]:flex [&_.rhap\_progress-section]:flex-[3_1_auto] [&_.rhap\_progress-section]:items-center`,
        time: [
            String.raw`[&_.rhap\_time]:absolute [&_.rhap\_time]:bottom-[68%] [&_.rhap\_time]:w-8`,
            String.raw`[&_.rhap\_time]:text-coremedia-grey-700 [&_.rhap\_time]:text-xs [&_.rhap\_total-time]:text-right`,
        ],
        volumeButton: String.raw`[&_.rhap\_volume-button]:size-6`,
        controlsSection: [
            String.raw`[&_.rhap\_controls-section]:relative [&_.rhap\_controls-section]:flex [&_.rhap\_controls-section]:items-center`,
            String.raw`[&_.rhap\_controls-section]:mt-2 [&_.rhap\_controls-section]:h-10 [&_.rhap\_controls-section]:flex-[1_1_auto]`,
        ],
        volumeBarArea: [
            String.raw`[&_.rhap\_volume-bar-area]:ml-2 [&_.rhap\_volume-bar-area]:flex [&_.rhap\_volume-bar-area]:items-center`,
            String.raw`[&_.rhap\_volume-bar-area]:h-3.5 [&_.rhap\_volume-bar-area]:w-full [&_.rhap\_volume-bar-area]:cursor-pointer`,
        ],
        volumeBar: [
            String.raw`[&_.rhap\_volume-bar]:relative [&_.rhap\_volume-bar]:h-1 [&_.rhap\_volume-bar]:w-full [&_.rhap\_volume-bar]:rounded-full`,
            String.raw`[&_.rhap\_volume-bar]:bg-coremedia-grey-200`,
        ],
        volumeContainer: String.raw`[&_.rhap\_volume-container]:flex [&_.rhap\_volume-container]:flex-[1_1_40%] [&_.rhap\_volume-container]:items-center`,
        volumeIndicator: [
            String.raw`[&_.rhap\_volume-indicator]:bg-coremedia-blue-500 [&_.rhap\_volume-indicator]:absolute [&_.rhap\_volume-indicator]:size-3`,
            String.raw`[&_.rhap\_volume-indicator]:opacity-0.9 [&_.rhap\_volume-indicator]:cursor-pointer [&_.rhap\_volume-indicator]:shadow`,
            String.raw`[&_.rhap\_volume-indicator]:top-[-0.25rem] [&_.rhap\_volume-indicator]:ml-[-0.375rem] [&_.rhap\_volume-indicator]:rounded-full`,
        ],
        progressContainer: [
            String.raw`[&_.rhap\_progress-container]:flex [&_.rhap\_progress-container]:flex-[1_0_auto] [&_.rhap\_progress-container]:items-center`,
            String.raw`[&_.rhap\_progress-container]:h-5 [&_.rhap\_progress-container]:cursor-pointer [&_.rhap\_progress-container]:self-center`,
            String.raw`[&_.rhap\_progress-container]:mx-[calc(0.625rem_+_1%)] [&_.rhap\_progress-container]:select-none`,
        ],
        progressBar: [
            String.raw`[&_.rhap\_progress-bar]:relative [&_.rhap\_progress-bar]:z-0 [&_.rhap\_progress-bar]:h-1 [&_.rhap\_progress-bar]:w-full`,
            String.raw`[&_.rhap\_progress-bar]:bg-coremedia-grey-200 [&_.rhap\_progress-bar]:rounded-full`,
        ],
        progressIndicator: [
            String.raw`[&_.rhap\_progress-indicator]:absolute [&_.rhap\_progress-indicator]:top-[-4px] [&_.rhap\_progress-indicator]:z-[3]`,
            String.raw`[&_.rhap\_progress-indicator]:ml-[-7px] [&_.rhap\_progress-indicator]:size-3 [&_.rhap\_progress-indicator]:shadow`,
            String.raw`[&_.rhap\_progress-indicator]:bg-coremedia-blue-500 [&_.rhap\_progress-indicator]:rounded-full`,
        ],
        progressFilled: [
            String.raw`[&_.rhap\_progress-filled]:absolute [&_.rhap\_progress-filled]:z-[2] [&_.rhap\_progress-filled]:h-full`,
            String.raw`[&_.rhap\_progress-filled]:bg-coremedia-blue-500 [&_.rhap\_progress-filled]:rounded-full`,
        ],
        playPauseButton: String.raw`[&_.rhap\_play-pause-button]:mx-1 [&_.rhap\_play-pause-button]:size-6 [&_.rhap\_play-pause-button]:text-2xl`,
        recordName: 'flex-[0_1_57%] text-sm font-semibold',
        icon: [
            'flex size-6 cursor-pointer items-center justify-center rounded-full transition-colors',
            'text-coremedia-blue-500 !text-sm !font-semibold',
            'disabled:text-coremedia-grey-400 disabled:border-coremedia-grey-400 disabled:cursor-default',
        ],
    },

    variants: {
        size: {
            xs: {
                container: 'w-[250px] max-w-[250px]',
                time: String.raw`[&_.rhap\_current-time]:left-[20%] [&_.rhap\_total-time]:right-[5%]`,
                recordName: 'text-xs',
                icon: 'text-xxs mr-0 size-6',
            },

            md: {
                container: 'w-[350px] max-w-[350px]',
                time: String.raw`[&_.rhap\_current-time]:left-[14%] [&_.rhap\_total-time]:right-[4%]`,
            },

            xl: {
                container: 'w-[500px] max-w-[500px]',
                time: String.raw`[&_.rhap\_current-time]:left-[10%] [&_.rhap\_total-time]:right-[3%]`,
            },

            full: {
                container: 'w-full min-w-full max-w-full',
                time: String.raw`[&_.rhap\_current-time]:left-[9%] [&_.rhap\_total-time]:right-[3%]`,
            },
        },

        showDownload: {
            true: {},
        },

        hasBorder: {
            true: {
                icon: 'border-coremedia-blue-500 border border-solid',
            },
        },
    },

    compoundVariants: [
        {
            showDownload: true,
            size: 'xs',
            className: {
                time: totalTimeClasses[0],
            },
        },
        {
            showDownload: true,
            size: 'md',
            className: {
                time: totalTimeClasses[1],
            },
        },
        {
            showDownload: true,
            size: 'xl',
            className: {
                time: totalTimeClasses[2],
            },
        },
        {
            showDownload: true,
            size: 'full',
            className: {
                time: totalTimeClasses[3],
            },
        },
    ],

    defaultVariants: {
        showDownload: false,
        size: 'xs',
        hasBorder: false,
    },
});

type PlayerProps = Exclude<AudioPlayer['props'], 'preload'> & Pick<VariantProps<typeof simpleAudioPlayerSlots>, 'size'>;

function Player({
    showDownloadProgress = false,
    showJumpControls = false,
    size = 'xs',
    className,
    ...rest
}: PlayerProps) {
    const [srcs, setSrcs] = useState<string[]>([]);
    const [labels, setLabels] = useState<string[]>([]);

    const { preload, entries, disabled, handleIsPlaying, handleDownload } = useSimpleAudioPlayer('Player');

    useEffect(() => {
        setSrcs(entries?.map((entry) => (isFunction(entry?.src) ? entry.src() : entry?.src)) ?? ['']);
        setLabels(entries?.map((entry) => entry.label) ?? ['']);
    }, [entries]);

    const {
        icon,
        recordName,
        container,
        main,
        progressSection,
        time,
        volumeButton,
        controlsSection,
        volumeBarArea,
        volumeBar,
        volumeContainer,
        volumeIndicator,
        progressContainer,
        progressBar,
        progressIndicator,
        progressFilled,
        playPauseButton,
    } = simpleAudioPlayerSlots({ size });

    return (
        <div>
            {srcs.map((source, index) => (
                <AudioPlayer
                    key={labels[index]}
                    onPlay={() => handleIsPlaying(true)}
                    onPause={() => handleIsPlaying(false)}
                    onEnded={() => handleIsPlaying(false)}
                    showJumpControls={showJumpControls}
                    showDownloadProgress={showDownloadProgress}
                    preload={preload}
                    className={cn([
                        container(),
                        main(),
                        progressSection(),
                        time({
                            showDownload: isFunction(entries?.[index]?.onDownloadClick),
                        }),
                        volumeButton(),
                        controlsSection(),
                        volumeBarArea(),
                        volumeBar(),
                        volumeContainer(),
                        volumeIndicator(),
                        progressContainer(),
                        progressBar(),
                        progressIndicator(),
                        progressFilled(),
                        playPauseButton(),
                        className,
                    ])}
                    customIcons={{
                        play: (
                            <Icon
                                type="byside"
                                iconName="broadcast-play"
                                data-testid="simpleaudioplayer-controls-play"
                                className={icon({ hasBorder: true, className: 'pl-0.5' })}
                                onClick={disabled ? (e) => e.stopPropagation() : undefined}
                            />
                        ),
                        pause: (
                            <Icon
                                type="byside"
                                iconName="pause-call"
                                data-testid="simpleaudioplayer-controls-pause"
                                className={icon({ hasBorder: true })}
                            />
                        ),
                        volume: (
                            <Icon
                                type="byside"
                                iconName="unmute"
                                data-testid="simpleaudioplayer-controls-volume"
                                className={icon()}
                            />
                        ),
                        volumeMute: (
                            <Icon
                                type="byside"
                                iconName="mute"
                                data-testid="simpleaudioplayer-controls-volume-mute"
                                className={icon()}
                            />
                        ),
                    }}
                    customProgressBarSection={[
                        <div key="record_name" className={recordName()}>
                            {labels[index]}
                        </div>,
                        RHAP_UI.VOLUME,
                    ]}
                    customAdditionalControls={[]}
                    customVolumeControls={[]}
                    {...rest}
                    src={source}
                    customControlsSection={[
                        RHAP_UI.MAIN_CONTROLS,
                        RHAP_UI.CURRENT_TIME,
                        RHAP_UI.PROGRESS_BAR,
                        RHAP_UI.DURATION,
                        (isFunction(entries?.[index]?.onDownloadClick) && (
                            <button type="button" onClick={(e) => handleDownload(e, index)}>
                                <Icon
                                    type="byside"
                                    iconName="download"
                                    label="Download"
                                    data-testid="simpleaudioplayer-controls-download"
                                    className={icon({ hasBorder: true, className: 'text-xs' })}
                                />
                            </button>
                        )) as unknown as RHAP_UI,
                    ]}
                />
            ))}
        </div>
    );
}

Player.displayName = 'SimpleAudioPlayer.Player';

export const SimpleAudioPlayer = {
    Root: CustomRoot,
    PopoverTrigger,
    Button: AudioButton,
    PopoverContent,
    Player,
};
