import { styled } from '@styles';

export const Ol = styled('ol', {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    gap: '$4',
});

export const Li = styled('li', {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    gap: '$4',
    cursor: 'default',

    '&:not(:last-child)::after': {
        content: '',
        boxSizing: 'border-box',
        width: '$2',
        height: '$2',
        display: 'block',
        borderBottom: '2px solid',
        borderRight: '2px solid',
        borderColor: '$red500',
        transform: 'rotate(-45deg)',
    },
});

export const Link = styled('a', {
    all: 'unset',
    position: 'relative',
    fontSize: '$sm',
    fontWeight: '$semibold',
    color: '$black',
    cursor: 'pointer',
    transition: 'color 0.2s ease-in-out',

    '&:hover, &:focus': {
        color: '$red500',
    },
});

export const Button = styled('button', Link, {
    '&:disabled': {
        cursor: 'default',
        color: '$black',
    },
});
