import { DateRange, DayPickerRangeProps } from 'react-day-picker';
import { enUS, pt, es, fr, ja } from 'date-fns/locale';
import { getYear } from 'date-fns';
import { deepClone } from '@sidetalk/helpers';
import { DayType, Locales } from './types';
import { DateFormat } from '.';

export const buildIsDayDisabled = (minDate?: Date, maxDate?: Date) => (day: Date) => {
    if (!day) {
        return false;
    }

    const dayWithHours = new Date(deepClone(day).setHours(12, 0, 0));
    let minDateWithHours;
    let maxDateWithHours;

    if (minDate && maxDate) {
        minDateWithHours = new Date(minDate.setHours(0, 0, 0));
        maxDateWithHours = new Date(maxDate.setHours(23, 59, 59));
        return (
            (minDate && dayWithHours.getTime() < minDateWithHours.getTime()) || // Disable days before minDate
            (maxDate && dayWithHours.getTime() > maxDateWithHours.getTime()) // Disable days after maxDate
        );
    }

    if (minDate) {
        minDateWithHours = new Date(minDate.setHours(0, 0, 0));
        return dayWithHours.getTime() < minDateWithHours.getTime();
    }

    if (maxDate) {
        maxDateWithHours = new Date(maxDate.setHours(23, 59, 59));
        return dayWithHours.getTime() > maxDate.getTime();
    }

    return false;
};

export const onSelectedRangeChangeTime = (
    selectedRange: DayPickerRangeProps['selected'],
    time: Date,
    dayType: DayType,
    hasSeconds: boolean,
) => {
    const newSelectRange = {
        from: selectedRange?.from,
        to: selectedRange?.to,
    };

    newSelectRange[dayType]?.setHours(time.getHours());
    newSelectRange[dayType]?.setMinutes(time.getMinutes());
    if (hasSeconds) {
        newSelectRange[dayType]?.setSeconds(time.getSeconds());
    }

    return newSelectRange;
};

export const sortRangeDates = (selectedRange: DateRange): DayPickerRangeProps['selected'] => {
    if (!selectedRange.from || !selectedRange.to) {
        return selectedRange;
    }

    const { from, to } = selectedRange;
    const date1 = from.getTime();
    const date2 = to.getTime();

    if (date1 < date2 || date1 === date2) {
        return selectedRange;
    }

    return {
        from: to,
        to: from,
    } as DateRange;
};

export const getInitialRange = (): DayPickerRangeProps['selected'] => {
    const from = new Date();
    const to = new Date();
    from.setHours(0, 0, 0);
    to.setHours(23, 59, 59);

    return { from, to } as DateRange;
};

export const preserveDayTime = (currentDate?: Date, timeToPreserve?: Date, type: 'from' | 'to' = 'from') => {
    if (currentDate) {
        const newDate = deepClone(currentDate);

        const defaultHour = type === 'from' ? 0 : 23;
        const defaultMinuteOrSecond = type === 'from' ? 0 : 59;

        newDate.setHours(timeToPreserve?.getHours() ?? defaultHour);
        newDate.setMinutes(timeToPreserve?.getMinutes() ?? defaultMinuteOrSecond);
        newDate.setSeconds(timeToPreserve?.getSeconds() ?? defaultMinuteOrSecond);

        return newDate;
    }

    return currentDate;
};

export const getDateLocale = (locale: Locales = 'en') =>
    ({
        en: enUS,
        pt,
        es,
        fr,
        jp: ja,
        ja,
    })[locale] || enUS;

export const getDatePlaceholder = (customPlaceholder?: string, locale: Locales = 'en') => {
    if (customPlaceholder) {
        return customPlaceholder;
    }

    return (
        {
            en: 'mm/dd/yyyy',
            pt: 'dd/mm/yyyy',
            es: 'dd/mm/yyyy',
            fr: 'dd/mm/yyyy',
            jp: 'dd/mm/yyyy',
            ja: 'dd/mm/yyyy',
        }[locale] || 'mm/dd/yyyy'
    );
};

export const getPlaceholderTitle = (customPlaceholderTitle?: string, locale: Locales = 'en') => {
    if (customPlaceholderTitle) {
        return customPlaceholderTitle;
    }

    return (
        {
            en: 'Date picker',
            pt: 'Selecionador de data',
            es: 'Selector de fechas',
            fr: 'Sélecteur de date',
            jp: '日付ピッカー',
            ja: '日付ピッカー',
        }[locale] || 'Date picker'
    );
};

export const getDateFormatsOnOldStyle = (showLocalizedDate = false) => {
    const formatDate = showLocalizedDate ? DateFormat.longLocalizedDate : DateFormat.oldLongLocalizedDate;
    const formatDateAndTime = showLocalizedDate ? DateFormat.longLocalizedDateAndTime : DateFormat.dateAndTime;
    const formatDateAndTimeWithSeconds = showLocalizedDate
        ? DateFormat.longLocalizedDateAndTimeWithSeconds
        : DateFormat.dateAndTimeWithSeconds;

    return {
        formatDate,
        formatDateAndTime,
        formatDateAndTimeWithSeconds,
    };
};

export const getYearsRangeForDatePicker = () => {
    const YEARS_FROM_CURRENT_YEAR = 100;

    const currentYear = getYear(new Date());
    const minimumYear = currentYear - YEARS_FROM_CURRENT_YEAR;
    const maximumYear = currentYear + YEARS_FROM_CURRENT_YEAR;

    return {
        TO_YEAR: maximumYear,
        FROM_YEAR: minimumYear,
    };
};
