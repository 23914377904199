import { DayPicker } from 'react-day-picker';
import { styled } from '@styles';
import { coremediaColors } from '@sidetalk/tokens';

export const DatePickerStyled = styled(DayPicker, {
    '.rdp-vhidden': {
        position: 'absolute !important',
        top: 0,
        boxSizing: 'border-box',
        width: '1px !important',
        height: '1px !important',
        padding: '0 !important',
        margin: 0,
        overflow: 'hidden !important',
        clip: 'rect(1px, 1px, 1px, 1px) !important',
        background: 'transparent',
        border: '0 !important',
        appearance: 'none',
    },

    '.rdp': {
        display: 'flex',
        width: 'fit-content',
        fontFamily: 'Roboto, "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif',

        '*:focus': {
            outline: 'none',
        },

        '&-months': {
            '.rdp-month': {
                $$calendarWidth: '275px',
                maxWidth: '$$calendarWidth',

                '.rdp-caption': {
                    position: 'relative',
                    padding: '$2',

                    '.rdp-caption_label, h2': {
                        margin: 0,
                        fontSize: '$sm',
                        fontWeight: '$semibold',
                        color: coremediaColors.blue500,
                        textAlign: 'center',
                    },

                    '.rdp-nav': {
                        position: 'absolute',
                        inset: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0 $2',

                        '.rdp-nav_button': {
                            lineHeight: 0,
                            color: coremediaColors.blue500,
                            cursor: 'pointer',
                            backgroundColor: 'transparent',
                            border: 0,

                            svg: {
                                width: 14,
                                height: 14,
                            },

                            '&:focus': {
                                outline: `1px solid ${coremediaColors.blue500}`,
                            },
                        },
                    },
                },

                '.rdp-table': {
                    display: 'table',
                    userSelect: 'none',

                    '.rdp-head': {
                        display: 'table-header-group',
                        marginTop: '$4',

                        '.rdp-head_row': {
                            display: 'table-row',

                            '& > .rdp-head_cell': {
                                display: 'table-cell',
                                width: 30,
                                height: 30,
                                padding: 0,
                                margin: 0,
                                fontSize: '$sm',
                                fontStyle: 'normal',
                                fontWeight: '$semibold',
                                fontStretch: 'normal',
                                lineHeight: '30px',
                                color: coremediaColors.grey700,
                                textAlign: 'center',
                            },
                        },
                    },

                    '.rdp-tbody': {
                        display: 'table-row-group',

                        '.rdp-row': {
                            display: 'table-row',

                            '.rdp-day': {
                                display: 'table-cell',
                                width: 35,
                                padding: 0,
                                margin: 0,
                                fontSize: '$sm',
                                lineHeight: '30px',
                                color: coremediaColors.grey700,
                                textAlign: 'center',
                                verticalAlign: 'middle',
                                cursor: 'pointer',
                                backgroundColor: 'transparent',
                                border: '1px solid transparent',
                                borderRadius: '$xs',

                                '&.rdp-day_today': {
                                    fontWeight: '$semibold',
                                    color: coremediaColors.blue500,
                                },

                                '&.rdp-day_selected': {
                                    color: '$white',
                                    backgroundColor: coremediaColors.blue500,

                                    '&:hover': {
                                        backgroundColor: coremediaColors.blue500,
                                    },
                                },

                                '&.rdp-day_outside': {
                                    color: coremediaColors.grey400,

                                    '&:hover': {
                                        color: coremediaColors.grey700,
                                    },
                                },

                                '&:hover': {
                                    backgroundColor: coremediaColors.grey200,
                                },

                                '&:focus': {
                                    outline: `1px solid ${coremediaColors.blue500}`,
                                },
                            },

                            '.rdp-weeknumber': {
                                fontSize: '$sm',
                                fontWeight: '$semibold',
                            },
                        },
                    },
                },
            },
        },
    },
});
