import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { uid } from '@sidetalk/helpers';

type StyleGuideType = 'coremedia' | 'new';

type ToggleContextProps = {
    randomToggleId: string;
    styleGuide: StyleGuideType;
};

const ToggleContext = createContext<ToggleContextProps | null>(null);

export type ToggleContextProviderProps = {
    children: ReactNode;
    styleGuide?: StyleGuideType;
};

export function ToggleContextProvider({
    styleGuide: initialStyleGuide = 'coremedia',
    children,
}: ToggleContextProviderProps) {
    const [styleGuide, setStyleGuide] = useState<StyleGuideType>(initialStyleGuide);

    const randomToggleId = useMemo(uid, []);

    useEffect(() => {
        setStyleGuide(initialStyleGuide);
    }, [initialStyleGuide]);

    return (
        <ToggleContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                styleGuide,
                randomToggleId,
            }}
        >
            {children}
        </ToggleContext.Provider>
    );
}

export function useToggle(componentName: string) {
    const toggleContext = useContext(ToggleContext);

    if (!toggleContext) {
        throw new Error(`Toggle.${componentName} has to be inside the Toggle.Root component`);
    }

    return toggleContext;
}
