import { ButtonHTMLAttributes, HTMLAttributes, forwardRef } from 'react';
import { tv } from '@lib/tailwind-variants';
import { useStepBar } from './context';

const stepVariants = tv({
    base: [
        'peer/step box-border cursor-default border-none outline-none',
        'flex min-w-[3.75rem] items-center justify-center',
        'text-coremedia-white font-semibold transition-colors duration-200',
        'enabled:hover:z-10 aria-[current=step]:z-20',
        'enabled:aria-[current=step]:cursor-default enabled:data-[enabled=true]:cursor-pointer',
        'enabled:data-[visited=true]:cursor-pointer',
        'disabled:cursor-not-allowed',
        'before:content-none after:content-none',
    ],

    variants: {
        styleGuide: {
            coremedia: [
                'bg-coremedia-turquoise-500 -mx-1.5 px-6 py-1.5 text-[0.6875rem]',
                'enabled:aria-[current=false]:data-[visited=true]:hover:bg-coremedia-turquoise-600',
                'aria-[current=step]:bg-coremedia-turquoise-700 aria-[current=step]:rounded-lg',
                'first:rounded-l-lg last:rounded-r-lg',
                'peer-aria-[current=step]/step:bg-coremedia-grey-300',
                'peer-aria-[current=step]/step:data-[enabled=true]:enabled:hover:bg-coremedia-grey-500',
            ],

            new: [
                'text-gray-850 relative h-full bg-red-600 px-4 text-xs',
                'data-[visited=true]:text-white enabled:data-[visited=true]:hover:brightness-95',
                'aria-[current=step]:rounded-full aria-[current=step]:bg-red-500 aria-[current=step]:text-white',
                'peer-aria-[current=step]/step:bg-gray-50',
                'peer-aria-[current=step]/step:data-[enabled=true]:enabled:hover:bg-gray-100',
                'first:rounded-l-full last:rounded-r-full',
                'after:absolute after:-right-1/2 after:h-full after:w-1/2 after:bg-red-600 after:transition-colors after:duration-200',
                'before:absolute before:-left-1/2 before:h-full before:w-1/2 before:transition-colors before:duration-200',
                'before:bg-gray-50 data-[enabled=true]:enabled:hover:before:bg-gray-100',
                '[&+button]:aria-[current=step]:before:content-[""]',
                '[&:nth-last-child(1_of_[data-visited=true])]:after:content-[""]',
            ],
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

export const Step = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useStepBar('Step');

        return (
            <button type="button" ref={forwardedRef} className={stepVariants({ styleGuide, className })} {...rest} />
        );
    },
);

const stepBarContainerVariants = tv({
    base: 'flex',

    variants: {
        styleGuide: {
            coremedia: '',
            new: 'h-8',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

export const StepBarContainer = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useStepBar('StepBarContainer');

        return <div ref={forwardedRef} className={stepBarContainerVariants({ styleGuide, className })} {...rest} />;
    },
);
