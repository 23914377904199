import { ComponentPropsWithRef, HTMLAttributes, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { Slot } from '@radix-ui/react-slot';

export const headingVariants = tv({
    base: 'font-default m-0',

    variants: {
        styleGuide: {
            new: 'leading-shorter text-gray-700',

            coremedia: 'text-xs font-light',
        },

        type: {
            big: '',
            regular: '',
            medium: '',
            small: '',
            table_header: '',
        },

        size: {
            sm: '',
            md: '',
            lg: '',
            '2xl': '',
            '4xl': '',
            '5xl': '',
        },
    },

    compoundVariants: [
        {
            styleGuide: 'new',
            size: 'sm',
            className: 'text-xl',
        },
        {
            styleGuide: 'new',
            size: 'md',
            className: 'text-2xl',
        },
        {
            styleGuide: 'new',
            size: 'lg',
            className: 'text-3xl',
        },
        {
            styleGuide: 'new',
            size: '2xl',
            className: 'text-5xl',
        },
        {
            styleGuide: 'new',
            size: '4xl',
            className: 'text-6xl',
        },
        {
            styleGuide: 'new',
            size: '5xl',
            className: 'text-7xl',
        },
        {
            styleGuide: 'coremedia',
            type: 'big',
            className: 'text-[2.5rem] leading-[normal]',
        },
        {
            styleGuide: 'coremedia',
            type: 'regular',
            className: 'text-[1.875rem] leading-[normal]',
        },
        {
            styleGuide: 'coremedia',
            type: 'medium',
            className: 'text-xl leading-[normal]',
        },
        {
            styleGuide: 'coremedia',
            type: 'small',
            className: 'text-lg leading-[normal]',
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
        type: 'regular',
        size: 'md',
    },
});

type CustomHeadingProps = HTMLAttributes<HTMLHeadingElement> &
    VariantProps<typeof headingVariants> & {
        asChild?: boolean;
    };

export const Heading = forwardRef<HTMLHeadingElement, CustomHeadingProps>(
    ({ styleGuide, type, size, className, asChild, ...rest }, forwardedRef) => {
        const Comp = asChild ? Slot : 'h2';

        return <Comp ref={forwardedRef} className={headingVariants({ styleGuide, type, size, className })} {...rest} />;
    },
);

Heading.displayName = 'Heading';

export type HeadingProps = ComponentPropsWithRef<typeof Heading>;
