import { useEffect } from 'react';
import { coremediaColors } from '@sidetalk/tokens';
import { Select, SelectOption, SingleValue } from '@components/Select';
import { useQueryBuilderState } from '../../context/QueryBuilder';

type ValueType = '0' | '1' | '';

type BooleanValueSelectorProps = {
    className: string;
    value: ValueType;
    handleOnChange: (value: ValueType) => void;
};

export function BooleanSelector({ className, value, handleOnChange }: BooleanValueSelectorProps) {
    const { state } = useQueryBuilderState();
    const { resources, disabled } = state;

    const options = [
        { value: '0', label: resources?.no ?? 'No' },
        { value: '1', label: resources?.yes ?? 'Yes' },
    ];

    useEffect(() => {
        if (value === '') {
            handleOnChange('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select.Basic
            className={className}
            styles={{
                container: () => ({
                    marginRight: 10,
                    textTransform: 'capitalize',
                    backgroundColor: coremediaColors.white,
                }),
            }}
            styleGuide="coremedia"
            name="boolean"
            options={options}
            menuPortalTarget={document.body}
            defaultValue={options.find((o) => +o.value === +value) ?? options[0]}
            onChange={(e: SingleValue<SelectOption>) => handleOnChange((e?.value ?? '') as ValueType)}
            menuPlacement="auto"
            isDisabled={disabled}
        />
    );
}
