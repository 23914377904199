import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { Popover } from '@components/Popover';
import { FilterOptions } from '../FilterOptions';
import { FilterPropOptions } from '../../types';
import { useFilter } from '../../context';
import {
    PopoverButtonsContainer,
    PopoverCloseButton,
    PopoverCloseButtonContainer,
    PopoverContentButtonsContainer,
    PopoverTriggerButton,
} from '../styles';

type FilterToSaveProps = {
    isApplyDisabled: boolean;
    options: FilterPropOptions;
};

export function FilterToSave({ isApplyDisabled, options }: FilterToSaveProps) {
    const { resources } = options;

    const {
        filterState: { filterSelected, selectedConfig, isPopoverOpen },
        containerRef,
        handleSaveFilterSelected,
        handleRemoveFilterSelected,
        handleSelectedFilterChanges,
        handleOnOpenPopover,
    } = useFilter('FilterToSave');

    const shouldPopoverBeOpen = selectedConfig && selectedConfig.type !== 'empty' ? isPopoverOpen : false;
    const isButtonDisabled = selectedConfig && !selectedConfig.editable;

    return (
        <Popover.Root open={shouldPopoverBeOpen} onOpenChange={handleOnOpenPopover}>
            <PopoverButtonsContainer disabled={isButtonDisabled}>
                <Popover.Trigger asChild>
                    <PopoverTriggerButton disabled={isButtonDisabled}>
                        <Icon type="bytalk" iconName="arrow-down" />
                        <span>{filterSelected?.label}</span>
                    </PopoverTriggerButton>
                </Popover.Trigger>
                <PopoverCloseButtonContainer>
                    <PopoverCloseButton disabled={isButtonDisabled} onClick={handleRemoveFilterSelected}>
                        <Icon type="bytalk" iconName="close-details" label={resources.removeFilter} />
                    </PopoverCloseButton>
                </PopoverCloseButtonContainer>
            </PopoverButtonsContainer>
            <Popover.Content container={containerRef.current}>
                <FilterOptions filterOption={selectedConfig} onChange={handleSelectedFilterChanges} options={options} />
                <PopoverContentButtonsContainer>
                    <Popover.CloseButton asChild>
                        <Button variant="secondary">{resources.cancel}</Button>
                    </Popover.CloseButton>
                    <Button variant="tertiary" onClick={handleSaveFilterSelected} disabled={isApplyDisabled}>
                        {resources.apply}
                    </Button>
                </PopoverContentButtonsContainer>
            </Popover.Content>
        </Popover.Root>
    );
}
