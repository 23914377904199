import { useState, useCallback, ChangeEventHandler } from 'react';
import { cn } from '@helpers/index';
import { useQueryBuilderState } from '../../context/QueryBuilder';

type ValueType = string;

type BetweenValueSelectorProps = {
    className: string;
    value: ValueType;
    handleOnChange: (value: ValueType) => void;
};

export function Between({ className, value, handleOnChange }: BetweenValueSelectorProps) {
    const [composeValue, setComposeValue] = useState(value.split('||').length > 1 ? value : `${value}||${value}`);
    const { state } = useQueryBuilderState();
    const { resources, disabled } = state;

    const handleComposeValue: ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            const { name, value: text } = e.target;
            const [field1, field2] = composeValue.split('||');
            const composedValue = name === 'field1' ? [text, field2].join('||') : [field1, text].join('||');
            setComposeValue(composedValue);
            handleOnChange(composedValue);
        },
        [composeValue, handleOnChange],
    );

    const [field1, field2] = composeValue.split('||');

    return (
        <div className={cn('flex [&_.query-value-editor.query-small]:w-[45px]', className)}>
            <input
                name="field1"
                onChange={handleComposeValue}
                className="query-value-editor query-small"
                value={field1}
                disabled={disabled}
            />
            <div className="mx-2 text-[13px] leading-7">{resources?.and ?? 'and'}</div>
            <input
                name="field2"
                onChange={handleComposeValue}
                className="query-value-editor query-small"
                value={field2}
                disabled={disabled}
            />
        </div>
    );
}
