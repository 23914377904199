import { useCallback, useRef } from 'react';
import { DatePickerVariants, DayType } from '@components/DatePicker/types';
import { TimePicker } from '@components/TimePicker';

type DateTimePickerProps = {
    timeStart: Date | null;
    showTimePicker?: boolean;
    isMultiple?: boolean;
    hasSeconds?: boolean;
    timeEnd?: Date | null;
    onChangeTime: (time: Date, dayType: DayType) => void;
    styleGuide: DatePickerVariants['styleGuide'];
};

const END_OF_DAY = new Date(new Date().setHours(23, 59, 59));

export function DateTimePicker({
    timeStart,
    styleGuide,
    isMultiple = false,
    showTimePicker = false,
    hasSeconds = false,
    timeEnd,
    onChangeTime,
}: DateTimePickerProps) {
    const containerRef = useRef<HTMLDivElement>(null);

    const handleTimePickerChangeFrom = useCallback(
        (time: Date | null) => {
            onChangeTime(time!, 'from');
        },
        [onChangeTime],
    );

    const handleTimePickerChangeTo = useCallback(
        (time: Date | null) => {
            onChangeTime(time!, 'to');
        },
        [onChangeTime],
    );

    if (!showTimePicker) {
        return null;
    }

    return (
        <div ref={containerRef} className="flex justify-around gap-1 p-1">
            <TimePicker.Basic
                isPopoverWithInput
                value={timeStart}
                onChangeTime={handleTimePickerChangeFrom}
                hasSeconds={hasSeconds}
                styleGuide={styleGuide}
                container={containerRef.current}
            />
            {isMultiple && (
                <TimePicker.Basic
                    isPopoverWithInput
                    defaultValue={END_OF_DAY}
                    value={timeEnd}
                    onChangeTime={handleTimePickerChangeTo}
                    hasSeconds={hasSeconds}
                    styleGuide={styleGuide}
                    container={containerRef.current}
                />
            )}
        </div>
    );
}
