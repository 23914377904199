import { ComponentPropsWithRef, ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { Button, Li, Link, Ol } from './styles';
import { BreadcrumbsContextProvider, BreadcrumbsContextProviderProps, useBreadcrumbs } from './context';

type CustomRootProps = ComponentPropsWithoutRef<typeof Ol> & BreadcrumbsContextProviderProps;

const Root = forwardRef<ElementRef<typeof Ol>, CustomRootProps>(({ disabled = false, ...rest }, forwardedRef) => (
    <BreadcrumbsContextProvider disabled={disabled}>
        <nav aria-label="Breadcrumb">
            <Ol ref={forwardedRef} {...rest} />
        </nav>
    </BreadcrumbsContextProvider>
));

Root.displayName = 'Breadcrumbs.Root';

export type BreadcrumbsRootProps = ComponentPropsWithRef<typeof Root>;

type CustomPageLinkProps = ComponentPropsWithoutRef<typeof Link> & {
    isCurrentPage?: boolean;
};

const PageLink = forwardRef<ElementRef<typeof Link>, CustomPageLinkProps>(
    ({ children, 'aria-current': ariaCurrent, isCurrentPage = false, ...rest }, forwardedRef) => {
        const ariaCurrentValue = isCurrentPage ? 'page' : ariaCurrent;

        return (
            <Li>
                <Link ref={forwardedRef} {...rest} aria-current={ariaCurrentValue}>
                    {children}
                </Link>
            </Li>
        );
    },
);

PageLink.displayName = 'Breadcrumbs.PageLink';

type CustomPageButtonProps = ComponentPropsWithoutRef<typeof Button> & {
    isCurrentPage?: boolean;
};

const PageButton = forwardRef<ElementRef<typeof Button>, CustomPageButtonProps>(
    ({ children, 'aria-current': ariaCurrent, isCurrentPage = false, disabled = false, ...rest }, forwardedRef) => {
        const ariaCurrentValue = isCurrentPage ? 'page' : ariaCurrent;

        const { isDisabled } = useBreadcrumbs('PageButton');

        return (
            <Li>
                <Button
                    type="button"
                    ref={forwardedRef}
                    disabled={disabled || isDisabled}
                    {...rest}
                    aria-current={ariaCurrentValue}
                >
                    {children}
                </Button>
            </Li>
        );
    },
);

PageButton.displayName = 'Breadcrumbs.PageButton';

export const Breadcrumbs = {
    Root,
    PageLink,
    PageButton,
};
