import { ButtonHTMLAttributes, HTMLAttributes, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { useBubble } from './context';

const labelVariants = tv({
    base: 'flex max-w-full truncate',

    variants: {
        styleGuide: {
            coremedia: 'px-2 py-1',
            new: 'mx-2',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

export const Label = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useBubble('Label');

        return <span ref={forwardedRef} className={labelVariants({ styleGuide, className })} {...rest} />;
    },
);

Label.displayName = 'Bubble.Label';

const actionButtonVariants = tv({
    base: [
        'border-none bg-transparent outline-0',
        'flex h-full items-center justify-center',
        'enabled:hover:cursor-pointer',
        'disabled:cursor-not-allowed',
    ],

    variants: {
        styleGuide: {
            coremedia: 'enabled:hover:text-coremedia-red-500 disabled:text-coremedia-grey-400 text-[0.7em]',

            new: [
                'w-[1.125rem] rounded-full text-[0.5rem] text-blue-700 [&_i]:size-2',
                'enabled:focus-within:bg-blue-200 enabled:hover:bg-blue-200',
                'enabled:focus-within:outline enabled:focus-within:outline-1 enabled:focus-within:-outline-offset-1 enabled:focus-within:outline-blue-700',
                'disabled:text-gray-250',
            ],
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

export const ActionButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLSpanElement>>(
    ({ className, ...rest }, forwardedRef) => {
        const { styleGuide } = useBubble('ActionButton');

        return (
            <button
                type="button"
                ref={forwardedRef}
                className={actionButtonVariants({ styleGuide, className })}
                {...rest}
            />
        );
    },
);

ActionButton.displayName = 'Bubble.ActionButton';

const rootVariants = tv({
    base: 'font-regular relative box-border inline-flex max-w-full items-baseline justify-center rounded-full',

    variants: {
        styleGuide: {
            coremedia: 'mr-1 last:mr-0',

            new: 'text-xxs h-[1.125rem] bg-blue-100 text-blue-700',
        },

        status: {
            info: '',
            success: '',
            alert: '',
            error: '',
        },

        size: {
            small: '',
            medium: '',
            big: '',
        },
    },

    compoundVariants: [
        {
            styleGuide: 'coremedia',
            status: 'info',
            className: 'bg-coremedia-blue-200',
        },
        {
            styleGuide: 'coremedia',
            status: 'success',
            className: 'bg-coremedia-green-400',
        },
        {
            styleGuide: 'coremedia',
            status: 'alert',
            className: 'bg-coremedia-yellow-500',
        },
        {
            styleGuide: 'coremedia',
            status: 'error',
            className: 'bg-coremedia-red-100',
        },
        {
            styleGuide: 'coremedia',
            size: 'small',
            className: 'text-xxs px-1 py-0.5 leading-[0.625rem]',
        },
        {
            styleGuide: 'coremedia',
            size: 'medium',
            className: 'px-2.5 py-1 text-xs leading-3',
        },
        {
            styleGuide: 'coremedia',
            size: 'big',
            className: 'leadin-[0.875rem] px-4 py-1.5 text-sm',
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
        size: 'medium',
        status: 'info',
    },
});

type RootProps = VariantProps<typeof rootVariants> & HTMLAttributes<HTMLSpanElement>;

export const Root = forwardRef<HTMLSpanElement, RootProps>(({ className, size, status, ...rest }, forwardedRef) => {
    const { styleGuide } = useBubble('Root');

    return <span ref={forwardedRef} className={rootVariants({ styleGuide, size, status, className })} {...rest} />;
});
