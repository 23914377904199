import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { CustomIndicator, CustomRoot } from './styles';

export type ProgressBarRootProps = typeof CustomRoot;

const Basic = forwardRef<ElementRef<typeof CustomRoot>, ComponentPropsWithoutRef<typeof CustomRoot>>(
    ({ styleGuide, value, ...rest }, forwardedRef) => (
        <CustomRoot ref={forwardedRef} value={value} {...rest} styleGuide={styleGuide}>
            <CustomIndicator styleGuide={styleGuide} style={{ transform: `translateX(-${100 - (value ?? 0)}%)` }} />
        </CustomRoot>
    ),
);

Basic.displayName = 'ProgressBar.Basic';

export const ProgressBar = {
    Root: CustomRoot,
    Indicator: CustomIndicator,
    Basic,
};
