import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type BreadcrumbsContextProps = {
    isDisabled: boolean;
    setIsDisabled: (value: boolean) => void;
};

const BreadcrumbsContext = createContext<BreadcrumbsContextProps | null>(null);

export type BreadcrumbsContextProviderProps = {
    children: ReactNode;
    disabled?: boolean;
};

export function BreadcrumbsContextProvider({ disabled = false, children }: BreadcrumbsContextProviderProps) {
    const [isDisabled, setIsDisabled] = useState<boolean>(disabled);

    useEffect(() => {
        setIsDisabled(!!disabled);
    }, [disabled]);

    return (
        <BreadcrumbsContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                isDisabled,
                setIsDisabled,
            }}
        >
            {children}
        </BreadcrumbsContext.Provider>
    );
}

export function useBreadcrumbs(componentName: string) {
    const breadcrumbsContext = useContext(BreadcrumbsContext);

    if (!breadcrumbsContext) {
        throw new Error(`Breadcrumbs.${componentName} has to be inside the Breadcrumbs.Root component`);
    }

    return breadcrumbsContext;
}
