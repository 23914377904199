import { RadioGroup } from '@components/RadioGroup';
import { Text } from '@components/Text';
import {
    FilterOption,
    FilterOptionInput,
    FilterOptionEmptyOrDate,
    FilterOptionRadioOrCheckbox,
    FilterPropOptions,
} from '@components/Filter/types';
import { FilterOptionsProps } from '../..';
import { OptionContainer, RadioContainer } from './styles';

export type RadioOptionsProps = {
    filterOption: FilterOptionRadioOrCheckbox;
    onChange: (filterChanges: FilterOptionRadioOrCheckbox) => void;
    filterOptions: (props: FilterOptionsProps) => JSX.Element | null;
    options: FilterPropOptions;
};

export function RadioOptions({
    filterOption,
    onChange,
    filterOptions: RecursiveFilterOptions,
    options,
}: RadioOptionsProps) {
    const handleRadioChange = (newValue: string) => {
        const valueWithoutId = newValue.replace(filterOption.id, '');

        const newOptions = filterOption.options.map((option) => {
            const isChecked = option.value === valueWithoutId;

            if (option.options !== undefined) {
                return {
                    ...option,
                    checked: isChecked,
                    options: option.options.map((insideOption) => ({ ...insideOption, checked: isChecked })),
                };
            }

            return {
                ...option,
                checked: isChecked,
            };
        });

        const hasOptionChecked = newOptions.some((option) => option.checked);

        const newFilterOption = {
            ...filterOption,
            value: valueWithoutId,
            options: newOptions,
            checked: hasOptionChecked,
        };

        onChange(newFilterOption);
    };

    const handleOptionChange = (newOption: FilterOption, radioIndex: number, optionIndex: number) => {
        const newFilterOption = {
            ...filterOption,
            options: filterOption.options.map((radioOption, filterRadioIndex) => {
                if (filterRadioIndex === radioIndex) {
                    const newRadioOption = {
                        ...radioOption,
                        options: radioOption.options?.map((option, index) => {
                            if (optionIndex === index) {
                                return {
                                    ...newOption,
                                    checked: true,
                                } as FilterOptionInput | FilterOptionEmptyOrDate;
                            }

                            return {
                                ...option,
                                checked: option.value !== '',
                            };
                        }),
                    };

                    return newRadioOption;
                }

                return radioOption;
            }),
        };

        onChange(newFilterOption);
    };

    const radioOptions = filterOption.options.map((option) => ({
        id: option.id,
        label: option.label || '',
        value: `${filterOption.id}${option.value}` || '',
        disabled: !option.editable || false,
        options: option.options?.map((optionsOption) => ({ ...optionsOption, value: optionsOption.value ?? '' })),
    }));

    const radioValue = `${filterOption.id}${filterOption.value}`;

    return (
        <RadioGroup.Root value={radioValue} onValueChange={handleRadioChange} disabled={!filterOption.editable}>
            {radioOptions.map((radio, radioIndex) => (
                <RadioContainer key={radio.id}>
                    <RadioGroup.Radio id={radio.id ?? radio.value} value={radio.value} disabled={radio.disabled} />
                    <Text asChild>
                        <label htmlFor={radio.id ?? radio.value} className="leading-tall">
                            {radio.label}
                        </label>
                    </Text>
                    {filterOption.checked && radio.value === radioValue && !!radio.options?.length && (
                        <OptionContainer>
                            {radio.options.map((option, optionIndex) => (
                                <RecursiveFilterOptions
                                    key={option.id}
                                    filterOption={option as FilterOptionInput | FilterOptionEmptyOrDate}
                                    onChange={(filterChanges) =>
                                        handleOptionChange(filterChanges, radioIndex, optionIndex)
                                    }
                                    options={options}
                                />
                            ))}
                        </OptionContainer>
                    )}
                </RadioContainer>
            ))}
        </RadioGroup.Root>
    );
}
