import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type StyleGuideType = 'coremedia' | 'new';

type DroplistContextProps = {
    styleGuide: StyleGuideType;
    setStyleGuide: (value: StyleGuideType) => void;
};

const DroplistContext = createContext<DroplistContextProps | null>(null);

export type DroplistContextProviderProps = {
    children: ReactNode;
    styleGuide?: StyleGuideType;
};

export function DroplistContextProvider({
    styleGuide: initialStyleGuide = 'coremedia',
    children,
}: DroplistContextProviderProps) {
    const [styleGuide, setStyleGuide] = useState<StyleGuideType>(initialStyleGuide);

    useEffect(() => {
        setStyleGuide(initialStyleGuide);
    }, [initialStyleGuide]);

    return (
        <DroplistContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                styleGuide,
                setStyleGuide,
            }}
        >
            {children}
        </DroplistContext.Provider>
    );
}

export function useDroplist(componentName: string) {
    const droplistContext = useContext(DroplistContext);

    if (!droplistContext) {
        throw new Error(`Droplist.${componentName} has to be inside the Droplist.Root component`);
    }

    return droplistContext;
}
