import { type Config } from 'dompurify';
import { AdditionalTags } from './types';

const additionalTagsMap = {
    iframe: {
        addTags: ['iframe'],
        addAttr: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
    },
};

const getAdditionalTagsConfig = (additionalTags?: AdditionalTags) => {
    const hasTag = !!additionalTags?.includes('iframe');

    if (!hasTag) {
        return {};
    }

    return {
        ADD_TAGS: additionalTagsMap.iframe.addTags,
        ADD_ATTR: additionalTagsMap.iframe.addAttr,
    } as Config;
};

export const sanitizeJoditEditorContent = (newValue: string) =>
    newValue.replace(/(<iframe.*?>)(.*)(<\/iframe>)/g, '$1$3');

export const getFullSanitizeConfig = (sanitizeConfig?: Config, additionalTags?: string[]) => ({
    ...sanitizeConfig,
    ...getAdditionalTagsConfig(additionalTags),
});
