import { useMemo } from 'react';
import type { FieldSelectorProps, FullField } from 'react-querybuilder';
import { coremediaColors } from '@sidetalk/tokens';
import { Select } from '@components/Select';
import { useQueryBuilderState } from '../../context/QueryBuilder';

const customSelectStyles = {
    container: () => ({
        backgroundColor: coremediaColors.white,
        minWidth: '140px',
    }),
    menu: () => ({
        width: 'auto',
        minWidth: '100%',
    }),
    option: () => ({
        padding: '4px 14px',
        minWidth: '250px',
    }),
};

export function FieldSelector({ options, value, handleOnChange }: FieldSelectorProps) {
    const { state } = useQueryBuilderState();
    const { resources, disabled } = state;

    const transformFields = (optionsFields: FullField[]) =>
        (optionsFields || []).reduce<FullField[]>((groups, option) => {
            const field = option;
            const group = (groups || []).find((g) => g.name === field.group);
            if (group) {
                return (groups || []).map((g) => {
                    if (g.label === group.label) {
                        return {
                            label: g.label,
                            name: g.name,
                            value: g.name,
                            options: [
                                ...(g?.options as never[]),
                                {
                                    value: option.value,
                                    label: option.fieldResource || option.label,
                                },
                            ],
                        };
                    }

                    return g;
                });
            }

            groups.push({
                label: (option.groupResource || option.group) as string,
                name: option.group as string,
                value: option.group as string,
                options: [
                    {
                        value: option.value,
                        label: option.fieldResource || option.label,
                    },
                ],
            });

            return groups;
        }, []);

    const fields = useMemo(() => transformFields(options as FullField[]), [options]);

    const chosenGroup =
        (fields || []).find(
            (group: FullField) =>
                !!((group?.options as { value: string | undefined }[]) || []).find(
                    (option: { value: string | undefined }) => option.value === value,
                ),
        ) ?? options[0];

    return (
        <Select.Basic
            styleGuide="coremedia"
            className="field-selector"
            styles={customSelectStyles}
            name="field"
            options={fields}
            menuPortalTarget={document.body}
            value={((chosenGroup?.options as FullField[]) || []).find(
                (option: { value: string | undefined }) => option.value === value,
            )}
            onChange={(chosen) => handleOnChange(chosen?.value)}
            menuPlacement="auto"
            noOptionsMessage={() => resources?.no_options ?? 'No options'}
            isDisabled={disabled}
        />
    );
}
