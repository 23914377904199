import { createContext, useState, useContext, ReactNode, useMemo, Dispatch, SetStateAction } from 'react';
import type { DatePickerRanges, Locales } from '@components/DatePicker';
import type { SelectOption } from '@components/Select';
import type { CustomFieldOptions } from '..';

const defaultInitialState = {
    maxDepthLevel: 2,
    resources: {},
    disabled: false,
    customFieldOptions: undefined,
    getCustomFieldOptions: undefined,
};

type DatePickerFormat = 'YYYY/MM/DD' | 'YYYY/DD/MM' | 'DD/MM/YYYY' | 'MM/DD/YYYY';

export type LocaleResources = {
    format: DatePickerFormat;
    separator: string;
    customRangeLabel: string;
    daysOfWeek: string[];
    monthNames: string[];
    firstDay: number;
    applyLabel: string;
    cancelLabel: string;
};

export type RangesKeys =
    | 'Last hour'
    | 'Last 24 hours'
    | 'Today'
    | 'Yesterday'
    | 'Current week'
    | 'Last week'
    | 'Last 7 days'
    | 'Last 30 days'
    | 'Current month'
    | 'Last month'
    | 'Last 3 months';

export type ResourcesType = {
    AddGroup?: {
        label?: string;
    };
    AddRule?: {
        label?: string;
    };
    DatePicker?: {
        locale?: LocaleResources;
        label: {
            lasthour: string;
            last24hours: string;
            today: string;
            yesterday: string;
            currentweek: string;
            lastweek: string;
            last7days: string;
            last30days: string;
            currentmonth: string;
            lastmonth: string;
            lastthreemonth: string;
            custom: string;
        };
        ranges: DatePickerRanges;
        rangeMapper: Record<string, string | null>;
        minDate: string | number | Date;
    };
    no?: string;
    yes?: string;
    no_options?: string;
    select_option?: string;
    fill_out?: string;
    type_field?: string;
    create?: string;
    and?: string;
};

export type QueryBuilderState = {
    maxDepthLevel?: number;
    resources?: ResourcesType;
    disabled?: boolean;
    locale?: Locales;
    localeResources?: LocaleResources;
    customFieldOptions?: CustomFieldOptions;
    getCustomFieldOptions?: (value: unknown, options?: CustomFieldOptions) => Promise<SelectOption[]>;
};

export type QueryBuilderContextProps = {
    state: QueryBuilderState;
    setState: Dispatch<SetStateAction<QueryBuilderState>>;
};

const QueryBuilderContext = createContext<QueryBuilderContextProps | null>(null);

export type QueryBuilderContextProviderProps = {
    children: ReactNode;
    initialState?: QueryBuilderState;
};

export function QueryBuilderContextProvider({ children, initialState }: QueryBuilderContextProviderProps) {
    const [state, setState] = useState<QueryBuilderState>(initialState ?? defaultInitialState);

    const providerValue = useMemo<QueryBuilderContextProps>(() => ({ state, setState }), [state, setState]);

    return <QueryBuilderContext.Provider value={providerValue}>{children}</QueryBuilderContext.Provider>;
}

QueryBuilderContextProvider.displayName = 'QueryBuilderContextProvider';

export const useQueryBuilderState = () => {
    const state = useContext(QueryBuilderContext);
    if (typeof state === 'undefined') {
        throw new Error('useQueryBuilderState must be used within a QueryBuilderContextProvider');
    }

    if (state === null) {
        throw new Error('useQueryBuilderState should not be null');
    }

    return state;
};
