import { ChangeEvent, ComponentPropsWithoutRef, ElementRef, forwardRef, useEffect, useState } from 'react';
import { useDebounceFunction } from '@sidetalk/hooks';
import { Container, Input, SearchIcon } from './styles';
import { SearchContextProvider, SearchContextProviderProps } from './context';

type RootProps = SearchContextProviderProps & ComponentPropsWithoutRef<typeof Container>;

const Root = forwardRef<ElementRef<typeof Container>, RootProps>(
    ({ styleGuide, expandable, ...rest }, forwardedRef) => (
        <SearchContextProvider styleGuide={styleGuide} expandable={expandable}>
            <Container ref={forwardedRef} {...rest} />
        </SearchContextProvider>
    ),
);

Root.displayName = 'Search.Root';

export type SearchRootProps = typeof Root;

type InputDelayedProps = ComponentPropsWithoutRef<typeof Input> & {
    delay?: number;
};

const InputDelayed = forwardRef<ElementRef<typeof Input>, InputDelayedProps>(
    ({ onChange, value, delay = 500, ...rest }, forwardedRef) => {
        const [searchText, setSearchText] = useState<typeof value>(value ?? '');

        const onChangeDebounced = useDebounceFunction(onChange, delay);

        const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value);
            onChangeDebounced(e);
        };

        useEffect(() => {
            setSearchText(value);
        }, [value]);

        return <Input ref={forwardedRef} onChange={handleSearchInput} value={searchText} {...rest} />;
    },
);

InputDelayed.displayName = 'Search.Input';

const Basic = forwardRef<ElementRef<typeof InputDelayed>, ComponentPropsWithoutRef<typeof InputDelayed>>(
    ({ styleGuide, expandable, ...rest }, forwardedRef) => (
        <Root styleGuide={styleGuide} expandable={expandable}>
            <InputDelayed ref={forwardedRef} {...rest} />
            <SearchIcon />
        </Root>
    ),
);

Basic.displayName = 'Search.Basic';

export const Search = {
    Root,
    Input: InputDelayed,
    SearchIcon,
    Basic,
};
