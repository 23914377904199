import { CustomBubble, Title, Root, StatusCircle } from './styles';

export type LineRootProps = typeof Root;

export const Line = {
    Root,
    StatusCircle,
    Title,
    Bubble: CustomBubble,
};
