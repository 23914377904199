import { MouseEventHandler, useState, useCallback } from 'react';
import ReactSelect, {
    components as ReactSelectComponents,
    type ClearIndicatorProps,
    type DropdownIndicatorProps,
    type GroupBase,
    type GroupProps,
    type MenuListProps,
    type OptionProps,
    type Options,
    type Props,
    type GroupHeadingProps,
    type StylesConfig,
    type MultiValueProps,
    type MultiValue,
    type MultiValueRemoveProps,
    type OnChangeValue,
    type ActionMeta,
} from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { horizontalListSortingStrategy, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { isUndefined, preventEvent } from '@sidetalk/helpers';
import { cn } from '@helpers/index';
import { Icon } from '../Icon';
import { getInnerGroupedOptions } from './helpers/innerGroupedOptions';
import { OPTION_NOT_GROUPED } from './constants';
import { OptionWithInnerGroup } from './types';
import { DefaultOldStyles, MultiOldStyles, DefaultNewStyles, MultiNewStyles } from './styles';

export type { OptionWithInnerGroup } from './types';

export type {
    ActionMeta,
    SingleValue,
    GroupBase,
    ClearIndicatorProps,
    ControlProps,
    DropdownIndicatorProps,
    GroupProps,
    GroupHeadingProps,
    IndicatorsContainerProps,
    IndicatorSeparatorProps,
    InputProps,
    LoadingIndicatorProps,
    MenuProps,
    MenuListProps,
    MultiValueProps,
    MultiValueRemoveProps,
    OptionProps,
    Options,
    PlaceholderProps,
    ContainerProps,
    SingleValueProps,
    ValueContainerProps,
    InputActionMeta,
} from 'react-select';

type ReactSelectProps = ReactSelect & {
    styleGuide?: 'coremedia' | 'new';
    isCreatable?: false;
};

type CreatableSelectProps = ReactSelect & {
    styleGuide?: 'coremedia' | 'new';
    isCreatable: true;
};

type IOption = {
    value: string | number;
    label?: string;
    [x: string]: unknown;
};

export type SelectProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>> = Props<
    Option,
    IsMulti,
    Group
> &
    CreatableProps<Option, IsMulti, Group> &
    Omit<ReactSelectProps | CreatableSelectProps, 'multiStyle'>;

export type SortableSelectProps<Option, IsMulti extends boolean, Group extends GroupBase<Option>> = Props<
    Option,
    IsMulti,
    Group
> &
    Omit<ReactSelectProps, 'multiStyle'>;

function DropdownIndicator<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    props: DropdownIndicatorProps<Option, IsMulti, Group>,
) {
    return (
        <ReactSelectComponents.DropdownIndicator {...props}>
            <Icon type="byside" iconName="dropdown" className="text-xxs" />
        </ReactSelectComponents.DropdownIndicator>
    );
}

function DropdownIndicatorNewStyleGuide<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    props: DropdownIndicatorProps<Option, IsMulti, Group>,
) {
    return (
        <ReactSelectComponents.DropdownIndicator {...props}>
            <Icon type="bytalk" iconName="arrow-down" className="text-xxs" />
        </ReactSelectComponents.DropdownIndicator>
    );
}

function ClearIndicator<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    props: ClearIndicatorProps<Option, IsMulti, Group>,
) {
    return (
        <ReactSelectComponents.ClearIndicator {...props}>
            <Icon type="byside" iconName="close" className="text-xxs" />
        </ReactSelectComponents.ClearIndicator>
    );
}

function ClearIndicatorNewStyleGuide<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    props: ClearIndicatorProps<Option, IsMulti, Group>,
) {
    return (
        <ReactSelectComponents.ClearIndicator {...props}>
            <Icon type="byside" iconName="close" className="text-xxs" />
        </ReactSelectComponents.ClearIndicator>
    );
}

function Group<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
    options,
    children,
    ...rest
}: GroupProps<Option, IsMulti, Group>) {
    const hasInnerGroup = (options as Options<OptionWithInnerGroup>).some(
        (option: OptionWithInnerGroup) => !isUndefined(option.data.innerGroup),
    );

    if (!hasInnerGroup) {
        return (
            <ReactSelectComponents.Group {...rest} options={options}>
                {children}
            </ReactSelectComponents.Group>
        );
    }

    const groupedOptions = getInnerGroupedOptions(options as Options<OptionWithInnerGroup>);

    const { options: notGroupedOptions } = groupedOptions.get(OPTION_NOT_GROUPED)!;

    groupedOptions.delete(OPTION_NOT_GROUPED);

    const mappableGroupedOptions = Array.from(groupedOptions);

    return (
        <ReactSelectComponents.Group {...rest} options={options}>
            {mappableGroupedOptions.map(([innerGroupLabel, innerGroupOptions]) =>
                !isUndefined(innerGroupOptions?.header?.index) ? (
                    <div className="flex flex-col [&>[role=option]]:pl-6" key={innerGroupLabel}>
                        <div>{children?.[innerGroupOptions?.header?.index as keyof typeof children]}</div>
                        {innerGroupOptions.options.map(
                            ({ index }) => children?.[index as keyof typeof children] ?? null,
                        )}
                    </div>
                ) : (
                    innerGroupOptions.options.map(({ index }) => children?.[index as keyof typeof children] ?? null)
                ),
            )}
            {notGroupedOptions.map(({ index }) => children?.[index as keyof typeof children] ?? null)}
        </ReactSelectComponents.Group>
    );
}

function MenuList<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
    innerProps,
    ...rest
}: MenuListProps<Option, IsMulti, Group>) {
    const newProps = { ...innerProps, role: 'listbox', 'aria-expanded': true };

    return <ReactSelectComponents.MenuList {...rest} innerProps={newProps} />;
}

function Option<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
    innerProps,
    isDisabled,
    ...rest
}: OptionProps<Option, IsMulti, Group>) {
    const newProps = { ...innerProps, role: 'option', 'aria-disabled': isDisabled };

    return <ReactSelectComponents.Option {...rest} isDisabled={isDisabled} innerProps={newProps} />;
}

function GroupHeading<Option, IsMulti extends boolean, Group extends GroupBase<Option>>({
    children,
    ...rest
}: GroupHeadingProps<Option, IsMulti, Group>) {
    const handleHeaderClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        preventEvent(e);
        const node = (e.target as HTMLDivElement).closest('.group-heading-wrapper');

        if (node) {
            const listNode = node.nextElementSibling;
            const iconNode = node.querySelector('i[data-collapsed]');

            if (listNode) {
                listNode.classList.toggle('collapsed');
                iconNode?.setAttribute('data-collapsed', String(iconNode?.getAttribute('data-collapsed') === 'false'));
            }
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div className="group-heading-wrapper" onClick={handleHeaderClick}>
            <ReactSelectComponents.GroupHeading {...rest} className="hover:bg-coremedia-grey-200">
                <div className="flex items-end">
                    <div className="flex-[1_0_80%] truncate">{children}</div>
                    <Icon
                        type="byside"
                        iconName="arrow-small"
                        data-collapsed="false"
                        className={cn([
                            'text-coremedia-grey-700 mr-2 self-center text-[0.5rem] !font-semibold transition-colors',
                            'data-[collapsed=true]:-rotate-90',
                        ])}
                    />
                </div>
            </ReactSelectComponents.GroupHeading>
        </div>
    );
}

function extendStyles<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    baseStyles: StylesConfig<Option, IsMulti, Group>,
    styles?: StylesConfig<Option, IsMulti, Group>,
) {
    const extendedStyles: StylesConfig<Option, IsMulti, Group> = { ...baseStyles };
    if (styles) {
        Object.keys(styles).forEach((key) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            extendedStyles[key] = (provided, state) => ({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                ...(baseStyles?.[key]?.(provided, state) || {}),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                ...(styles?.[key]?.(provided, state) || {}),
            });
        });
    }
    return extendedStyles;
}

function SelectBasic<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({
    isCreatable = false,
    styleGuide = 'coremedia',
    components,
    isMulti,
    isSearchable,
    styles,
    className,
    ...props
}: SelectProps<Option, IsMulti, Group>) {
    const isOldStyle = styleGuide === 'coremedia';

    let baseStyles = (isOldStyle ? DefaultOldStyles : DefaultNewStyles) as unknown as StylesConfig<
        Option,
        IsMulti,
        Group
    >;
    if (isMulti) {
        baseStyles = (isOldStyle ? MultiOldStyles : MultiNewStyles) as unknown as StylesConfig<Option, IsMulti, Group>;
    }

    const componentsAccordinglyWithStyle = isOldStyle
        ? {
              GroupHeading,
              DropdownIndicator,
              ClearIndicator,
              MenuList,
              Option,
              ...components,
          }
        : {
              DropdownIndicator: DropdownIndicatorNewStyleGuide,
              ClearIndicator: ClearIndicatorNewStyleGuide,
              MenuList,
              Group,
              Option,
              ...components,
          };

    if (isCreatable) {
        return (
            <CreatableSelect
                {...props}
                className={cn('sidetalk-select__container', className)}
                classNamePrefix="sidetalk-select"
                components={componentsAccordinglyWithStyle}
                isMulti={isMulti}
                unstyled={!isOldStyle}
                styles={extendStyles<Option, IsMulti, Group>(baseStyles, styles)}
            />
        );
    }

    return (
        <ReactSelect
            {...props}
            className={cn('sidetalk-select__container', className)}
            classNamePrefix="sidetalk-select"
            components={componentsAccordinglyWithStyle}
            isSearchable={isSearchable}
            isMulti={isMulti}
            unstyled={!isOldStyle}
            styles={extendStyles<Option, IsMulti, Group>(baseStyles, styles)}
        />
    );
}

function MultiValueComponent<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    props: MultiValueProps<Option, IsMulti, Group>,
) {
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const { innerProps: propsInnerProps, data } = props;
    const innerProps = { ...propsInnerProps, onMouseDown };
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: (data as IOption)?.value });

    const style = { transition, transform: CSS.Transform.toString(transform) };

    return (
        <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
            <ReactSelectComponents.MultiValue {...props} innerProps={innerProps} />
        </div>
    );
}

function MultiValueRemove<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
    props: MultiValueRemoveProps<Option, IsMulti, Group>,
) {
    const { innerProps: propsInnerProps } = props;

    return (
        <ReactSelectComponents.MultiValueRemove
            {...props}
            innerProps={{
                ...propsInnerProps,
                onPointerDown: (e) => e.stopPropagation(),
            }}
        />
    );
}

function arrayMove<T>(array: MultiValue<T>, from: number, to: number) {
    const slicedArray = array.slice();

    slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);

    return slicedArray;
}

function SelectMultiSortable<Option, IsMulti extends boolean, Group extends GroupBase<Option> = GroupBase<Option>>({
    components,
    onChange,
    styleGuide = 'coremedia',
    styles,
    value = [],
    ...rest
}: SortableSelectProps<Option, IsMulti, Group>) {
    const isOldStyle = styleGuide === 'coremedia';
    const baseStyles = (isOldStyle ? MultiOldStyles : MultiNewStyles) as unknown as StylesConfig<
        Option,
        IsMulti,
        Group
    >;

    const componentsAccordinglyWithStyle = isOldStyle
        ? {
              GroupHeading,
              DropdownIndicator,
              ClearIndicator,
              MenuList,
              Option,
              MultiValue: MultiValueComponent,
              MultiValueRemove,
              ...components,
          }
        : {
              DropdownIndicator: DropdownIndicatorNewStyleGuide,
              ClearIndicator: ClearIndicatorNewStyleGuide,
              MenuList,
              Group,
              Option,
              MultiValue: MultiValueComponent,
              MultiValueRemove,
              ...components,
          };

    const [selected, setSelected] = useState(value as MultiValue<Option>);

    const onDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (!active || !over) {
                return;
            }

            setSelected((items) => {
                const oldIndex = items.findIndex((item) => (item as IOption).value === active.id);
                const newIndex = items?.findIndex?.((item) => (item as IOption).value === over.id);
                const sortedItems = arrayMove<Option>(items, oldIndex, newIndex);
                onChange?.(sortedItems as OnChangeValue<Option, IsMulti>, {
                    action: 'select-option',
                    option: undefined,
                    name: rest.name,
                });

                return sortedItems;
            });
        },
        [setSelected, onChange, rest.name],
    );

    const onChangeValue = (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>) => {
        setSelected(newValue as MultiValue<Option>);
        onChange?.(newValue, actionMeta);
    };

    return (
        <DndContext modifiers={[restrictToParentElement]} onDragEnd={onDragEnd} collisionDetection={closestCenter}>
            <SortableContext items={selected.map((o) => (o as IOption).value)} strategy={horizontalListSortingStrategy}>
                <ReactSelect
                    {...rest}
                    className="sidetalk-select__container"
                    classNamePrefix="sidetalk-select"
                    components={componentsAccordinglyWithStyle}
                    onChange={onChangeValue}
                    // @ts-expect-error conflict between prop name and IsMulti type (not resolvable)
                    isMulti
                    unstyled={!isOldStyle}
                    value={selected}
                    styles={extendStyles<Option, IsMulti, Group>(baseStyles, styles)}
                    closeMenuOnSelect={false}
                />
            </SortableContext>
        </DndContext>
    );
}

export type SelectOption = {
    id?: string;
    label: string;
    value: string;
};

// This type is necessary for `pnpm` to work since isn't able to infer the types
type Select = {
    Basic: typeof SelectBasic;
    MultiSortable: typeof SelectMultiSortable;
    ReactSelectComponents: typeof ReactSelectComponents;
};

export const Select: Select = {
    Basic: SelectBasic,
    MultiSortable: SelectMultiSortable,
    ReactSelectComponents,
};
