import { ComponentPropsWithoutRef, ElementRef, InputHTMLAttributes, LabelHTMLAttributes, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { Icon } from '@components/Icon';
import { useSearch } from './context';

const searchSlots = tv({
    slots: {
        containerSlot: 'relative',

        inputSlot: [
            'peer/search-input h-8 text-xs outline-none transition-colors',
            '[&::-webkit-search-cancel-button]:hidden [&::-webkit-search-decoration]:hidden [&::-webkit-search-results-button]:hidden [&::-webkit-search-results-decoration]:hidden',
            '[&::-ms-clear]:hidden [&::-ms-reveal]:hidden',
        ],

        iconSlot: ['flex h-full cursor-pointer items-center transition-colors'],
    },

    variants: {
        styleGuide: {
            coremedia: {
                containerSlot: 'text-coremedia-grey-400',

                inputSlot: [
                    'w-40 py-px pl-2 pr-8',
                    'text-coremedia-grey-700 font-medium leading-[30px] tracking-[0.5px]',
                    'placeholder:text-coremedia-grey-400',
                    'border-coremedia-grey-100 rounded border border-solid',
                    'enabled:focus:border-coremedia-blue-500',
                    'enabled:hover:border-coremedia-blue-500',
                ],

                iconSlot: [
                    'pointer-events-none absolute inset-y-0 right-0 px-2 text-xs',
                    'peer-hover/search-input:peer-enabled/search-input:text-coremedia-blue-500',
                    'peer-focus-within/search-input:peer-enabled/search-input:text-coremedia-blue-500',
                ],
            },

            new: {
                containerSlot: ['group/search-container inline-block h-8 max-w-[200px] font-semibold transition-all'],

                inputSlot: [
                    'transition-all',
                    'border-none',
                    'placeholder:text-gray-250',
                    'rounded-full border-2 border-solid',
                    'disabled:text-gray-250 disabled:cursor-not-allowed',
                ],

                iconSlot: [
                    'absolute inset-y-0 left-0 px-2 transition-all',
                    'peer-disabled/search-input:text-gray-250 peer-disabled/search-input:cursor-not-allowed',
                    'peer-[&:not([value=""])]/search-input:pointer-events-none',
                ],
            },
        },

        expandable: {
            true: {
                containerSlot: '',

                inputSlot: '',

                iconSlot: '',
            },

            false: {
                containerSlot: '',

                inputSlot: '',

                iconSlot: '',
            },
        },
    },

    compoundVariants: [
        {
            expandable: true,
            styleGuide: 'new',
            className: {
                containerSlot: 'min-w-8',

                inputSlot: [
                    'w-0',
                    'border-transparent',
                    '[&:not([value=""])]:w-[200px] [&:not([value=""])]:pl-4 [&:not([value=""])]:pr-8',
                    '[&:not([value=""])]:border-gray-100 [&:not([value=""])]:enabled:hover:border-red-500',
                    'group-focus-within/search-container:w-[200px] group-focus-within/search-container:pl-4 group-focus-within/search-container:pr-8',
                    'group-focus-within/search-container:border-red-500',
                ],

                iconSlot: [
                    'ml-auto',
                    'group-hover/search-container:text-red-500',
                    'group-focus-within/search-container:text-red-500',
                    'group-focus-within/search-container:pointer-events-none group-focus-within/search-container:left-auto group-focus-within/search-container:right-0',
                    'peer-[&:not([value=""])]/search-input:left-auto peer-[&:not([value=""])]/search-input:right-0',
                ],
            },
        },
        {
            expandable: false,
            styleGuide: 'new',
            className: {
                containerSlot: 'w-[200px]',

                inputSlot: [
                    'w-full pl-4 pr-8',
                    'border-gray-100',
                    'group-hover/search-container:enabled:border-red-500',
                    'group-focus-within/search-container:enabled:border-red-500',
                ],

                iconSlot: [
                    'pointer-events-none left-auto right-0',
                    'group-hover/search-container:text-red-500',
                    'group-focus-within/search-container:text-red-500',
                ],
            },
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

const { containerSlot, inputSlot, iconSlot } = searchSlots();

type ContainerProps = LabelHTMLAttributes<HTMLLabelElement> & VariantProps<typeof searchSlots>;

export const Container = forwardRef<HTMLLabelElement, ContainerProps>(({ className, ...rest }, forwardedRef) => {
    const { randomSearchId, styleGuide, expandable } = useSearch('Container');

    return (
        <label
            ref={forwardedRef}
            htmlFor={randomSearchId}
            className={containerSlot({ styleGuide, expandable, className })}
            {...rest}
        />
    );
});

type InputProps = InputHTMLAttributes<HTMLInputElement> & VariantProps<typeof searchSlots>;

export const Input = forwardRef<HTMLInputElement, InputProps>(({ className, ...rest }, forwardedRef) => {
    const { randomSearchId, styleGuide, expandable } = useSearch('Input');

    return (
        <input
            ref={forwardedRef}
            id={randomSearchId}
            type="search"
            className={inputSlot({ styleGuide, expandable, className })}
            {...rest}
        />
    );
});

Input.displayName = 'Search.Input';

type IconProps = Omit<ComponentPropsWithoutRef<typeof Icon>, 'type' | 'iconName'> & VariantProps<typeof searchSlots>;

export const SearchIcon = forwardRef<ElementRef<typeof Icon>, IconProps>(({ className, ...rest }, forwardedRef) => {
    const { styleGuide, expandable } = useSearch('Input');

    return (
        <Icon
            ref={forwardedRef}
            type="byside"
            iconName="search"
            className={iconSlot({ styleGuide, expandable, className })}
            {...rest}
        />
    );
});

SearchIcon.displayName = 'Search.SearchIcon';
