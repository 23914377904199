import { ButtonHTMLAttributes, HTMLAttributes, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { cn } from '@helpers/index';

export const CustomRoot = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ className, ...rest }, forwardedRef) => (
        <div ref={forwardedRef} className={cn('relative inline-flex', className)} {...rest} />
    ),
);

CustomRoot.displayName = 'Badge.Root';

const visualVariants = tv({
    base: [
        'absolute right-0 top-0',
        'bg-coremedia-turquoise-500 h-4 rounded-md border-0 px-[5px] py-0',
        'text-xxs text-center font-normal leading-4 text-white',
    ],

    variants: {
        blink: {
            true: 'animate-blinkEffect',

            false: 'animate-fadeIn',
        },
    },

    defaultVariants: {
        blink: false,
    },
});

type CustomVisualProps = HTMLAttributes<HTMLSpanElement> & VariantProps<typeof visualVariants>;

export const CustomVisual = forwardRef<HTMLSpanElement, CustomVisualProps>(
    ({ blink, className, ...rest }, forwardedRef) => (
        <span
            ref={forwardedRef}
            role="status"
            aria-live="polite"
            className={visualVariants({ blink, className })}
            {...rest}
        />
    ),
);

type CustomButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & VariantProps<typeof visualVariants>;

export const CustomButton = forwardRef<HTMLButtonElement, CustomButtonProps>(
    ({ blink, className, ...rest }, forwardedRef) => (
        <button
            ref={forwardedRef}
            type="button"
            aria-live="polite"
            className={cn(visualVariants({ blink, className }), 'cursor-pointer')}
            {...rest}
        />
    ),
);
