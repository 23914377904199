import { ElementRef, HTMLAttributes, LabelHTMLAttributes, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { Root, CheckboxProps } from '@radix-ui/react-checkbox';
import { ToggleContextProvider, ToggleContextProviderProps, useToggle } from './context';

const rootVariants = tv({
    base: 'flex items-center',

    variants: {
        styleGuide: {
            coremedia: 'gap-3',

            new: 'gap-2',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type CustomRootProps = HTMLAttributes<HTMLDivElement> & ToggleContextProviderProps;

export const CustomRoot = forwardRef<HTMLDivElement, CustomRootProps>(
    ({ styleGuide, className, ...rest }, forwardedRef) => (
        <ToggleContextProvider styleGuide={styleGuide}>
            <div ref={forwardedRef} className={rootVariants({ styleGuide, className })} {...rest} />
        </ToggleContextProvider>
    ),
);

CustomRoot.displayName = 'Toggle.Root';

const toggleVariants = tv({
    base: [
        'relative w-8 min-w-8 cursor-pointer transition-all',
        'rounded-full border-solid',
        'disabled:cursor-not-allowed',
        'after:absolute after:left-0 after:top-0 after:content-[""]',
        'after:pointer-events-none after:box-border after:transition-all',
        'after:rounded-full after:border after:border-solid',
    ],

    variants: {
        styleGuide: {
            coremedia: [
                'bg-coremedia-grey-200 h-4 outline-none',
                'border-coremedia-grey-200 border-x border-y-2',
                'disabled:opacity-50',
                'before:absolute before:left-2 before:top-1/2 before:h-1.5 before:w-[3px] before:content-[""]',
                'before:border-coremedia-grey-200 before:-translate-y-1/2 before:rotate-45 before:transition-colors',
                'before:border-0 before:border-b-2 before:border-r-2 before:border-solid',
                'data-[state=checked]:before:border-white',
                'data-[state=checked]:border-green-600 data-[state=checked]:bg-green-600',
                'after:border-coremedia-grey-200 after:bg-gray-30 after:size-3',
                'data-[state=checked]:after:translate-x-[150%] data-[state=checked]:after:border-green-600',
            ],

            new: [
                'border-gray-250 bg-gray-250 h-5 border-2',
                'disabled:border-gray-100 disabled:bg-gray-100',
                'outline-offset-3 outline-1 outline-red-500',
                'data-[state=checked]:border-green-500 data-[state=checked]:bg-green-500 data-[state=checked]:disabled:opacity-50',
                'after:border-gray-250 after:size-4 after:bg-white disabled:after:border-gray-100',
                'data-[state=checked]:after:translate-x-3/4 data-[state=checked]:after:border-green-500',
            ],
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type ToggleProps = Omit<CheckboxProps, 'checked' | 'defaultChecked' | 'onCheckedChange'> &
    VariantProps<typeof toggleVariants> & {
        checked?: boolean;
        defaultChecked?: boolean;
        onCheckedChange?(checked: boolean): void;
    };

export const CustomToggle = forwardRef<ElementRef<typeof Root>, ToggleProps>(
    ({ styleGuide: mainStyleGuide, className, ...rest }, forwardedRef) => {
        const { randomToggleId, styleGuide } = useToggle('Toggle');

        return (
            <Root
                ref={forwardedRef}
                id={randomToggleId}
                className={toggleVariants({ styleGuide: mainStyleGuide ?? styleGuide, className })}
                {...rest}
            />
        );
    },
);

CustomToggle.displayName = 'Toggle.Input';

const labelVariants = tv({
    base: 'mb-0 text-sm',

    variants: {
        styleGuide: {
            coremedia: 'text-coremedia-grey-700 font-semibold leading-4 tracking-normal',

            new: 'font-normal text-black',
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & VariantProps<typeof labelVariants>;

export const CustomLabel = forwardRef<HTMLLabelElement, LabelProps>(
    ({ styleGuide: mainStyleGuide, className, ...rest }, forwardedRef) => {
        const { randomToggleId, styleGuide } = useToggle('Label');

        return (
            <label
                ref={forwardedRef}
                htmlFor={randomToggleId}
                className={labelVariants({ styleGuide: mainStyleGuide ?? styleGuide, className })}
                {...rest}
            />
        );
    },
);

CustomLabel.displayName = 'Toggle.Label';
