import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type LineContextProps = {
    hasErrors: boolean;
};

const LineContext = createContext<LineContextProps | null>(null);

export type LineContextProviderProps = {
    children: ReactNode;
    hasErrors?: boolean;
};

export function LineContextProvider({ hasErrors: initialHasErrors = false, children }: LineContextProviderProps) {
    const [hasErrors, setHasErrors] = useState(initialHasErrors);

    useEffect(() => {
        setHasErrors(initialHasErrors);
    }, [initialHasErrors]);

    return (
        <LineContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                hasErrors,
            }}
        >
            {children}
        </LineContext.Provider>
    );
}

export function useLine(componentName: string) {
    const lineContext = useContext(LineContext);

    if (!lineContext) {
        throw new Error(`Line.${componentName} has to be inside the Line.Root component`);
    }

    return lineContext;
}
