import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { Color, RGBColor, SketchPicker, SketchPickerProps } from 'react-color';
import { isObject } from '@sidetalk/helpers';
import { VisuallyHidden } from '@components/VisuallyHidden';
import { cn } from '@helpers/index';
import { useColorPicker } from './context';
import { Content, Root, Trigger } from './styles';

export type ColorPickerRootProps = typeof Root;

const isRGBColor = (value: object): value is RGBColor => Object.hasOwn(value, 'r');

const getColorTransformed = (color: Color) => {
    if (isObject(color)) {
        if (isRGBColor(color)) {
            return `rgba(${color.r},${color.g},${color.b},${color.a ?? 1})`;
        }

        return `hlsa(${color.h},${color.s},${color.l},${color.a ?? 1})`;
    }

    return color;
};

const Preview = forwardRef<ElementRef<typeof Trigger>, ComponentPropsWithoutRef<typeof Trigger>>(
    ({ className, children, ...rest }, forwardedRef) => {
        const { color } = useColorPicker('Preview');

        const colorTransformed = getColorTransformed(color);

        return (
            <Trigger
                ref={forwardedRef}
                className={cn(
                    ['size-11 cursor-pointer outline-none', 'border-coremedia-grey-200 rounded-xs border border-solid'],
                    className,
                )}
                style={{ backgroundColor: colorTransformed }}
                {...rest}
            >
                <VisuallyHidden.Root>{children}</VisuallyHidden.Root>
            </Trigger>
        );
    },
);

Preview.displayName = 'ColorPicker.Preview';

const Picker = forwardRef<ElementRef<typeof SketchPicker>, SketchPickerProps>((props, forwardedRef) => {
    const { color, handleColorChange } = useColorPicker('Picker');

    return <SketchPicker ref={forwardedRef} color={color} onChange={handleColorChange} {...props} />;
});

Picker.displayName = 'ColorPicker.Picker';

export const ColorPicker = {
    Root,
    Preview,
    Trigger,
    Content,
    Picker,
};
