import { MutableRefObject, useLayoutEffect, useRef } from 'react';
import type { ActionWithRulesProps } from 'react-querybuilder';
import { Button } from '@components/Button';
import { useQueryBuilderState } from '../../context/QueryBuilder';

export function AddRule({ label, handleOnClick }: ActionWithRulesProps) {
    const { state } = useQueryBuilderState();
    const { resources, disabled } = state;
    const addRuleElement = useRef<HTMLButtonElement>();

    useLayoutEffect(() => {
        if (!addRuleElement.current) {
            return;
        }

        const addGroupElement = addRuleElement.current.nextSibling;
        const ruleGroupEl = addRuleElement.current.closest('.ruleGroup');

        if (ruleGroupEl) {
            const buttonsContainer = document.createElement('div');

            buttonsContainer.classList.add('ruleGroup-buttonsContainer');
            ruleGroupEl.appendChild(buttonsContainer);

            buttonsContainer.appendChild(addRuleElement.current);
            if (addGroupElement) {
                buttonsContainer.appendChild(addGroupElement);
            }
        }
    }, []);

    return (
        <Button
            ref={addRuleElement as MutableRefObject<HTMLButtonElement>}
            onClick={handleOnClick}
            styleGuide="coremedia"
            variant="boxed"
            disabled={disabled}
        >
            {resources?.AddRule?.label ?? label}
        </Button>
    );
}
