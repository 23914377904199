import { ComponentPropsWithoutRef } from 'react';
import { CustomLabel, CustomRoot, CustomToggle } from './styles';

export type ToggleRootProps = ComponentPropsWithoutRef<typeof CustomRoot>;

export const Toggle = {
    Root: CustomRoot,
    Input: CustomToggle,
    Label: CustomLabel,
};
