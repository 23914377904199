import type { ActionWithRulesProps } from 'react-querybuilder';
import { Button } from '../../../Button';
import { useQueryBuilderState } from '../../context/QueryBuilder';

export function AddGroup({ label, handleOnClick, level }: ActionWithRulesProps) {
    const { state } = useQueryBuilderState();
    const { resources, maxDepthLevel, disabled } = state;

    return (
        <Button
            onClick={handleOnClick}
            disabled={level >= (maxDepthLevel ?? 2) || disabled}
            styleGuide="coremedia"
            variant="boxed"
        >
            {resources?.AddGroup?.label ?? label}
        </Button>
    );
}
