import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type StyleGuideType = 'coremedia' | 'new';

type OverlayContextProps = {
    styleGuide: StyleGuideType;
    setStyleGuide: (value: StyleGuideType) => void;
};

const OverlayContext = createContext<OverlayContextProps | null>(null);

export type OverlayContextProviderProps = {
    children: ReactNode;
    styleGuide?: StyleGuideType;
};

export function OverlayContextProvider({
    styleGuide: initialStyleGuide = 'coremedia',
    children,
}: OverlayContextProviderProps) {
    const [styleGuide, setStyleGuide] = useState<StyleGuideType>(initialStyleGuide);

    useEffect(() => {
        setStyleGuide(initialStyleGuide);
    }, [initialStyleGuide]);

    return (
        <OverlayContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                styleGuide,
                setStyleGuide,
            }}
        >
            {children}
        </OverlayContext.Provider>
    );
}

export function useOverlay(componentName: string) {
    const overlayContext = useContext(OverlayContext);

    if (!overlayContext) {
        throw new Error(`Overlay.${componentName} has to be inside the Overlay.Root component`);
    }

    return overlayContext;
}
