import { SVGProps, forwardRef } from 'react';

export const Spinner = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
    ({ color = 'currentColor', ...rest }, ref) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15 15"
            width="1em"
            height="1em"
            fill="none"
            {...rest}
            ref={ref}
        >
            <circle
                cx={7.5}
                cy={7.5}
                r={7}
                fill="none"
                stroke={color}
                strokeWidth={1}
                strokeDasharray="30px,60px"
                strokeDashoffset={0}
                className="animate-spinnerCircle"
            />
        </svg>
    ),
);

Spinner.displayName = 'Spinner';
