/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as Tabs from '@radix-ui/react-tabs';
import { styled } from '@styles';

export const IconCircle = styled('div', {
    position: 'relative',
    width: 40,
    height: 40,
    borderRadius: '$full',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '$4',
    fontSize: '$lg',
    lineHeight: '24px',
    color: '$white',

    '&::after': {
        content: '',
        position: 'absolute',
        backgroundColor: '$gray100',
    },

    variants: {
        isChecked: {
            true: {
                fontSize: '$2xl',
                backgroundColor: '$green500',
            },

            false: {
                backgroundColor: '$red500',
            },
        },
    },

    defaultVariants: {
        isChecked: 'false',
    },
});

export const List = styled(Tabs.List, {
    flex: 1,
    position: 'relative',
    display: 'flex',
    width: '100%',
    gap: '$6',

    '&[data-orientation="horizontal"]': {
        justifyContent: 'center',

        '&::after': {
            right: 0,
            left: 0,
            top: '20px',
            width: '100%',
            height: 1,
        },
    },

    '&[data-orientation="vertical"]': {
        flexDirection: 'column',

        '&::after': {
            top: 0,
            bottom: 0,
            left: '20px',
            height: '100%',
            width: 1,
        },
    },

    '* + *': {
        '&[data-orientation="horizontal"]': {
            [`${IconCircle}`]: {
                '&::after': {
                    right: 0,
                    left: 0,
                    top: '20px',
                    width: '20px',
                    height: 1,
                    transform: 'translateX(calc(-100% - 4px))',
                },
            },
        },

        '&[data-orientation="vertical"]': {
            [`${IconCircle}`]: {
                '&::after': {
                    top: 0,
                    bottom: 0,
                    left: '20px',
                    height: '24px',
                    width: 1,
                    transform: 'translateY(-100%)',
                },
            },
        },
    },
});

export const Trigger = styled(Tabs.Trigger, {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    fontSize: '$xs',
    fontWeight: '$semibold',
    lineHeight: '24px',
    color: '$gray250',
    zIndex: 2,
    backgroundColor: '$white',
    cursor: 'pointer',
    transition: 'color 0.05s ease-in',

    '&:not(:disabled)': {
        '&:hover': {
            color: '$gray850',
        },

        '&:focus': {
            color: '$gray850',
        },

        '&[data-state="active"]': {
            color: '$black',
        },
    },

    '&:disabled': {
        cursor: 'not-allowed',
    },
});

export const Content = styled(Tabs.Content, {
    flex: 1,
    height: 'auto',
    outline: 'none',
});

Content.displayName = 'Steps.Content';

export const Separator = styled('div', {
    display: 'none',
    backgroundColor: '$gray100',
});

export const Root = styled(Tabs.Root, {
    display: 'grid',

    variants: {
        hasSeparator: {
            true: {
                '&[data-orientation="horizontal"]': {
                    [`${Separator}`]: {
                        display: 'block',
                        height: 1,
                        margin: '2.375rem 0',
                    },
                },

                '&[data-orientation="vertical"]': {
                    gridTemplateColumns: '1fr auto 1fr',

                    [`${Separator}`]: {
                        display: 'block',
                        width: 1,
                        margin: '0 2.375rem',
                    },
                },
            },

            false: {
                '&[data-orientation="vertical"]': {
                    gridTemplateColumns: '1fr 1fr',
                },
            },
        },
    },

    defaultVariants: {
        hasSeparator: false,
    },
});
