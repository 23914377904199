import { useInput } from 'react-day-picker';
import { DatePickerSingle } from './components/Single';
import { DatePickerMultiple } from './components/Multiple';
import { DatePickerRange } from './components/Range';
import { DatePickerWithDefaultRanges } from './components/RangeWithDefaulRanges';
import { Calendar } from './components/Calendar';

import {
    DatePickerMultipleProps,
    DatePickerRangeProps,
    DatePickerRanges,
    DatePickerSingleProps,
    DatePickerWithDefaultRangesProps,
    CalendarProps,
    Locales,
} from './types';

export { getYearsRangeForDatePicker } from './helpers';

export type {
    DatePickerMultipleProps,
    DatePickerSingleProps,
    DatePickerWithDefaultRangesProps,
    DatePickerRangeProps,
    DatePickerRanges,
    CalendarProps,
    Locales,
};

export enum DateFormat {
    longLocalizedDate = 'P', // Long localized date (It uses the date format based on the user locale, Example on en-US: 04/29/1453, on pt-PT: 29/04/1453)
    longLocalizedDateAndTime = 'P HH:mm', // Adds time to the end of date
    longLocalizedDateAndTimeWithSeconds = 'P HH:mm:ss', // Adds time and seconds to the end of date
    dayOfWeek = 'EEEEE', // Mo, Tu, We, Th, Fr, Sa, Su
    oldLongLocalizedDate = 'yyyy-MM-dd', // 2023-05-26
    dateAndTime = 'yyyy-MM-dd HH:mm', // 2023-05-26, 15:00
    dateAndTimeWithSeconds = 'yyyy-MM-dd HH:mm:ss', // 2023-05-26, 15:00:00
    time = 'HH:mm', // 2023-05-26, 15:00
    timeWithSeconds = 'HH:mm:ss', // 2023-05-26, 15:00:00
}

const useInputForDatePicker = useInput;

export const DatePicker = {
    Single: DatePickerSingle,
    Multiple: DatePickerMultiple,
    Range: DatePickerRange,
    WithDefaultRange: DatePickerWithDefaultRanges,
};

export {
    DatePickerSingle,
    DatePickerMultiple,
    DatePickerRange,
    DatePickerWithDefaultRanges,
    Calendar,
    useInputForDatePicker,
};
