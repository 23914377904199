import { MouseEventHandler } from 'react';
import { format, isSameMonth } from 'date-fns';
import { CaptionProps, IconLeft, IconRight, useDayPicker, useNavigation } from 'react-day-picker';
import { Text } from '@components/Text';
import { DateFormat } from '@components/DatePicker';
import { DatePickerRootProps, DatePickerVariants } from '@components/DatePicker/types';
import { MonthsDropdown } from './components/MonthsDropdown';
import { YearsDropdown } from './components/YearsDropdown';
import { CustomCalendarNavigationButton, DatePickerStyled, NavigationContainer } from './styles';

const getNavigation = ({ styleGuide }: DatePickerVariants) =>
    function Navigation({ displayMonth }: CaptionProps) {
        const {
            numberOfMonths,
            disableNavigation,
            captionLayout,
            locale,
            labels: { labelPrevious, labelNext },
            formatters: { formatCaption },
        } = useDayPicker();
        const { previousMonth, nextMonth, goToMonth, displayMonths } = useNavigation();

        const displayIndex = displayMonths.findIndex((month) => isSameMonth(displayMonth, month));

        const isFirst = displayIndex === 0;
        const isLast = displayIndex === displayMonths.length - 1;

        const disableNextButton = numberOfMonths > 1 && !(isFirst || !isLast);
        const disablePreviousButton = numberOfMonths > 1 && !(isLast || !isFirst);

        const handlePreviousClick: MouseEventHandler = () => {
            if (!previousMonth) return;

            goToMonth(previousMonth);
        };

        const handleNextClick: MouseEventHandler = () => {
            if (!nextMonth) return;

            goToMonth(nextMonth);
        };

        const previousLabel = labelPrevious(previousMonth, { locale });
        const nextLabel = labelNext(nextMonth, { locale });

        const showDropdown = captionLayout === 'dropdown' || captionLayout === 'dropdown-buttons';

        const hideButtons = captionLayout === 'dropdown' || disableNavigation;

        return (
            <NavigationContainer isDropdown={showDropdown} isWithButtons={!hideButtons} styleGuide={styleGuide}>
                {!hideButtons && (
                    <CustomCalendarNavigationButton
                        styleGuide={styleGuide}
                        onClick={handlePreviousClick}
                        aria-label={previousLabel}
                        disabled={disableNextButton}
                    >
                        <IconLeft />
                    </CustomCalendarNavigationButton>
                )}
                {showDropdown ? (
                    <>
                        <MonthsDropdown displayMonth={displayMonth} styleGuide={styleGuide} />
                        <YearsDropdown displayYear={displayMonth} styleGuide={styleGuide} />
                    </>
                ) : (
                    <Text asChild size="xs" className="mx-auto capitalize">
                        <h2>{formatCaption(displayMonth, { locale })}</h2>
                    </Text>
                )}
                {!hideButtons && (
                    <CustomCalendarNavigationButton
                        styleGuide={styleGuide}
                        onClick={handleNextClick}
                        aria-label={nextLabel}
                        disabled={disablePreviousButton}
                    >
                        <IconRight />
                    </CustomCalendarNavigationButton>
                )}
            </NavigationContainer>
        );
    };

export function DatePickerRoot(props: DatePickerRootProps) {
    const { styleGuide } = props;

    return (
        <DatePickerStyled
            components={{
                Caption: getNavigation({ styleGuide }),
            }}
            formatters={{
                formatWeekdayName: (weekName, options) => format(weekName, DateFormat.dayOfWeek, options),
            }}
            {...props}
        />
    );
}
