import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { TabsContentProps } from '@radix-ui/react-tabs';
import { CustomScrollArea } from '@components/CustomScrollArea';
import { TabsContextProvider, TabsContextProviderProps } from './context';
import { CustomRoot, CustomList, CustomTrigger, RenderExtraContainer, CustomContent, ExtraValue } from './styles';

type RootProps = ComponentPropsWithoutRef<typeof CustomRoot> & TabsContextProviderProps;

const Root = forwardRef<ElementRef<typeof CustomRoot>, RootProps>(
    ({ styleGuide, alignment, layout, size, ...rest }, forwardedRef) => (
        <TabsContextProvider styleGuide={styleGuide} alignment={alignment} layout={layout} size={size}>
            <CustomRoot ref={forwardedRef} {...rest} />
        </TabsContextProvider>
    ),
);

Root.displayName = 'Tabs.Root';

export type TabsRootProps = typeof Root;

type CustomTabsContentProps = Omit<TabsContentProps, 'asChild'>;

const ContentScrollable = forwardRef<ElementRef<typeof CustomContent>, CustomTabsContentProps>(
    ({ children, ...rest }, ref) => (
        <CustomContent ref={ref} {...rest} asChild>
            <CustomScrollArea.Root>
                <CustomScrollArea.Viewport>{children}</CustomScrollArea.Viewport>
                <CustomScrollArea.Scrollbar orientation="vertical">
                    <CustomScrollArea.Thumb />
                </CustomScrollArea.Scrollbar>
                <CustomScrollArea.Scrollbar orientation="horizontal">
                    <CustomScrollArea.Thumb />
                </CustomScrollArea.Scrollbar>
            </CustomScrollArea.Root>
        </CustomContent>
    ),
);

ContentScrollable.displayName = 'Tabs.Content';

export const Tabs = {
    Root,
    List: CustomList,
    Trigger: CustomTrigger,
    Content: CustomContent,
    ContentScrollable,
    RenderExtraContainer,
    ExtraValue,
};
