import { SelectOption, SingleValue } from '@components/Select';
import { FilterOption } from '../types';
import {
    ActionTypes,
    AddSavedFiltersAction,
    ConfirmFilterChangesAction,
    OnOpenPopoverAction,
    RemoveFilterAppliedAction,
    RemoveFilterBeingChangedAction,
    RemoveFilterSelectedAction,
    SaveFilterSelectedAction,
    SelectedFilterChangesAction,
    SelectNewFilterAction,
    SetFilterToBeChangedAction,
} from './action';

export type FilterState = {
    isPopoverOpen: boolean;
    filterSelected: SingleValue<SelectOption>;
    selectedConfig: FilterOption | undefined;
    filtersApplied: FilterOption[];
    indexAppliedFilterBeingChanged: number | undefined;
};

type Action =
    | SelectNewFilterAction
    | RemoveFilterAppliedAction
    | SaveFilterSelectedAction
    | RemoveFilterSelectedAction
    | OnOpenPopoverAction
    | SelectedFilterChangesAction
    | ConfirmFilterChangesAction
    | SetFilterToBeChangedAction
    | RemoveFilterBeingChangedAction
    | AddSavedFiltersAction;

export function filterReducer(state: FilterState, action: Action): FilterState {
    switch (action.type) {
        case ActionTypes.addSavedFilters: {
            const { savedFilters } = action.payload;

            return {
                ...state,
                filtersApplied: savedFilters,
            };
        }

        case ActionTypes.selectNewFilter: {
            const { filterSelected, filterData } = action.payload;
            const { filtersApplied } = state;
            let newState = state;

            if (filterSelected) {
                Object.entries(filterData).forEach(([groupLabel, filterDataOptions]) => {
                    const optionSelected = filterDataOptions.find((option) => option.id === filterSelected.value);

                    if (optionSelected) {
                        if (optionSelected.type === 'empty') {
                            newState = {
                                ...state,
                                filtersApplied: [...filtersApplied, { ...optionSelected, groupLabel }],
                                selectedConfig: undefined,
                                isPopoverOpen: false,
                                filterSelected: null,
                            };
                        } else {
                            newState = {
                                ...state,
                                selectedConfig: optionSelected,
                                isPopoverOpen: true,
                                filterSelected,
                            };
                        }
                    }
                });
            } else {
                newState = {
                    ...state,
                    selectedConfig: undefined,
                    isPopoverOpen: false,
                    filterSelected,
                };
            }

            return newState;
        }

        case ActionTypes.removeFilterApplied: {
            const { index } = action.payload;
            const { filtersApplied } = state;

            return {
                ...state,
                filterSelected: null,
                selectedConfig: undefined,
                filtersApplied: filtersApplied.filter((_, filterIndex) => filterIndex !== index),
            };
        }

        case ActionTypes.saveFilterSelected: {
            const { selectedConfig, filtersApplied } = state;

            if (selectedConfig) {
                return {
                    ...state,
                    filtersApplied: [...filtersApplied, selectedConfig],
                    selectedConfig: undefined,
                    filterSelected: null,
                };
            }

            return state;
        }

        case ActionTypes.removeFilterSelected: {
            return {
                ...state,
                filterSelected: null,
                selectedConfig: undefined,
            };
        }

        case ActionTypes.onOpenPopover: {
            const { open } = action.payload;

            return {
                ...state,
                isPopoverOpen: open,
            };
        }

        case ActionTypes.selectedFilterChanges: {
            const { newFilterChanges } = action.payload;

            return {
                ...state,
                selectedConfig: newFilterChanges,
            };
        }

        case ActionTypes.confirmFilterChanges: {
            const { index } = action.payload;
            const { selectedConfig } = state;

            return {
                ...state,
                filtersApplied: state.filtersApplied.map((filter, filterIndex) => {
                    if (filterIndex === index && selectedConfig) {
                        return selectedConfig;
                    }

                    return filter;
                }),
                selectedConfig: undefined,
                indexAppliedFilterBeingChanged: undefined,
            };
        }

        case ActionTypes.setFilterToBeChanged: {
            const { index } = action.payload;
            const { filtersApplied } = state;

            return {
                ...state,
                indexAppliedFilterBeingChanged: index,
                selectedConfig: filtersApplied[index],
            };
        }

        case ActionTypes.removeFilterBeingChanged: {
            return {
                ...state,
                indexAppliedFilterBeingChanged: undefined,
                selectedConfig: undefined,
            };
        }

        default:
            return state;
    }
}
