import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { CustomRoot, CustomRadioGroupItem, CustomIndicator } from './styles';

export type RadioGroupRootProps = typeof CustomRoot;

const Radio = forwardRef<
    ElementRef<typeof CustomRadioGroupItem>,
    ComponentPropsWithoutRef<typeof CustomRadioGroupItem>
>((props, forwardedRef) => (
    <CustomRadioGroupItem ref={forwardedRef} {...props}>
        <CustomIndicator />
    </CustomRadioGroupItem>
));

Radio.displayName = 'RadioGroup.Radio';

export const RadioGroup = {
    Root: CustomRoot,
    Radio,
};
