import { CheckboxOptions } from './components/CheckboxOptions';
import { DateOptions } from './components/DateOptions';
import { InputOptions } from './components/InputOptions';
import { RadioOptions } from './components/RadioOptions';
import { SelectOptions } from './components/SelectOptions';
import { FilterOption, FilterPropOptions } from '../../types';

export type FilterOptionsProps = {
    filterOption: FilterOption | undefined;
    onChange: (filterChanges: FilterOption) => void;
    options: FilterPropOptions;
};

export function FilterOptions({ filterOption, onChange, options }: FilterOptionsProps) {
    if (filterOption?.type === 'input') {
        return <InputOptions filterOption={filterOption} onChange={onChange} />;
    }

    if (filterOption?.type === 'date') {
        return <DateOptions filterOption={filterOption} onChange={onChange} options={options} />;
    }

    if (filterOption?.type === 'select') {
        return (
            <SelectOptions
                filterOption={filterOption}
                onChange={onChange}
                filterOptions={FilterOptions}
                options={options}
            />
        );
    }

    if (filterOption?.type === 'radio') {
        return (
            <RadioOptions
                filterOption={filterOption}
                onChange={onChange}
                filterOptions={FilterOptions}
                options={options}
            />
        );
    }

    if (filterOption?.type === 'checkbox') {
        return (
            <CheckboxOptions
                filterOption={filterOption}
                onChange={onChange}
                filterOptions={FilterOptions}
                options={options}
            />
        );
    }

    return null;
}
