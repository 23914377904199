import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { Root, Trigger, Close, PopoverProps, PopoverPortalProps, Portal } from '@radix-ui/react-popover';
import { CustomContent, CustomArrow } from './styles';

export type PopoverRootProps = PopoverProps;

type ContentProps = PopoverPortalProps & ComponentPropsWithoutRef<typeof CustomContent>;

const Content = forwardRef<ElementRef<typeof CustomContent>, ContentProps>(
    ({ children, container, forceMount, ...rest }, forwardedRef) => (
        <Portal container={container ?? document.body} forceMount={forceMount}>
            <CustomContent ref={forwardedRef} sideOffset={10} alignOffset={-10} align="start" {...rest}>
                {children}
            </CustomContent>
        </Portal>
    ),
);

Content.displayName = 'Popover.Content';

Root.displayName = 'Popover.Root';

Trigger.displayName = 'Popover.Trigger';

Close.displayName = 'Popover.CloseButton';

export const Popover = {
    Root,
    Trigger,
    Content,
    CloseButton: Close,
    Arrow: CustomArrow,
};
