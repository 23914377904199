import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { ActionButton, Label, Root } from './styles';
import { BubbleContextProvider } from './context';

const CustomRoot = forwardRef<ElementRef<typeof Root>, ComponentPropsWithoutRef<typeof Root>>(
    ({ styleGuide, ...rest }, forwardedRef) => (
        <BubbleContextProvider styleGuide={styleGuide}>
            <Root ref={forwardedRef} {...rest} />
        </BubbleContextProvider>
    ),
);

CustomRoot.displayName = 'Bubble.Root';

export type BubbleRootProps = typeof CustomRoot;

export const Bubble = {
    Root: CustomRoot,
    Label,
    ActionButton,
};
