import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { uid } from '@sidetalk/helpers';

type StyleGuideType = 'coremedia' | 'new';

type SearchContextProps = {
    randomSearchId: string;
    expandable: boolean;
    styleGuide: StyleGuideType;
};

const SearchContext = createContext<SearchContextProps | null>(null);

export type SearchContextProviderProps = {
    children: ReactNode;
    styleGuide?: StyleGuideType;
    expandable?: boolean;
};

export function SearchContextProvider({
    styleGuide: initialStyleGuide = 'coremedia',
    expandable: initialExpandable = false,
    children,
}: SearchContextProviderProps) {
    const [styleGuide, setStyleGuide] = useState<StyleGuideType>(initialStyleGuide);
    const [expandable, setExpandable] = useState(initialExpandable);

    const randomSearchId = useMemo(uid, []);

    useEffect(() => {
        setStyleGuide(initialStyleGuide);
    }, [initialStyleGuide]);

    useEffect(() => {
        setExpandable(initialExpandable);
    }, [initialExpandable]);

    return (
        <SearchContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                styleGuide,
                expandable,
                randomSearchId,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
}

export function useSearch(componentName: string) {
    const searchContext = useContext(SearchContext);

    if (!searchContext) {
        throw new Error(`Search.${componentName} has to be inside the Search.Root component`);
    }

    return searchContext;
}
