import { createContext, MutableRefObject, ReactNode, useCallback, useContext, useReducer, useRef } from 'react';
import { SelectOption, SingleValue } from '@components/Select';
import { filterReducer, FilterState } from './reducer';
import {
    addSavedFiltersAction,
    confirmFilterChangesAction,
    onOpenPopoverAction,
    removeFilterAppliedAction,
    removeFilterBeingChangedAction,
    removeFilterSelectedAction,
    saveFilterSelectedAction,
    selectedFilterChangesAction,
    selectNewFilterAction,
    setFilterToBeChangedAction,
} from './reducer/action';
import { FilterData, FilterOption } from './types';

type FilterContextProps = {
    filterState: FilterState;
    containerRef: MutableRefObject<HTMLDivElement | null>;
    handleSelectNewFilter: (newFilter: SingleValue<SelectOption>, filterData: FilterData) => void;
    handleRemoveFilterApplied: (filterIndex: number) => void;
    handleSaveFilterSelected: () => void;
    handleRemoveFilterSelected: () => void;
    handleOnOpenPopover: (open: boolean) => void;
    handleSelectedFilterChanges: (newFilterChanges: FilterOption) => void;
    handleFiltersAppliedBeingChanged: (open: boolean, index: number) => void;
    handleConfirmFilterChanges: (index: number) => void;
    addSavedFilters: (savedFilters: FilterOption[]) => void;
};

const FilterContext = createContext<FilterContextProps | null>(null);

export type FilterContextProviderProps = {
    children: ReactNode;
};

const defaultFilterContextData: FilterState = {
    filterSelected: null,
    selectedConfig: undefined,
    filtersApplied: [],
    isPopoverOpen: true,
    indexAppliedFilterBeingChanged: undefined,
};

export function FilterContextProvider({ children }: FilterContextProviderProps) {
    const [filterState, dispatch] = useReducer(filterReducer, defaultFilterContextData);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const addSavedFilters = useCallback((savedFilters: FilterOption[]) => {
        dispatch(addSavedFiltersAction(savedFilters));
    }, []);

    const handleSelectNewFilter = useCallback((newFilter: SingleValue<SelectOption>, filterData: FilterData) => {
        dispatch(selectNewFilterAction(newFilter, filterData));
    }, []);

    const handleRemoveFilterApplied = useCallback((filterIndex: number) => {
        dispatch(removeFilterAppliedAction(filterIndex));
    }, []);

    const handleSaveFilterSelected = useCallback(() => {
        dispatch(saveFilterSelectedAction());
    }, []);

    const handleRemoveFilterSelected = useCallback(() => {
        dispatch(removeFilterSelectedAction());
    }, []);

    const handleOnOpenPopover = useCallback((open: boolean) => {
        dispatch(onOpenPopoverAction(open));
    }, []);

    const handleSelectedFilterChanges = useCallback((newFilterChanges: FilterOption) => {
        dispatch(selectedFilterChangesAction(newFilterChanges));
    }, []);

    const handleConfirmFilterChanges = useCallback((index: number) => {
        dispatch(confirmFilterChangesAction(index));
    }, []);

    const handleFiltersAppliedBeingChanged = useCallback((open: boolean, index: number) => {
        if (open) {
            dispatch(setFilterToBeChangedAction(index));
        } else {
            dispatch(removeFilterBeingChangedAction());
        }
    }, []);

    return (
        <FilterContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                filterState,
                containerRef,
                handleSelectNewFilter,
                handleRemoveFilterApplied,
                handleSaveFilterSelected,
                handleRemoveFilterSelected,
                handleOnOpenPopover,
                handleSelectedFilterChanges,
                handleFiltersAppliedBeingChanged,
                handleConfirmFilterChanges,
                addSavedFilters,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
}

export const FilterContextConsumer = FilterContext.Consumer;

export function useFilter(componentName: string) {
    const filterContext = useContext(FilterContext);

    if (!filterContext) {
        throw new Error(`Filter.${componentName} has to be inside the Filter.Root component`);
    }

    return filterContext;
}
