import { MultiValue } from 'react-select';
import { coremediaColors } from '@sidetalk/tokens';
import { isString } from '@sidetalk/helpers';
import { Select } from '@components/Select';
import { useQueryBuilderState } from '../../context/QueryBuilder';

const customSelectStyles = {
    container: () => ({
        backgroundColor: coremediaColors.white,
        width: '100%',
        height: 'auto',
    }),
    control: () => ({
        height: 'auto',
    }),
    multiValue: () => ({
        margin: '3px 3px 3px 0',
        height: '22px',
    }),
};

export type SelectOption = {
    label: string;
    value: string | number;
};

type ValueType = string | SelectOption[];

type CreatorValueSelectorProps = {
    className: string;
    value: ValueType;
    handleOnChange: (value: ValueType) => void;
};

export function Creator({ className, value, handleOnChange }: CreatorValueSelectorProps) {
    const { state } = useQueryBuilderState();
    const { resources, disabled } = state;

    const handleMultiChange = (values: MultiValue<SelectOption | undefined>) => {
        const ids = (values || []).map((v) => (v ? v.value : null));
        handleOnChange(ids.join('||'));
    };

    const defaultValue = isString(value)
        ? value
              .split('||')
              .filter((item) => item !== '')
              .map((item) => ({ value: item, label: item }))
        : value;

    return (
        <Select.Basic
            className={className}
            styleGuide="coremedia"
            styles={customSelectStyles}
            name="field"
            options={[]}
            isMulti
            isCreatable
            menuPortalTarget={document.body}
            menuPlacement="auto"
            onChange={handleMultiChange}
            value={defaultValue}
            placeholder={resources?.fill_out ?? 'Fill out'}
            noOptionsMessage={() => resources?.type_field ?? 'Type field'}
            formatCreateLabel={() => `${resources?.create ?? 'Create'}...`}
            isDisabled={disabled}
        />
    );
}
