import { StylesConfig } from 'react-select';
import { colors, coremediaColors, fontSizes, lineHeights, radii, space } from '@sidetalk/tokens';

export const DefaultOldStyles: StylesConfig = {
    container: (provided) => ({
        ...provided,
        height: 28,
        minHeight: 28,
    }),
    control: (provided, state) => ({
        ...provided,
        border: 0,
        borderRadius: 0,
        borderBottom:
            state.isFocused || state.menuIsOpen
                ? `1px solid ${coremediaColors.blue500}`
                : `1px solid ${coremediaColors.grey300}`,
        boxShadow: '0',
        '&:hover': {
            borderColor:
                state.isFocused || state.menuIsOpen
                    ? `1px solid ${coremediaColors.blue500}`
                    : `1px solid ${coremediaColors.grey300}`,
        },
        cursor: state.isDisabled ? 'not-allowed' : 'default',
        fontSize: 12,
        height: 28,
        minHeight: 28,
        background: 'transparent',
        lineHeight: '17.1429px',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0 6px',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        margin: 0,
        color: state.isDisabled ? coremediaColors.grey400 : coremediaColors.grey700,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: coremediaColors.grey400,
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: 12,
        padding: '4px 6px',
        color: coremediaColors.grey700,
        backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.03)' : 'none',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.03)',
        },
        ...(state.isSelected && { fontWeight: '600' }),
        ...(state.isDisabled && {
            color: coremediaColors.grey400,
            '&:hover': {
                backgroundColor: 'none',
            },
        }),
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        padding: '0 6px',
        height: 27,
        minHeight: 27,
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: 0,
        color: state.isDisabled ? coremediaColors.grey400 : coremediaColors.grey700,
        height: 10,
        width: 8,
        '&:hover': {
            color: state.isDisabled ? coremediaColors.grey400 : coremediaColors.grey700,
        },
    }),
    menu: (provided, state) => ({
        ...provided,
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
        border: `1px solid ${coremediaColors.grey200}`,
        marginBottom: state.placement === 'top' ? 'unset' : 8,
    }),
    group: (provided) => ({
        ...provided,
        padding: '4px 0 4px',
    }),
    groupHeading: (provided) => ({
        ...provided,
        margin: 0,
        padding: '4px 6px',
        fontSize: '12px',
        fontWeight: '600',
        color: coremediaColors.black,
        flex: 1,
        transition: 'all 0.2s ease-in-out',

        '&:hover': {
            backgroundColor: coremediaColors.grey100,
        },
    }),
    loadingIndicator: (provided) => provided,
    loadingMessage: (provided) => provided,
    menuList: (provided) => ({
        ...provided,
        'div.collapsed': {
            display: 'none !important',
        },
    }),
    menuPortal: (provided) => ({
        ...provided,
        pointerEvents: 'auto',
        zIndex: 15010,
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        fontSize: 12,
        color: coremediaColors.grey700,
    }),
};

export const MultiOldStyles: StylesConfig = {
    ...DefaultOldStyles,
    container: (provided, state) => ({
        ...DefaultOldStyles?.container?.(provided, state),
        height: 'unset',
    }),
    control: (provided, state) => ({
        ...DefaultOldStyles?.control?.(provided, state),
        height: 'unset',
    }),
    indicatorsContainer: (provided, state) => ({
        ...DefaultOldStyles?.indicatorsContainer?.(provided, state),
        height: 'unset',
        minHeight: 'unset',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        padding: '0 8px',
        color: coremediaColors.grey700,
        '&:hover': {
            color: coremediaColors.red500,
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        margin: '0 3px 4px 0',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        '&:hover': {
            backgroundColor: 'inherit',
            cursor: 'pointer',
            color: coremediaColors.red500,
        },
    }),
    multiValueLabel: (provided) => provided,
    menuList: (provided) => ({
        ...provided,
        'div.collapsed': {
            display: 'none !important',
        },
    }),
};

export const DefaultNewStyles: StylesConfig = {
    container: (provided, state) => ({
        ...provided,
        minHeight: space[8],

        ...(state.isDisabled && {
            cursor: 'not-allowed',
            pointerEvents: 'initial',

            '> *': {
                pointerEvents: 'none',
            },
        }),
    }),
    control: (provided) => ({
        ...provided,
        fontSize: fontSizes.sm,
        lineHeight: lineHeights.base,
        color: colors.gray850,
        padding: `0 ${space[2]} 0 ${space[4]}`,
        minWidth: '17.8125rem',
        gap: space[1],
        backgroundColor: colors.gray050,
        border: `1px solid ${colors.gray050}`,
        borderRadius: radii.xs,

        '&--is-focused, &--menu-is-open': {
            backgroundColor: colors.white,
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
        },

        '&--is-focused, &:hover': {
            borderColor: coremediaColors.blue500,
        },
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
    }),
    singleValue: (provided, state) => ({
        ...provided,
        margin: 0,
        color: state.isDisabled ? colors.gray100 : provided.color,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: colors.gray850,
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: fontSizes.xs,
        lineHeight: lineHeights.base,
        color: colors.gray700,
        userSelect: 'none',
        cursor: 'pointer',

        ...((state.isFocused || state.isSelected) && {
            outline: 'none',
            color: colors.red500,
        }),

        '&:hover': {
            outline: 'none',
            color: colors.red500,
        },

        ...(state.isDisabled && {
            color: colors.gray100,
            userSelect: 'none',
            cursor: 'initial',
            '&:hover': {
                backgroundColor: 'none',
            },
        }),
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        padding: '0 6px',
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: colors.gray850,
        width: space[6],
        height: space[6],

        ...(state.isDisabled && {
            padding: 0,
            color: colors.gray100,

            '&:hover': {
                color: colors.gray100,
            },
        }),
    }),
    menu: (provided) => ({
        ...provided,
        width: '100%',
        minWidth: 'fit-content',
        marginTop: space[3],
        backgroundColor: colors.white,
        padding: space[4],
        boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.20)',
        border: `1px solid ${colors.gray200}`,
        borderRadius: 6,
    }),
    group: (provided) => ({
        ...provided,
        display: 'flex',
        flexDirection: 'column',
        gap: space[2],

        '> div': {
            display: 'flex',
            flexDirection: 'column',
            gap: space[3],
        },
    }),
    groupHeading: (provided) => ({
        ...provided,
        fontSize: fontSizes.xs,
        lineHeight: lineHeights.base,
        color: colors.gray250,
        textTransform: 'uppercase',
    }),
    loadingIndicator: (provided) => provided,
    loadingMessage: (provided) => provided,
    menuList: (provided) => ({
        ...provided,
        display: 'flex',
        flexDirection: 'column',
        gap: space[3],
        backgroundColor: colors.white,
        overflowY: 'auto',

        '&::-webkit-scrollbar': {
            width: 4,
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.gray100,
            borderRadius: radii.xs,
        },

        'div.collapsed': {
            display: 'none !important',
        },
    }),
    menuPortal: (provided) => ({
        ...provided,
        pointerEvents: 'auto',
        zIndex: 15010,
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        fontSize: fontSizes.xs,
        lineHeight: lineHeights.base,
        color: colors.gray700,
    }),
};

export const MultiNewStyles: StylesConfig = {
    ...DefaultNewStyles,
    clearIndicator: (provided) => ({
        ...provided,
        color: colors.gray850,
        alignItems: 'center',
        padding: space[2],

        '&:hover, &:focus': {
            cursor: 'pointer',
            color: colors.red500,
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: colors.blue100,
        margin: `0 ${space[1]} 0 0`,
        padding: `0 ${space[2]}`,
        color: colors.blue500,
        borderRadius: radii.md,
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        marginLeft: space[2],

        '&:hover, &:focus': {
            cursor: 'pointer',
            backgroundColor: 'inherit',
            color: colors.red500,
        },
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        fontSize: fontSizes.xxs,
        lineHeights: lineHeights.shorter,
    }),
    menuList: (provided) => ({
        ...provided,
        'div.collapsed': {
            display: 'none !important',
        },
    }),
};
