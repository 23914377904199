import { ComponentPropsWithoutRef, ElementRef, HTMLAttributes, forwardRef } from 'react';
import { Popover } from '@components/Popover';
import { cn } from '@helpers/index';
import { ColorPickerContextProvider, ColorPickerContextProviderProps } from './context';

type CustomRootProps = HTMLAttributes<HTMLDivElement> & ColorPickerContextProviderProps;

export const Root = forwardRef<HTMLDivElement, CustomRootProps>(
    ({ color, onChange, className, ...rest }, forwardedRef) => (
        <Popover.Root>
            <ColorPickerContextProvider color={color} onChange={onChange}>
                <div ref={forwardedRef} className={cn(className)} {...rest} />
            </ColorPickerContextProvider>
        </Popover.Root>
    ),
);

Root.displayName = 'ColorPicker.Root';

export const Trigger = forwardRef<ElementRef<typeof Popover.Trigger>, ComponentPropsWithoutRef<typeof Popover.Trigger>>(
    (props, forwardedRef) => <Popover.Trigger ref={forwardedRef} {...props} />,
);

Trigger.displayName = 'ColorPicker.Trigger';

export const Content = forwardRef<ElementRef<typeof Popover.Content>, ComponentPropsWithoutRef<typeof Popover.Content>>(
    ({ className, ...rest }, forwardedRef) => (
        <Popover.Content ref={forwardedRef} className={cn('p-0', className)} {...rest} />
    ),
);

Content.displayName = 'ColorPicker.Content';
