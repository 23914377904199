import {
    ComponentPropsWithRef,
    ComponentPropsWithoutRef,
    Dispatch,
    ElementRef,
    SetStateAction,
    forwardRef,
} from 'react';
import { Icon } from '../Icon';
import { CustomRoot, CustomInput, CustomButton } from './styles';

export type PlusButtonProps = ComponentPropsWithoutRef<typeof CustomButton> & {
    increaseBy?: number;
    onButtonClicked: Dispatch<SetStateAction<number>>;
};

const PlusButton = forwardRef<ElementRef<typeof CustomButton>, PlusButtonProps>(
    ({ increaseBy = 1, onButtonClicked, title, ...rest }, forwardedRef) => {
        const handleClickPlusButton = () => {
            onButtonClicked((oldState) => oldState + increaseBy);
        };

        return (
            <CustomButton ref={forwardedRef} onClick={handleClickPlusButton} {...rest}>
                <Icon type="bytalk" iconName="open-details" label={title} />
            </CustomButton>
        );
    },
);

PlusButton.displayName = 'TextInput.PlusButton';

export type MinusButtonProps = ComponentPropsWithoutRef<typeof CustomButton> & {
    decreaseBy?: number;
    onButtonClicked: Dispatch<SetStateAction<number>>;
};

const MinusButton = forwardRef<ElementRef<typeof CustomButton>, MinusButtonProps>(
    ({ decreaseBy = 1, onButtonClicked, title, ...rest }, forwardedRef) => {
        const handleClickMinusButton = () => {
            onButtonClicked((oldState) => oldState - decreaseBy);
        };

        return (
            <CustomButton ref={forwardedRef} onClick={handleClickMinusButton} {...rest}>
                <Icon type="bytalk" iconName="check-parcial" label={title} />
            </CustomButton>
        );
    },
);

MinusButton.displayName = 'TextInput.MinusButton';

export type TextInputRootProps = ComponentPropsWithRef<typeof CustomRoot>;

export const TextInput = {
    Root: CustomRoot,
    Input: CustomInput,
    PlusButton,
    MinusButton,
};
