import { ComponentPropsWithRef, ComponentPropsWithoutRef, ElementRef, ReactNode, forwardRef } from 'react';
import { Root, Provider, Trigger, type TooltipProps, Portal, type TooltipPortalProps } from '@radix-ui/react-tooltip';
import { isEmpty } from '@sidetalk/helpers';
import { TooltipArrow, TooltipContent } from './styles';

export type TooltipProviderProps = ComponentPropsWithRef<typeof Provider>;

Provider.displayName = 'Tooltip.Provider';

type CustomTooltipProps = Omit<
    TooltipProps & TooltipPortalProps & ComponentPropsWithoutRef<typeof TooltipContent>,
    'content'
> & {
    content?: ReactNode;
};

const Basic = forwardRef<ElementRef<typeof TooltipContent>, CustomTooltipProps>(
    (
        {
            children,
            open,
            defaultOpen,
            delayDuration,
            disableHoverableContent,
            onOpenChange,
            forceMount,
            container,
            content,
            ...props
        },
        forwardedRef,
    ) => (
        <Root
            open={isEmpty(content) ? false : open}
            defaultOpen={defaultOpen}
            onOpenChange={onOpenChange}
            delayDuration={delayDuration}
            disableHoverableContent={disableHoverableContent}
        >
            <Trigger asChild>{children}</Trigger>
            <Portal forceMount={forceMount} container={container}>
                <TooltipContent
                    ref={forwardedRef}
                    sideOffset={4}
                    alignOffset={12}
                    collisionPadding={8}
                    arrowPadding={4}
                    {...props}
                >
                    {content}
                    <TooltipArrow width={14} height={8} />
                </TooltipContent>
            </Portal>
        </Root>
    ),
);

export type TooltipBasicProps = typeof Basic;

Basic.displayName = 'Tooltip.Basic';

export const Tooltip = {
    Provider,
    Basic,
};
