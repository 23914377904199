import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

type StyleGuideType = 'coremedia' | 'new';
type BorderType = 'no-border' | 'top' | 'bottom';

type AccordionContextProps = {
    styleGuide: StyleGuideType;
    setStyleGuide: (value: StyleGuideType) => void;
    border: BorderType;
    setBorder: (value: BorderType) => void;
};

const AccordionContext = createContext<AccordionContextProps | null>(null);

export type AccordionContextProviderProps = {
    children: ReactNode;
    styleGuide?: StyleGuideType;
    border?: BorderType;
};

export function AccordionContextProvider({
    styleGuide: initialStyleGuide = 'coremedia',
    border: initialBorder = 'top',
    children,
}: AccordionContextProviderProps) {
    const [styleGuide, setStyleGuide] = useState<StyleGuideType>(initialStyleGuide);
    const [border, setBorder] = useState<BorderType>(initialBorder);

    useEffect(() => {
        setStyleGuide(initialStyleGuide);
    }, [initialStyleGuide]);

    useEffect(() => {
        setBorder(initialBorder);
    }, [initialBorder]);

    const accordionContextValue = useMemo(
        () => ({
            styleGuide,
            setStyleGuide,
            border,
            setBorder,
        }),
        [styleGuide, setStyleGuide, border, setBorder],
    );

    return <AccordionContext.Provider value={accordionContextValue}>{children}</AccordionContext.Provider>;
}

export function useAccordion(componentName: string) {
    const accordionContext = useContext(AccordionContext);

    if (!accordionContext) {
        throw new Error(`Accordion.${componentName} has to be inside the Accordion.Root component`);
    }

    return accordionContext;
}
