import { styled } from '@styles';

export const CheckboxGroupContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
});

export const CheckboxContainer = styled('div', {
    display: 'grid',
    gap: '$2',
    gridTemplateColumns: 'max-content auto',
});

export const OptionContainer = styled('div', {
    width: '100%',
    borderRadius: '$xs',
    display: 'grid',
    gridColumn: 2,
    gap: '$4',
    marginBottom: '$2',
});
