import { ChangeEvent, createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Color, ColorChangeHandler, ColorResult } from 'react-color';

type ColorPickerContextProps = {
    color: Color;
    handleColorChange: ColorChangeHandler;
};

const ColorPickerContext = createContext<ColorPickerContextProps | null>(null);

export type ColorPickerContextProviderProps = {
    children: ReactNode;
    color?: Color;
    onChange?: ColorChangeHandler;
};

export function ColorPickerContextProvider({
    color: initialColor = '#000000',
    onChange,
    children,
}: ColorPickerContextProviderProps) {
    const [color, setColor] = useState<Color>(initialColor);

    const handleColorChange = useCallback(
        (newColor: ColorResult, e: ChangeEvent<HTMLInputElement>) => {
            onChange?.(newColor, e);
            setColor(newColor.hex);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    useEffect(() => {
        setColor(initialColor);
    }, [initialColor]);

    return (
        <ColorPickerContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                color,
                handleColorChange,
            }}
        >
            {children}
        </ColorPickerContext.Provider>
    );
}

export function useColorPicker(componentName: string) {
    const colorPickerContext = useContext(ColorPickerContext);

    if (!colorPickerContext) {
        throw new Error(`ColorPicker.${componentName} has to be inside the ColorPicker.Root component`);
    }

    return colorPickerContext;
}
