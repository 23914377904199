import { useState, useEffect, useCallback } from 'react';
import type { MultiValue } from 'react-select';
import { coremediaColors } from '@sidetalk/tokens';
import { Loader } from '@components/Loader';
import { Select, type SelectOption, type InputActionMeta } from '@components/Select';
import { useDebounceFunction } from '@sidetalk/hooks';
import { isEmpty } from '@sidetalk/helpers';
import { CustomFieldOptions } from '@components/QueryBuilder';
import { useQueryBuilderState } from '../../context/QueryBuilder';

const customSelectStyles = {
    container: () => ({
        backgroundColor: coremediaColors.white,
    }),
    multiValue: () => ({
        margin: 'auto 3px auto 0 !important',
    }),
};

type CustomValueSelectorProps = {
    className: string;
    value: string;
    handleOnChange: (value: unknown) => void;
    fieldId: string;
};

export function Custom({ className, value, handleOnChange, fieldId }: CustomValueSelectorProps) {
    const [loader, setLoader] = useState(true);
    const [options, setOptions] = useState<SelectOption[]>([]);

    const {
        state: { customFieldOptions, getCustomFieldOptions, resources, disabled },
    } = useQueryBuilderState();
    const debounceGetCustomFieldOptions = useDebounceFunction(getCustomFieldOptions, customFieldOptions?.delay);

    const getCustomOptions = useCallback(async (id: string, customOptions?: CustomFieldOptions) => {
        setLoader(true);

        try {
            const opts = await getCustomFieldOptions?.(id, customOptions);
            setOptions(opts ?? []);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('QueryBuilder::Custom::getOptions::Error', error);
        } finally {
            setLoader(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelectInputChange = (searchText: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change' && !isEmpty(customFieldOptions)) {
            debounceGetCustomFieldOptions(fieldId, {
                ...customFieldOptions,
                search: searchText,
            });
        }
    };

    useEffect(() => {
        getCustomOptions(fieldId, {
            search: customFieldOptions?.search,
            offset: customFieldOptions?.offset,
            limit: customFieldOptions?.limit,
        });
    }, [fieldId, getCustomOptions, customFieldOptions?.limit, customFieldOptions?.offset, customFieldOptions?.search]);

    if (loader) {
        return (
            <div className="h-7">
                <Loader.Byside size="xs" noPadding />
            </div>
        );
    }

    const handleMultiChange = (values: MultiValue<SelectOption | undefined>) => {
        const ids = (values || []).map((v) => (v ? v.value : null));
        handleOnChange(ids.join('||'));
    };

    const defaultValue = value
        .split('||')
        .filter((item) => item !== '')
        .map((item) => options.find((option) => option.value === item));

    return (
        <Select.Basic
            className={className}
            styleGuide="coremedia"
            styles={customSelectStyles}
            name="field"
            options={options}
            isMulti
            menuPortalTarget={document.body}
            value={defaultValue}
            onChange={handleMultiChange}
            menuPlacement="auto"
            noOptionsMessage={() => resources?.no_options ?? 'No options'}
            placeholder={resources?.select_option ?? 'Select option'}
            isDisabled={disabled}
            onInputChange={handleSelectInputChange}
        />
    );
}
