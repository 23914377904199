import { createContext, ReactNode, useContext, useMemo } from 'react';
import { uid } from '@sidetalk/helpers';

type CheckboxContextProps = {
    randomCheckboxId: string;
};

const CheckboxContext = createContext<CheckboxContextProps | null>(null);

export type CheckboxContextProviderProps = {
    children: ReactNode;
};

export function CheckboxContextProvider({ children }: CheckboxContextProviderProps) {
    const randomCheckboxId = useMemo(uid, []);

    return (
        <CheckboxContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                randomCheckboxId,
            }}
        >
            {children}
        </CheckboxContext.Provider>
    );
}

export function useCheckbox(componentName: string) {
    const checkboxContext = useContext(CheckboxContext);

    if (!checkboxContext) {
        throw new Error(`Checkbox.${componentName} has to be inside the Checkbox.Root component`);
    }

    return checkboxContext;
}
