import { HTMLAttributes, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import { AccessibleIcon } from '@components/AccessibleIcon';
import { Spinner } from './components/Spinner';

export const loaderContainer = tv({
    slots: {
        container: 'text-coremedia-blue-500 p-2.5 text-center',

        icon: 'inline-block h-full w-auto',
    },

    variants: {
        verticalCenter: {
            true: {
                container: 'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
            },
        },

        size: {
            xs: {
                container: 'h-[22px] p-0',
            },

            sm: {
                container: 'h-[38px] p-1.5',
            },

            md: {
                container: 'h-[70px]',
            },

            lg: {
                container: 'h-[158px]',
            },

            xl: {
                container: 'h-[290px]',
            },

            auto: {
                container: 'h-auto',
            },
        },

        noPadding: {
            true: {
                container: 'p-0',
            },
        },
    },

    defaultVariants: {
        verticalCenter: false,
        size: 'md',
        noPadding: false,
    },
});

export type LoaderProps = HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof loaderContainer> & {
        width?: string | number;
        height?: string | number;
    };

const { container, icon } = loaderContainer();

const LoaderByside = forwardRef<HTMLDivElement, LoaderProps>(
    ({ width, height, verticalCenter, size, noPadding, className, ...rest }, forwardedRef) => (
        <div
            ref={forwardedRef}
            className={container({ size, verticalCenter, noPadding, className })}
            style={{ width, height }}
            {...rest}
        >
            <AccessibleIcon.Root label="loading">
                <Spinner className={icon({ className: 'animate-spinnerSpin' })} />
            </AccessibleIcon.Root>
        </div>
    ),
);

LoaderByside.displayName = 'Loader.Byside';

export const Loader = {
    Byside: LoaderByside,
};
