import { useCallback, useMemo } from 'react';
import { setYear, startOfMonth, startOfYear } from 'date-fns';
import { useDayPicker, useNavigation } from 'react-day-picker';
import { Select, SelectOption, SingleValue } from '@components/Select';
import { DatePickerVariants } from '@components/DatePicker/types';

type YearsDropdownProps = DatePickerVariants & {
    displayYear: Date;
};

export function YearsDropdown({ displayYear, styleGuide }: YearsDropdownProps) {
    const {
        fromDate,
        toDate,
        locale,
        formatters: { formatYearCaption },
        labels: { labelYearDropdown },
    } = useDayPicker();
    const { goToMonth } = useNavigation();

    const dropdownYearsOptions: SelectOption[] = useMemo(() => {
        if (!fromDate || !toDate) {
            return [];
        }

        const years: SelectOption[] = [];

        const fromYear = fromDate.getFullYear();
        const toYear = toDate.getFullYear();

        for (let year = toYear; year >= fromYear; year -= 1) {
            const yearAsDate = setYear(startOfYear(new Date()), year);

            years.push({
                label: formatYearCaption(yearAsDate, { locale }) as string,
                value: String(yearAsDate.getFullYear()),
            });
        }

        return years;
    }, [fromDate, toDate, formatYearCaption, locale]);

    const handleYearChange = useCallback(
        (e: SingleValue<SelectOption>) => {
            const selectedYear = Number(e?.value);
            const newMonth = setYear(startOfMonth(displayYear), selectedYear);
            goToMonth(newMonth);
        },
        [displayYear, goToMonth],
    );

    // Dropdown should appear only when both from/toDate is set
    if (!fromDate || !toDate) {
        return null;
    }

    const currentValue = dropdownYearsOptions.find((option) => option.value === String(displayYear.getFullYear()));

    return (
        <Select.Basic
            aria-label={labelYearDropdown()}
            options={dropdownYearsOptions}
            value={currentValue}
            onChange={handleYearChange}
            styleGuide={styleGuide}
        />
    );
}
