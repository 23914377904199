import { ComponentPropsWithoutRef, ElementRef, forwardRef, useCallback } from 'react';
import {
    Root,
    type DialogProps,
    Portal,
    type DialogPortalProps,
    Overlay as RadixOverlay,
    Trigger,
    Close,
    type DialogTitleProps,
    Content,
} from '@radix-ui/react-dialog';
import { Icon } from '@components/Icon';
import { Loader } from '@components/Loader';
import { CustomScrollArea } from '@components/CustomScrollArea';
import { OverlayContextProvider, OverlayContextProviderProps } from './context';
import {
    CustomOverlay,
    CustomContent,
    ContentCloseButton,
    CustomDescription,
    CustomFooter,
    CustomHeader,
    CustomTitle,
} from './styles';

export type OverlayRootProps = typeof Root;

type CustomRootProps = DialogProps & OverlayContextProviderProps;

function CustomRoot({ styleGuide, ...rest }: CustomRootProps) {
    return (
        <OverlayContextProvider styleGuide={styleGuide}>
            <Root {...rest} />
        </OverlayContextProvider>
    );
}

CustomRoot.displayName = 'Overlay.Root';

Trigger.displayName = 'Overlay.Trigger';

Close.displayName = 'Overlay.CloseButton';

const OverlayTitle = forwardRef<ElementRef<typeof CustomTitle>, DialogTitleProps>((props, forwardedRef) => (
    <CustomHeader>
        <CustomTitle ref={forwardedRef} {...props} />
    </CustomHeader>
));

OverlayTitle.displayName = 'Overlay.Title';

type CustomContentProps = DialogPortalProps &
    ComponentPropsWithoutRef<typeof CustomContent> & {
        disableEscKeyAndOverlayClose?: boolean;
    };

const OverlayContent = forwardRef<ElementRef<typeof CustomContent>, CustomContentProps>(
    ({ container, forceMount, size, disableEscKeyAndOverlayClose = true, children, style, ...rest }, forwardedRef) => {
        const isCustomSize = !['xs', 'sm', 'md', 'lg', 'xl'].includes(size as string);

        const customStyle = isCustomSize ? { width: size as string, ...style } : style;

        const handleClose = useCallback(
            (e: Event) => {
                if (disableEscKeyAndOverlayClose) {
                    e.preventDefault();
                }
            },
            [disableEscKeyAndOverlayClose],
        );

        return (
            <Portal container={container} forceMount={forceMount}>
                <CustomOverlay />
                <CustomContent
                    ref={forwardedRef}
                    size={size}
                    style={customStyle}
                    onEscapeKeyDown={handleClose}
                    onPointerDownOutside={handleClose}
                    onInteractOutside={handleClose}
                    {...rest}
                >
                    {children}
                    <Close asChild>
                        <ContentCloseButton>
                            <Icon type="byside" iconName="close" label="Close" />
                        </ContentCloseButton>
                    </Close>
                </CustomContent>
            </Portal>
        );
    },
);

OverlayContent.displayName = 'Overlay.Content';

type CustomBodyProps = ComponentPropsWithoutRef<typeof CustomScrollArea.Basic> & {
    isLoading?: boolean;
};

const CustomBody = forwardRef<ElementRef<typeof CustomScrollArea.Basic>, CustomBodyProps>(
    ({ children, isLoading = false, ...rest }, forwardedRef) => (
        <CustomScrollArea.Basic ref={forwardedRef} {...rest}>
            {isLoading ? (
                <div className="min-h-28">
                    <Loader.Byside size="sm" verticalCenter />
                </div>
            ) : (
                children
            )}
        </CustomScrollArea.Basic>
    ),
);

CustomBody.displayName = 'Overlay.Body';

export const Overlay = {
    Root: CustomRoot,
    Trigger,
    Portal,
    Overlay: RadixOverlay,
    Title: OverlayTitle,
    Description: CustomDescription,
    Body: CustomBody,
    Content: OverlayContent,
    ContentWithoutPortal: Content,
    CloseButton: Close,
    Footer: CustomFooter,
};
