import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

type StyleGuideType = 'coremedia' | 'new';
type AlignmentType = 'left' | 'center' | 'right' | 'justify';
type LayoutType = 'nav' | 'minimal' | 'menu';
type SizeType = 'xs' | 'small' | 'medium' | 'large';

type TabsContextProps = {
    styleGuide: StyleGuideType;
    setStyleGuide: (value: StyleGuideType) => void;
    alignment: AlignmentType;
    setAlignment: (value: AlignmentType) => void;
    layout: LayoutType;
    setLayout: (value: LayoutType) => void;
    size: SizeType;
    setSize: (value: SizeType) => void;
};

const TabsContext = createContext<TabsContextProps | null>(null);

export type TabsContextProviderProps = {
    children: ReactNode;
    styleGuide?: StyleGuideType;
    alignment?: AlignmentType;
    layout?: LayoutType;
    size?: SizeType;
};

export function TabsContextProvider({
    styleGuide: initialStyleGuide = 'coremedia',
    alignment: initialAlignment = 'left',
    layout: initialLayout = 'nav',
    size: initialSize = 'medium',
    children,
}: TabsContextProviderProps) {
    const [styleGuide, setStyleGuide] = useState<StyleGuideType>(initialStyleGuide);
    const [alignment, setAlignment] = useState<AlignmentType>(initialAlignment);
    const [layout, setLayout] = useState<LayoutType>(initialLayout);
    const [size, setSize] = useState<SizeType>(initialSize);

    useEffect(() => {
        setStyleGuide(initialStyleGuide);
    }, [initialStyleGuide]);

    useEffect(() => {
        setAlignment(initialAlignment);
    }, [initialAlignment]);

    useEffect(() => {
        setLayout(initialLayout);
    }, [initialLayout]);

    useEffect(() => {
        setSize(initialSize);
    }, [initialSize]);

    return (
        <TabsContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                styleGuide,
                setStyleGuide,
                alignment,
                setAlignment,
                layout,
                setLayout,
                size,
                setSize,
            }}
        >
            {children}
        </TabsContext.Provider>
    );
}

export function useTabs(componentName: string) {
    const tabsContext = useContext(TabsContext);

    if (!tabsContext) {
        throw new Error(`Tabs.${componentName} has to be inside the Tabs.Root component`);
    }

    return tabsContext;
}
