import { styled } from '@styles';

export const RadioContainer = styled('div', {
    display: 'grid',
    gap: '$2',
    gridTemplateColumns: 'max-content auto',
});

export const OptionContainer = styled('div', {
    width: '100%',
    borderRadius: '$xs',
    display: 'grid',
    gridColumn: 2,
    gap: '$3',
    marginBottom: '$2',
});
