import { ElementRef, forwardRef } from 'react';
import {
    Root,
    Viewport,
    Scrollbar,
    Thumb,
    type ScrollAreaProps,
    type ScrollAreaViewportProps,
    type ScrollAreaScrollbarProps,
    type ScrollAreaThumbProps,
} from '@radix-ui/react-scroll-area';
import { cn } from '@helpers/index';

export const CustomRoot = forwardRef<ElementRef<typeof Root>, ScrollAreaProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Root ref={forwardedRef} className={cn('relative overflow-hidden', className)} {...rest} />
    ),
);

CustomRoot.displayName = 'CustomScrollArea.Root';

export const CustomViewport = forwardRef<ElementRef<typeof Viewport>, ScrollAreaViewportProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Viewport ref={forwardedRef} className={cn('size-full rounded-[inherit]', className)} {...rest} />
    ),
);

CustomViewport.displayName = 'CustomScrollArea.Viewport';

export const CustomScrollbar = forwardRef<ElementRef<typeof Scrollbar>, ScrollAreaScrollbarProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Scrollbar
            ref={forwardedRef}
            className={cn(
                [
                    'flex touch-none select-none p-0.5',
                    'transition-colors duration-[160ms] ease-out',
                    'data-[orientation=horizontal]:h-2.5 data-[orientation=horizontal]:flex-col',
                    'data-[orientation=vertical]:w-2.5',
                ],
                className,
            )}
            {...rest}
        />
    ),
);

CustomScrollbar.displayName = 'CustomScrollArea.Scrollbar';

export const CustomThumb = forwardRef<ElementRef<typeof Thumb>, ScrollAreaThumbProps>(
    ({ className, ...rest }, forwardedRef) => (
        <Thumb
            ref={forwardedRef}
            className={cn(
                [
                    'hover:bg-gray-250 relative flex-1 rounded-[10px] bg-gray-100',
                    'before:absolute before:left-1/2 before:top-1/2 before:content-[""]',
                    'before:size-full before:min-h-4 before:min-w-4',
                    'before:-translate-x-1/2 before:-translate-y-1/2',
                ],
                className,
            )}
            {...rest}
        />
    ),
);

CustomThumb.displayName = 'CustomScrollArea.Thumb';
