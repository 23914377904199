import { DayPicker } from 'react-day-picker';
import { coremediaColors } from '@sidetalk/tokens';
import { styled } from '@styles';

export const NavigationContainer = styled('div', {
    variants: {
        styleGuide: {
            new: {
                all: 'unset',
                display: 'flex',
                alignItems: 'center',
                height: '$10',
                padding: '0 $1',
            },

            coremedia: {
                all: 'unset',
                display: 'flex',
                alignItems: 'center',
                height: '$10',
                padding: '0 $3',
                color: coremediaColors.blue500,

                h2: {
                    color: coremediaColors.blue500,
                    fontWeight: '$semibold',
                    fontSize: '13px',
                },
            },
        },

        isDropdown: {
            true: {
                '.sidetalk-select__container .sidetalk-select__control': {
                    minWidth: '6rem',
                },
            },
        },

        isWithButtons: {
            true: {
                justifyContent: 'space-between',
            },

            false: {
                justifyContent: 'center',
                gap: '$2',
            },
        },
    },

    compoundVariants: [
        {
            isDropdown: true,
            styleGuide: 'coremedia',
            css: {
                padding: 0,

                '.sidetalk-select__container .sidetalk-select__control': {
                    minWidth: '4.5rem',
                },
            },
        },
        {
            isDropdown: true,
            styleGuide: 'coremedia',
            isWithButtons: true,
            css: {
                gap: 0,

                '.sidetalk-select__container .sidetalk-select__control': {
                    minWidth: '4.5rem',
                },
            },
        },
    ],

    defaultVariants: {
        styleGuide: 'coremedia',
        isDropdown: false,
        isWithButtons: false,
    },
});

export const CustomCalendarNavigationButton = styled('button', {
    all: 'unset',
    padding: '$2',
    lineHeight: 0,
    borderRadius: '$xs',

    '&:not([disabled]):hover': {
        cursor: 'pointer',
    },

    '&:disabled': {
        color: '$gray100',
        cursor: 'not-allowed',
    },

    variants: {
        styleGuide: {
            new: {
                '&:not([disabled]):focus': {
                    outline: '1px solid $red500',
                },
            },

            coremedia: {
                '&:not([disabled]):focus': {
                    outline: 'none',
                },
            },
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});

export const DatePickerStyled = styled(DayPicker, {
    variants: {
        styleGuide: {
            new: {
                '.rdp-vhidden': {
                    boxSizing: 'border-box',
                    margin: 0,
                    background: 'transparent',
                    appearance: 'none',
                    position: 'absolute !important',
                    top: 0,
                    width: '1px !important',
                    height: '1px !important',
                    padding: '0 !important',
                    overflow: 'hidden !important',
                    clip: 'rect(1px, 1px, 1px, 1px) !important',
                    border: '0 !important',
                },

                '.rdp-months': {
                    display: 'flex',
                    gap: '$4',
                },

                '.rdp-table': {
                    margin: 0,
                    maxWidth: 'calc($10 * 7)',
                    borderCollapse: 'collapse',

                    '.rdp-head': {
                        '.rdp-head_row': {
                            backgroundColor: '$gray030',
                            height: '$8',

                            '.rdp-head_cell': {
                                verticalAlign: 'middle',
                                textTransform: 'uppercase',
                                fontSize: '$xs',
                                fontWeight: '$regular',
                                color: '$gray400',
                                textAlign: 'center',
                                height: '$10',
                                padding: 0,
                            },
                        },
                    },

                    '.rdp-tbody': {
                        '.rdp-row': {
                            height: '100%',

                            '.rdp-cell': {
                                position: 'relative',
                                width: '$10',
                                maxWidth: '$10',
                                height: '$10',
                                padding: 0,
                                textAlign: 'center',
                                overflow: 'hidden',
                                zIndex: 0,

                                '.rdp-weeknumber': {
                                    display: 'grid',
                                    placeItems: 'center',
                                    height: '100%',
                                    fontSize: '$xs',
                                    fontWeight: '$regular',
                                    color: '$gray400',
                                    backgroundColor: '$gray030',
                                },

                                '.rdp-day': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    boxSizing: 'border-box',
                                    width: '$10',
                                    maxWidth: '$10',
                                    height: '$10',
                                    fontSize: '$xs',
                                    color: '$gray850',
                                    fontWeight: '$regular',
                                    margin: 0,
                                    border: '2px solid transparent',
                                    borderRadius: '100%',
                                    backgroundColor: 'transparent',

                                    '&.rdp-day_disabled, &[disabled]': {
                                        color: '$gray100',
                                    },

                                    '&:not([disabled]).rdp-day_outside': {
                                        color: '$gray100',
                                    },

                                    '&:not([disabled]):not(.rdp-day_selected):hover': {
                                        backgroundColor: '$red100',
                                    },

                                    '&:focus-visible:not([disabled])': {
                                        borderColor: '$red500',
                                    },

                                    '&:not([disabled])': {
                                        cursor: 'pointer',
                                    },

                                    '&.rdp-day_today': {
                                        fontWeight: '$bold',
                                    },

                                    '&.rdp-day_selected': {
                                        backgroundColor: '$red100',
                                    },

                                    '&.rdp-button_reset:focus-visible': {
                                        outline: 'none',
                                    },

                                    '&.rdp-day_range_middle': {
                                        borderRadius: 0,
                                        backgroundColor: '$red050',
                                    },

                                    '&.rdp-day_range_start, &.rdp-day_range_end': {
                                        '&::after': {
                                            content: '',
                                            position: 'absolute',
                                            inset: '0',
                                            backgroundColor: '$red050',
                                            zIndex: -1,
                                        },
                                    },

                                    '&.rdp-day_range_start': {
                                        '&::after': {
                                            transform: 'translateX(50%)',
                                        },
                                    },

                                    '&.rdp-day_range_end': {
                                        '&::after': {
                                            transform: 'translateX(-50%)',
                                        },
                                    },

                                    '&.rdp-day_range_start.rdp-day_range_end': {
                                        '&::after': {
                                            content: 'none',
                                        },
                                    },
                                },

                                '&:first-child, &:last-child': {
                                    '.rdp-day:not([disabled]):not(.rdp-day_outside)': {
                                        color: '$gray400',
                                    },
                                },
                            },
                        },
                    },
                },
            },

            coremedia: {
                '.rdp-vhidden': {
                    boxSizing: 'border-box',
                    margin: 0,
                    background: 'transparent',
                    appearance: 'none',
                    position: 'absolute !important',
                    top: 0,
                    width: '1px !important',
                    height: '1px !important',
                    padding: '0 !important',
                    overflow: 'hidden !important',
                    clip: 'rect(1px, 1px, 1px, 1px) !important',
                    border: '0 !important',
                },

                '.rdp-months': {
                    display: 'flex',
                    padding: '10px',
                    gap: '$4',
                },

                '.rdp-table': {
                    display: 'block',
                    margin: 0,
                    borderCollapse: 'collapse',

                    '.rdp-head': {
                        '.rdp-head_row': {
                            backgroundColor: '$white',
                            height: '$8',

                            '.rdp-head_cell': {
                                verticalAlign: 'middle',
                                textTransform: 'uppercase',
                                fontSize: '13px',
                                fontWeight: '$semibold',
                                color: '$black',
                                textAlign: 'center',
                                height: '31px',
                                padding: 0,
                            },
                        },
                    },

                    '.rdp-tbody': {
                        '.rdp-row': {
                            height: '100%',

                            '.rdp-cell': {
                                position: 'relative',
                                width: '31px',
                                maxWidth: '31px',
                                height: '31px',
                                padding: 0,
                                textAlign: 'center',
                                overflow: 'hidden',
                                zIndex: 0,

                                '.rdp-weeknumber': {
                                    display: 'grid',
                                    placeItems: 'center',
                                    height: '100%',
                                    fontSize: '13px',
                                    fontWeight: '$regular',
                                    color: '$gray400',
                                    backgroundColor: '$gray030',
                                },

                                '.rdp-day': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    boxSizing: 'border-box',
                                    width: '31px',
                                    maxWidth: '31px',
                                    height: '31px',
                                    maxHeight: '31px',
                                    fontSize: '13px',
                                    color: '$black',
                                    fontWeight: '$regular',
                                    margin: 0,
                                    border: '2px solid transparent',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',

                                    '&.rdp-day_disabled, &[disabled]': {
                                        color: coremediaColors.grey400,
                                        textDecoration: 'line-through',
                                    },

                                    '&:not([disabled]).rdp-day_outside': {
                                        color: coremediaColors.grey400,
                                    },

                                    '&:not([disabled]):not(.rdp-day_selected):hover': {
                                        backgroundColor: coremediaColors.grey200,
                                    },

                                    '&:focus-visible:not([disabled])': {
                                        borderColor: coremediaColors.blue600,
                                    },

                                    '&:not([disabled])': {
                                        cursor: 'pointer',
                                    },

                                    '&.rdp-day_today': {
                                        fontWeight: '$bold',
                                    },

                                    '&:first-child, &:last-child': {
                                        '.rdp-day:not([disabled]):not(.rdp-day_outside)': {
                                            color: '$black',
                                        },
                                    },

                                    '&.rdp-day_selected': {
                                        color: '$white',
                                        backgroundColor: coremediaColors.blue500,
                                    },

                                    '&.rdp-button_reset:focus-visible': {
                                        outline: 'none',
                                    },

                                    '&.rdp-day_range_middle': {
                                        borderRadius: 0,
                                        color: '$black',
                                        backgroundColor: coremediaColors.blue100,
                                    },

                                    '&.rdp-day_range_start, &.rdp-day_range_end': {
                                        '&::after': {
                                            content: '',
                                            position: 'absolute',
                                            inset: '0',
                                            backgroundColor: coremediaColors.blue100,
                                            zIndex: -1,
                                        },
                                    },

                                    '&.rdp-day_range_start': {
                                        '&::after': {
                                            transform: 'translateX(50%)',
                                        },
                                    },

                                    '&.rdp-day_range_end': {
                                        '&::after': {
                                            transform: 'translateX(-50%)',
                                        },
                                    },

                                    '&.rdp-day_range_start.rdp-day_range_end': {
                                        '&::after': {
                                            content: 'none',
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },

    defaultVariants: {
        styleGuide: 'coremedia',
    },
});
