import type { ActionWithRulesProps } from 'react-querybuilder';
import { cn } from '@helpers/index';
import { Button } from '@components/Button';
import { Icon } from '@components/Icon';
import { useQueryBuilderState } from '../../context/QueryBuilder';

export function RemoveGroup({ label, handleOnClick, rules }: ActionWithRulesProps) {
    const { state } = useQueryBuilderState();
    const { disabled } = state;

    const rulesLength = rules?.length ?? 0;

    return (
        <Button
            className={cn(
                'absolute right-[-30px] self-center text-[large]',
                rulesLength > 0 ? 'top-[14px]' : 'top-[10px]',
                disabled ? 'hover:!text-gray-250 text-gray-250' : 'enabled:hover:!text-coremedia-red-500',
            )}
            onClick={disabled ? undefined : handleOnClick}
            disabled={disabled}
            styleGuide="coremedia"
            variant="icon"
        >
            <Icon type="byside" iconName="trash" label={label as string} />
        </Button>
    );
}
