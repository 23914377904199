import { SelectOption, SingleValue } from '@components/Select';
import { FilterData, FilterOption } from '../types';

export enum ActionTypes {
    addSavedFilters = 'ADD_SAVED_FILTERS',
    selectNewFilter = 'SELECT_NEW_FILTER',
    removeFilterApplied = 'REMOVE_FILTER_APPLIED',
    saveFilterSelected = 'SAVE_FILTER_SELECTED',
    removeFilterSelected = 'REMOVE_FILTER_SELECTED',
    onOpenPopover = 'ON_OPEN_POPOVER',
    selectedFilterChanges = 'SELECT_FILTER_CHANGES',
    confirmFilterChanges = 'CONFIRM_FILTER_CHANGES',
    setFilterToBeChanged = 'SET_FILTER_TO_BE_CHANGED',
    removeFilterBeingChanged = 'REMOVE_FILTER_BEING_CHANGED',
}

export const addSavedFiltersAction = (savedFilters: FilterOption[]) => ({
    type: ActionTypes.addSavedFilters as ActionTypes.addSavedFilters,
    payload: {
        savedFilters,
    },
});

export type AddSavedFiltersAction = ReturnType<typeof addSavedFiltersAction>;

export const selectNewFilterAction = (filterSelected: SingleValue<SelectOption>, filterData: FilterData) => ({
    type: ActionTypes.selectNewFilter as ActionTypes.selectNewFilter,
    payload: {
        filterSelected,
        filterData,
    },
});

export type SelectNewFilterAction = ReturnType<typeof selectNewFilterAction>;

export const removeFilterAppliedAction = (index: number) => ({
    type: ActionTypes.removeFilterApplied as ActionTypes.removeFilterApplied,
    payload: {
        index,
    },
});

export type RemoveFilterAppliedAction = ReturnType<typeof removeFilterAppliedAction>;

export const saveFilterSelectedAction = () => ({
    type: ActionTypes.saveFilterSelected as ActionTypes.saveFilterSelected,
});

export type SaveFilterSelectedAction = ReturnType<typeof saveFilterSelectedAction>;

export const removeFilterSelectedAction = () => ({
    type: ActionTypes.removeFilterSelected as ActionTypes.removeFilterSelected,
});

export type RemoveFilterSelectedAction = ReturnType<typeof removeFilterSelectedAction>;

export const onOpenPopoverAction = (open: boolean) => ({
    type: ActionTypes.onOpenPopover as ActionTypes.onOpenPopover,
    payload: {
        open,
    },
});

export type OnOpenPopoverAction = ReturnType<typeof onOpenPopoverAction>;

export const selectedFilterChangesAction = (newFilterChanges: FilterOption) => ({
    type: ActionTypes.selectedFilterChanges as ActionTypes.selectedFilterChanges,
    payload: {
        newFilterChanges,
    },
});

export type SelectedFilterChangesAction = ReturnType<typeof selectedFilterChangesAction>;

export const confirmFilterChangesAction = (index: number) => ({
    type: ActionTypes.confirmFilterChanges as ActionTypes.confirmFilterChanges,
    payload: {
        index,
    },
});

export type ConfirmFilterChangesAction = ReturnType<typeof confirmFilterChangesAction>;

export const setFilterToBeChangedAction = (index: number) => ({
    type: ActionTypes.setFilterToBeChanged as ActionTypes.setFilterToBeChanged,
    payload: {
        index,
    },
});

export type SetFilterToBeChangedAction = ReturnType<typeof setFilterToBeChangedAction>;

export const removeFilterBeingChangedAction = () => ({
    type: ActionTypes.removeFilterBeingChanged as ActionTypes.removeFilterBeingChanged,
});

export type RemoveFilterBeingChangedAction = ReturnType<typeof removeFilterBeingChangedAction>;
