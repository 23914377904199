import { ComponentPropsWithoutRef, ElementRef, HTMLAttributes, forwardRef } from 'react';
import { VariantProps, tv } from '@lib/tailwind-variants';
import {
    bysideIconsName,
    bytalkIconsName,
    BysideClassNamesIcons,
    BytalkClassNamesIcons,
    SVGNamesIcons as RawSVGNamesIcons,
} from '@sidetalk/tokens';
import * as svgs from '@sidetalk/svgs';
import { toPascalCase } from '@sidetalk/helpers';
import { AccessibleIcon, AccessibleIconRootProps } from '../AccessibleIcon';

type CamelToKebab<S extends string> = S extends `${infer T}${infer U}`
    ? U extends Uncapitalize<U>
        ? `${Uncapitalize<T>}${CamelToKebab<U>}`
        : `${Uncapitalize<T>}-${CamelToKebab<U>}`
    : '';

type RemoveIconSuffix<T extends string> = T extends `${infer Prefix}Icon` ? Prefix : never;

type SVGNamesIcons = CamelToKebab<RemoveIconSuffix<RawSVGNamesIcons>>;

export type { BysideClassNamesIcons, BytalkClassNamesIcons, SVGNamesIcons };

export const iconVariants = tv({
    base: 'leading-none text-inherit antialiased',

    variants: {
        type: {
            byside: 'font-bysideIcons',

            bytalk: 'font-bytalkIcons',

            svg: '',
        },

        iconSize: {
            inherit: 'text-[inherit]',

            small: 'text-xl',

            normal: 'text-2xl',

            large: 'text-3xl',
        },
    },

    defaultVariants: {
        iconSize: 'inherit',
    },
});

export type IconCreatorProps = HTMLAttributes<HTMLElement> &
    VariantProps<typeof iconVariants> &
    (
        | {
              type: 'bytalk';
              iconName: BytalkClassNamesIcons;
          }
        | {
              type: 'byside';
              iconName: BysideClassNamesIcons;
          }
        | {
              type: 'svg';
              iconName: SVGNamesIcons;
          }
    );

const IconCreator = forwardRef<HTMLElement, IconCreatorProps>(
    ({ type, iconName, iconSize, className = '', ...rest }, forwardedRef) => {
        if (type === 'svg') {
            const Icon = svgs[`${toPascalCase(iconName)}Icon` as keyof typeof svgs];

            // @ts-expect-error since if should be SVGSVGElement for this type and we can't use generic on forwardRef
            return <Icon ref={forwardedRef} className={className} {...rest} />;
        }

        const iconType = type === 'bytalk' ? 'icon' : 'byside-icon';
        const icon = type === 'bytalk' ? bytalkIconsName[iconName] : bysideIconsName[iconName];

        const [name, ...innerElements] = icon?.split(' ') ?? [];

        const newClassName = `${iconType}-${name} ${className}`;

        return (
            <i ref={forwardedRef} className={iconVariants({ type, iconSize, className: newClassName })} {...rest}>
                {innerElements.map((element) => (
                    <i key={element} className={element} />
                ))}
            </i>
        );
    },
);

export type IconProps = ComponentPropsWithoutRef<typeof IconCreator> & Partial<Pick<AccessibleIconRootProps, 'label'>>;

export const Icon = forwardRef<ElementRef<typeof IconCreator>, IconProps>(({ title, label, ...rest }, forwardedRef) => {
    const iconLabel = title ?? label;

    if (iconLabel) {
        return (
            <AccessibleIcon.Root label={iconLabel}>
                <IconCreator ref={forwardedRef} {...rest} />
            </AccessibleIcon.Root>
        );
    }

    return <IconCreator ref={forwardedRef} aria-hidden {...rest} />;
});

Icon.displayName = 'Icon';
