import { HOURS_IN_A_DAY } from './constants';

export const generateTime = (steps = 1, time = HOURS_IN_A_DAY) => {
    const buttonsQuantity = Math.floor(time / steps);

    const hoursArray = Array.from({ length: buttonsQuantity - 1 }).map((_, i) => i * steps);

    const timesWithFirstAndLast = new Set([0, ...hoursArray, time - 1]);

    return [...timesWithFirstAndLast];
};

export const closestTime = (array: number[], target: number) => {
    let closestIndex = 0;
    let closestDistance: number = Math.abs(array[0] - target);

    const timesLength = array.length;

    for (let i = 0; i <= timesLength; i += 1) {
        const distance = Math.abs(array[i] - target);

        if (distance === 0) {
            closestDistance = distance;
            closestIndex = i;
            break;
        }

        if (distance < closestDistance) {
            closestDistance = distance;
            closestIndex = i;
        }
    }

    return array[closestIndex];
};
